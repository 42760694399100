import AnimatedRoute from 'components/AnimatedRoute';
import { useNavigate } from "react-router-dom";
import { getDrilldownPath } from 'utils';
import _ from 'lodash';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import YASkeleton from 'components/YASkeleton';
// import DraggablePanel from "components/DraggablePanel";
import { useContext, useEffect, useState } from 'react';
import { CubeContext } from '@cubejs-client/react';
import useFetchRequest from "hooks/useFetchRequest";
import useHandleError from 'hooks/useHandleError';
import { Card, CircularProgress, circularProgressClasses, Divider, Icon, Menu, MenuItem, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import MDTypography from 'components/MDTypography';
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import { formatAmount } from 'utils';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import colors from 'assets/theme/base/colors';
import { downloadChargeBack } from 'utils';
import TableRenderer from './TableRenderer';
import { useAppController } from 'context';
import moment from 'moment';
import S3bucketForm from 'components/S3bucketForm';

const loadQueryResult = async (cubeApi, query, pagingOptions, disableServerSidePaging) => {
  let newQuery = { ...query };
  if (pagingOptions) {
    const { pageSize, currentPage, selectedColumns } = pagingOptions;
    newQuery.dimensions = newQuery.dimensions.filter(d => selectedColumns.includes(d));
    newQuery.measures = newQuery.measures.filter(m => selectedColumns.includes(m));
    if (!disableServerSidePaging) {
      newQuery["limit"] = pageSize + 1;
      newQuery["offset"] = currentPage === 0 ? 0 : (currentPage * pageSize);
    }
  }
  try {
    const response = await cubeApi.load(newQuery);
    return {
      resultSet: response,
      error: null
    };
  } catch (error) {
    return {
      resultSet: null,
      error
    };
  }
}

const MapRule = (props) => {
  const { yearFilter, yearFilterName, categoryFilter, tabStyles, menuItem, setMenuItem, monthFilter, setMonthFilter, monthFilterName, setMonthFilterName, action, refresh, setAction, setRefresh, subCategoryFilter, approver} = props
  const [vizState, setVizState] = useState(null);
  const [vizOptions, setVizOptions] = useState(null);
  const [BUSpend, setBUSpend] = useState(null);
  const [status, setStatus] = useState(null)
  const [noDef, setNoDef] = useState(false)
  const typeFilter = "Spend"
  let navigate = useNavigate()

  if (action != null) {
    if (menuItem) {
      <MDBox display="flex">
         <MDTypography
        component="span"
        variant="h3"
        fontWeight="medium"
        lineHeight="1"
        sx={() => ({
          
          "&:hover": {
            color: colors.linkColour,
            cursor: 'pointer'
          }
        })} onClick={() => { setMenuItem(menuItem); setMonthFilter(null); setAction(null) }}>{categoryFilter}s</MDTypography>
        <ChevronRightIcon fontSize="medium" sx={{ marginTop: 0.3 }} />
        <MDTypography
          component="span"
          variant="h3"
          fontWeight="medium"

          // sx={{ lineHeight: 1.25,}}
          sx={{ lineHeight: 1, "&:hover": { "& .helpIcon": { visibility: 'visible' } } }}
        >{monthFilterName}</MDTypography>
        <MDTypography
          component="span"
          variant="h5"
          fontWeight="medium" 
          pl="3px"
          pt="5px"
          
          // sx={{ lineHeight: 1.25,}}
          sx={{ lineHeight: 1.2, "&:hover": { "& .helpIcon": { visibility: 'visible' } } }}
        >{' (' + typeFilter + ')'}</MDTypography>
      </MDBox>
    }
  }


  const handleAction = (month, monthName, action, buSpend, status) => {
    setMonthFilter(month)
    setMonthFilterName(monthName)
    setAction(action)
    setBUSpend(buSpend)
    setStatus(status)
  }

  const goToSettings = () => {
    navigate(getDrilldownPath('', `/admin/settings`))
  }

  return (
    <>
      <div>         
        {(!monthFilter || refresh || action === 'view' || action === 'preview') && 
          <ShowAllMonths 
            setAction={setAction} 
            action={action} 
            yearFilter={yearFilter} 
            yearFilterName={yearFilterName} 
            monthFilter={monthFilter}
            monthFilterName={monthFilterName} 
            tabStyles={tabStyles} 
            categoryFilter={categoryFilter} 
            typeFilter={typeFilter} 
            handleAction={handleAction} 
            refresh={refresh} 
            setRefresh={setRefresh} 
            subCategoryFilter={subCategoryFilter} 
            setVizOptions={setVizOptions}
            setVizState={setVizState}
            vizOptions={vizOptions}
            vizState={vizState}
            BUSpend={BUSpend}
            status={status}
            setNoDef={setNoDef}
            approver={approver}
          />
        }
        {noDef &&
          <Modal open={true}>
            <MDBox p={1} mb={1} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
              <Card sx={{ minHeight: "160px", width: "400px", overflow: 'hidden', border: "2px solid #000000", boxShadow: "0px 2px 8px rgba(48, 53, 109, 0.1);", borderRadius: "12px" }}>
                <MDBox px={4} pt={2} pb={1} display="flex" justifyContent="space-between" alignItems="center">
                  <br />
                  <Icon fontSize="large" component="span" color="warning">warning</Icon>
                  <MDBox >
                    <MDTypography variant="h6" component="span" color="text">
                      Default Chargeback Report is not selected.<br />
                      Redirecting to the settings.
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={2} pr={2} pl={2} sx={{ backgroungColor: "white", textAlign: "center" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    // sx={{ width:"100%"}}
                    onClick={goToSettings}
                  >
                    Go to settings
                  </MDButton>
                </MDBox>
              </Card>
            </MDBox>
          </Modal>
        }
      </div>
    </>
  );
}

const ShowChargeBack = (props) => {
  const { yearFilter, yearFilterName, monthFilter, monthFilterName, action, setAction, tabStyles, setRefresh, vizState, vizOptions, BUSpend, chargeBackReload, reloadData, status, approver } = props
  return (
    <>
      <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", borderBottom: "1px solid #edeef3", borderTop: "1px solid #e3e3e3", display: "inline-flex" }} justifyContent="space-between">
        <MDBox display="flex">
          {action === "view" &&<MDButton data-testid = {"rules"} sx={(theme) => tabStyles(theme, { selected: action === "view" })} onClick={() => {setAction("view")}}>
            <Icon color="dark" sx={{ marginRight: "6px" }} fontSize="small">receipt_long</Icon>
            CHARGEBACK
          </MDButton>}
          {action === "preview" && <MDButton data-testid = {"rules"} sx={(theme) => tabStyles(theme, { selected: action === "preview" })} onClick={() => {setAction("preview")}}>
            <Icon color="dark" fontSize="small" sx={{marginRight: "6px" }}>preview</Icon>
            PREVIEW
          </MDButton>}
        </MDBox>
      </MDBox>
      {(action === "view" || action === 'preview') && 
      <MDBox sx={{backgroundColor: colors.chartBackground}}>
        <TableRenderer 
          title={'Chargeback'}
          subTitle={'Chargeback Report Details'}
          yearFilter={yearFilter} 
          yearFilterName={yearFilterName} 
          monthFilter={monthFilter}
          monthFilterName={monthFilterName} 
          vizOptions={vizOptions}
          vizState={vizState}
          action={action} 
          setAction={setAction}
          containerHeight={"calc(100vh - 370px)"} 
          setRefresh={setRefresh} 
          chargeBackReload={chargeBackReload}
          reloadData={reloadData} 
          loading={ false }
          BUSpend={BUSpend}
          applyFilters={false}
          status={status}
          approver={approver}
        />
      </MDBox>
      }
    </>
  )
}


const ShowAllMonths = (props) => {
  const { setAction, action, yearFilter, yearFilterName, monthFilter, monthFilterName, tabStyles, categoryFilter, typeFilter, handleAction, refresh, setRefresh, subCategoryFilter, setVizOptions, setVizState, vizOptions, vizState, BUSpend, status, setNoDef, approver } = props

  const { response, error, loading, reloadData } = useFetchRequest(`/api/dataflow/${categoryFilter.toLowerCase().replace(' ', '')}${typeFilter}Monthly/${yearFilter}`);

  const { response: previewDataRes, error: previewDataErr, loading: previewDataLoading } = useFetchRequest(`/api/dataflow/preview/chargeBack`);

  const { response: chargeBack, error: chargeBackErr, loading: chargeBackLoading, reloadData: chargeBackReload } = useFetchRequest(`/api/dataflow/chargeBack`)

  const handleError = useHandleError();
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!loading) {
      if (error !== null && chargeBackErr !== null) {
        handleError(error);
      }
      else if (response !== null) {
        setData(response);
      }
    }
  }, [loading, yearFilter, reloadData, refresh]);


  if (loading && previewDataLoading && chargeBackLoading && data === null) {
    return <YASkeleton variant="dashboard-loading" />;
  }

  if (loading === false && data === null) {
    return (
      <div>
        no data
      </div>
    );
  }
  return (
    <>
      <MDBox p={3} pt={1} sx={{ backgroundColor: "#F7F8FD", }}>
        {!action && data && data.map(item => {
          if (item["amount"])
            return (
              <Month 
                key={item["id"]} 
                action={action} 
                tabStyles={tabStyles} 
                setAction={setAction} 
                handleAction={handleAction} 
                yearFilter={yearFilter} 
                yearFilterName={yearFilterName} 
                monthFilter={item['id']} 
                monthFilterName={item['name']} 
                item={item}
                data={_.find(chargeBack, {yearNameId: yearFilter, monthNameId: item['id']})?.def}
                preViewDataResponse = {[previewDataRes, previewDataErr, previewDataLoading ]}
                reloadData={reloadData} 
                chargeBackReload={chargeBackReload} 
                categoryFilter={categoryFilter} 
                typeFilter={typeFilter} 
                refresh={refresh} 
                setRefresh={setRefresh} 
                subCategoryFilter={subCategoryFilter} 
                status={_.find(chargeBack, {yearNameId: yearFilter, monthNameId: item['id']})?.status}
                setVizOptions={setVizOptions}
                setVizState={setVizState}
                setNoDef={setNoDef}
                approver={approver}
              />
            )
          else
            return (
              <MonthEmpty 
                key={item["id"]} 
                tabStyles={tabStyles} 
                action={action} 
                setAction={setAction} 
                handleAction={handleAction} 
                yearFilter={yearFilter} 
                yearFilterName={yearFilterName} 
                monthFilter={item['id']} 
                monthFilterName={item['name']} 
                item={item} 
                chargeBackReload={chargeBackReload} 
                reloadData={reloadData} 
                categoryFilter={categoryFilter} 
                typeFilter={typeFilter} 
                setRefresh={setRefresh} 
                subCategoryFilter={subCategoryFilter} 
              />
            )
        })}
        {(action === 'view' || action === 'preview') && 
          <ShowChargeBack 
            yearFilter={yearFilter} 
            yearFilterName={yearFilterName} 
            monthFilter={monthFilter}
            monthFilterName={monthFilterName} 
            setAction={setAction} 
            action={action} 
            tabStyles={tabStyles} 
            setRefresh={setRefresh} 
            vizState={vizState}
            vizOptions={vizOptions}
            BUSpend={BUSpend}
            status={status}
            approver={approver}
            chargeBackReload={chargeBackReload}
            reloadData={reloadData} 
            />
        }
      </MDBox>
    </>
  )
}
const MonthEmpty = (props) => {
  const { item, typeFilter } = props

  return (
    <Card
      sx={{
        minHeight: "150px",
        minWidth: "250px",
        margin: "10px",
        display: "inline-block",
        overflow: "hidden",
        position: "relative",
        backgroundColor: "#F4F4F4",
        border: "1px dashed #ADADAD",
        "&:hover": {
          border: "1px dashed #495dbd",

        }
      }}>
        <MDBox px={3} pb={1} pt={2} display="flex" justifyContent="space-between" alignItems="flex-start">
        <MDBox display="flex" width="100%" flexDirection="column" justifyContent="space-between" >
          <MDBox color="text" pt={0} mt={0} display="flex" justifyContent="space-between" flexDirection="row">
            <MDTypography data-testid = {item["name"].toLowerCase().replaceAll(' ', '')} variant="h6" component="span" color="#4c4c4c" display="flex">{item["name"]}</MDTypography>
            <MDBox mt={-1} mr={-1} pt={0}>
              <MDButton
                // size="medium"
                disableRipple
                disabled={true}
                color="dark"
                variant="text"
                sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                iconOnly
                aria-label="options"
              >
                <Icon px={0} py={0} alignItems="end">more_horiz</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" >
            <MDBox data-testid = {"chargeback"} pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
            Chargeback<br />
              {item["amount"] ? formatAmount(item["amount"]) : formatAmount(0)}&nbsp;
            </MDBox>
            <CircularProgressWithLabel value={0} />
          </MDBox>
          <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
          <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
            <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{'Business Unit' + " " + typeFilter}:</MDTypography>
            <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{item["amount"] ? formatAmount(item["amount"]) : 'No ' + typeFilter }</MDTypography>
          </MDBox>
          {typeFilter === "Spend" && 
            <>
              <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                <MDTypography data-testid = {"chargeback"} sx={{ fontSize: "14px", pt: "8px", pb: "8px" }}>Chargeback</MDTypography>
                <MDTypography 
                  data-testid = {"chargeback>"}
                  variant="button"
                  sx={{
                    fontSize: "14px",
                    padding: "8px",
                    color: "#A8A8A8",
                    borderRadius: "5px",
                    "&:hover": {
                      cursor: "pointer",
                      color: "#A8A8A8",
                      backgroundColor: "#EDEDED"
                    }
              }} fontWeight="medium"
            >{"+ Create"}</MDTypography>
              </MDBox>
            </>
          } 
        </MDBox>
      </MDBox>
    </Card>
  )
}
export function addFilters (query, yearFilterName, monthFilterName, additionalFilter) {
  const applyYearFilter = {
    "member": "Years.year",
    "operator": "equals",
    "values": [yearFilterName]
  }
  const applyMonthFilter = {
    "member": "Months.month",
    "operator": "equals",
    "values": [monthFilterName]
  }
  const applyTypeFilter = {
    "member": "SpendCube.type",
    "operator": "equals",
    "values": ["Actual"]
  }
  const applyBuFilter = {
    "member": "SpendCube.businessUnitId",
    "operator": "set",
    "values": []
  }
  const { filters } = query
  if (query?.limit) {
    delete(query.limit)
  }
  if (filters?.length > 0) {
    _.remove(filters, item => item['member'] === applyYearFilter['member'])
    _.remove(filters, item => item['member'] === applyMonthFilter['member'])
    _.remove(filters, item => item['member'] === applyTypeFilter['member'])
    _.remove(filters, item => item['member'] === applyBuFilter['member'])
    if (additionalFilter && additionalFilter?.length > 0) {
      additionalFilter.map( filter => _.remove(filters, item => item['member'] === filter['member']))
    }
  }
  if(!_.includes(filters, applyYearFilter) && yearFilterName) filters.push(applyYearFilter)
  if(!_.includes(filters, applyMonthFilter) && monthFilterName) filters.push(applyMonthFilter)
  if(!_.includes(filters, applyTypeFilter)) filters.push(applyTypeFilter)
  if(!_.includes(filters, applyBuFilter)) filters.push(applyBuFilter)
  if (additionalFilter && additionalFilter?.length > 0) {
    additionalFilter.map( filter => {if(!_.includes(filters, filter)) filters.push(filter)})
  }

  return query
}
const Month = (props) => {
  const { item, handleAction, yearFilter, yearFilterName, monthFilter, monthFilterName, chargeBackReload, data, preViewDataResponse, typeFilter, setRefresh, action, status, setVizOptions, setVizState, setNoDef, approver, reloadData } = props
  const { showAlert, showPrompt, showSnackbar,showCustomForm } = useYADialog();
  const [ previewDataRes, previewDataErr, previewDataLoading ] = preViewDataResponse
  // const [ dataRes, dataErr, dataLoading, dataResReload ] = dataResponse
  const [openMenu, setOpenMenu] = useState(false);
  // const [ rowDataAnnotion, setRowDataAnnotion ] = useState(null)
  const [ totalSpend, setTotalSpend ] = useState(null)
  const { cubeApi } = useContext(CubeContext);
  const [rowData, setRowData] = useState(null);
  const [controller, ] = useAppController();
  const { appDef: { years } } = controller;
  // const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
  const [loading, setDataLoading] = useState(true);

  let totalSpendCalculated = 0;

  useEffect(async () => {
    if (data) {
      setDataLoading(true)
      let parsedResult = JSON.parse(data)
      const newQuery = addFilters(parsedResult?.vizState.query, yearFilterName, monthFilterName)
      setVizState(parsedResult?.vizState)
      setVizOptions(parsedResult?.vizOptions)
      const result = await loadQueryResult(cubeApi, newQuery, parsedResult?.vizOptions.disableServerSidePaging)
      setDataLoading(false)
      if (result?.error === null) {
        setRowData(result?.resultSet?.loadResponse?.results[0]?.data)
        // setRowDataAnnotion(result?.resultSet?.loadResponse?.results[0]?.annotation)
        result?.resultSet?.loadResponse?.results[0]?.data.map( data => {
          totalSpendCalculated += Number(data['SpendCube.totalSpend']);
        })
        setTotalSpend(totalSpendCalculated)
      }
    } else {
      setTotalSpend(null)
      setDataLoading(false)
    }
  }, [yearFilter, data]);

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  };
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };

  const handleChargeBackPreview = async () => {
    setDataLoading(true)
    if (monthFilter) {
      if (!previewDataLoading) {
        if (previewDataErr !== null) {
          setNoDef(true)
        } else {
          if (previewDataRes) {
            let parsedResult = previewDataRes
            setVizState(parsedResult?.vizState)
            setVizOptions(parsedResult?.vizOptions)
            setDataLoading(false)
            handleAction(item["id"],item["name"], 'preview', item['amount'], null);
          }
        }
      } 
    }
  }

  const handleChargeBackView = async () => {
    if (monthFilter) {
      if (data) {
        let parsedResult = JSON.parse(data)
        setVizState(parsedResult?.vizState)
        setVizOptions(parsedResult?.vizOptions)
        handleAction(item["id"],item["name"], 'view', item['amount'], status);
      }
    }
  }

  useEffect(() => {
    if (action === 'preview') {
      reloadData()
      // preViewDataReload()
      handleChargeBackPreview()
    }
  }, [yearFilter]);

  useEffect(() => {
    if (action === 'view') {
      reloadData()
      chargeBackReload()
      handleChargeBackView()
    }
  }, [yearFilter]);

  const handleApproveChargeBack = () => {
    showPrompt("Approve Chargeback", "Do you want to Approve the Chargeback" + " for - [ " + item["name"] + " " + yearFilterName + " ]", async () => {
      let [err, data] = await fetchRequest.post(`/api/dataflow/approveChargeBack/${yearFilter}/${item["id"]}`)
      if (err) {
        console.error(err)
        showAlert("Approve Chargeback", "Something went wrong. Contact your administrator.");
      }
      else if (data) {
        showSnackbar(data, "success")
        setOpenMenu(false)
        reloadData()
        chargeBackReload()
        setRefresh(Math.random())
      }
    });
  }

  const handleRevertChargeBack = () => {
    showPrompt("Revert Chargeback", "Chargeback will be revered" + " for - [ " + item["name"] + " " + yearFilterName + " ]", async () => {
      let [err, data] = await fetchRequest.delete(`/api/dataflow/revertChargeBack/${yearFilter}/${item["id"]}`)
      if (err) {
        console.error(err)
        showAlert("Revert Chargeback", "Something went wrong. Contact your administrator.");
      }
      else if (data) {
        showSnackbar(data, "success")
        setOpenMenu(false)
        reloadData()
        chargeBackReload()
        setRefresh(Math.random())
      }
    });
  }
  
  // const handleDeleteChargeBack = () => {
  //   showPrompt("Revert Chargeback", "Chargeback will be revered" + " for - [ " + item["name"] + " " + yearFilterName + " ]", async () => {
  //     let [err, data] = await fetchRequest.delete(`/api/dataflow/deleteChargeBack/${yearFilter}/${item["id"]}`)
  //     if (err) {
  //       console.error(err)
  //       showAlert("Delete Chargeback", "Something went wrong. Contact your administrator.");
  //     }
  //     else if (data) {
  //       showSnackbar(data, "success")
  //       setOpenMenu(false)
  //       setRefresh(Math.random());   
  //       reloadData()
  //       chargeBackReload()
  //     }
  //   });
  // }

  // function getYear(yearFilterName) {
  //   const startDate = _.find(years, {name: yearFilterName})?.startDate;
  //   const yearSplit = yearFilterName?.split('-').length > 1 ? yearFilterName.split('-').map(year => { 
  //     if (Number(year) < 100) {
  //       const currCentury = Number(Math.round(moment(Date.now()).year()/100))
  //       year = (currCentury * 100) + Number(year)
  //       return Number(year)
  //     }else {
  //       return Number(year)
  //     }
  //    }) : Number(yearFilterName.match(/\d+/g))
  //   if (Array.isArray(yearSplit)) {
  //     if (monthFilter >= Number(moment(startDate || '').month() + 1)) {
  //       return yearSplit[0]
  //     } else {
  //       return yearSplit[1]
  //     }
  //   } else {
  //     const dateFormat1 = Number((String(yearSplit) + (monthFilter < 10 ? `0` + monthFilter : monthFilter)))
  //     const dateFormat2 = moment(startDate || '').year() * 100 + moment(startDate || '').month() + 1;
  //     if (dateFormat1 >= dateFormat2) {
  //       return yearSplit
  //     } else {
  //       return yearSplit + 1
  //     }
  //   }
  // }
  
  // const startDate = moment(new Date(getYear(yearFilterName), monthFilter - 1, 1)).format(defaultDateFormat);
  // const endDate = moment(new Date(getYear(yearFilterName), monthFilter, 0)).format(defaultDateFormat);

  const handleClose = () => {
    setRefresh(Math.random())
  };

  const handleS3Export = async (data) => {
    if (data?.length > 0) {
        const fileName = `yarken_chargeback_${moment(Date()).format("YYYYMMDDHHmmss")}.xlsx`
        showCustomForm("Choose S3 Bucket", () => <S3bucketForm onClose={handleClose} tableData = {data} fileName = {fileName} loading = {previewDataLoading} />, null, null, null, 'sm');
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Exported Chargeback for ${yearFilterName} - ${monthFilterName} to S3 Bucket` });
    }
  }

  // const modRowData = rowData && rowDataAnnotion ? rowData?.map( data => {
  //   Object.keys(data).map( key => {
  //     if (_.includes(Object.keys(rowDataAnnotion.dimensions), key)) {
  //       Object.keys(rowDataAnnotion.dimensions).map( dimensions => {
  //         if ( dimensions === key)
  //           return data[key] = rowDataAnnotion.dimensions[dimensions].title
  //       })
  //     }
  //     if (_.includes(Object.keys(rowDataAnnotion.measures), key)) {
  //       Object.keys(rowDataAnnotion.measures).map( measures => {
  //         if ( measures === key)
  //         return data[key] = rowDataAnnotion.measures[measures].title
  //       }) 
  //     }
  //   })
  // }) : []


  return (
    <Card
      sx={{
        minHeight: "150px",
        minWidth: "250px",
        margin: "10px",
        display: "inline-block",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        border: "none",
        overflow: "hidden",
        position: "relative",
        "&:hover": {
          "& .helpIcon": {
            visibility: 'visible'
          }
        }
      }}>
      {(loading) && <YASkeleton variant="loading" />}
      { (!loading) &&
        <MDBox px={3} pb={1} pt={2} display="flex" justifyContent="space-between" alignItems="flex-start">
        <MDBox display="flex" width="100%" flexDirection="column" justifyContent="space-between" >
          <MDBox color="text" pt={0} mt={0} display="flex" justifyContent="space-between" flexDirection="row">
            <MDTypography data-testid = {item["name"]?.toLowerCase().replaceAll(' ', '')} variant="h6" component="span" color="#4c4c4c" display="flex">{item["name"]}</MDTypography>
            {status && <MDTypography data-testid = {item["name"]?.toLowerCase().replaceAll(' ', '')} variant="h6" component="span" color={status === 'APPROVED' ? 'success' : "info"} display="flex">{status}</MDTypography>}
            <Menu
              anchorEl={openMenu}
              anchorReference={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(openMenu)}
              onClose={handleCloseMenu}
            >
                {!status && <MenuItem data-testid = {"create"} key={'create'} onClick={() => handleCloseMenuItem(() => { handleChargeBackPreview(); (null) })}>Create</MenuItem>}
                {status === 'DRAFT' && <MenuItem disabled = {!approver} data-testid = {"approve"} key={'approve'} onClick={() => handleCloseMenuItem(() => { handleApproveChargeBack(); (null) })}>Approve</MenuItem>}
                {status && <MenuItem disabled = {status === 'APPROVED' && !approver} data-testid = {"revert"} key={'revert'} onClick={() => handleCloseMenuItem(() => { handleRevertChargeBack(); (null) })}>Revert</MenuItem>}
                {status && <MenuItem data-testid = {"download"} key={'download'} onClick={() => handleCloseMenuItem(() => { downloadChargeBack(years, rowData, status, yearFilterName, monthFilter, monthFilterName); (null) })}>Download</MenuItem>}
                {status === 'APPROVED' && <MenuItem data-testid = {"export"} key={'export'} onClick={() => handleCloseMenuItem(() => { handleS3Export(rowData); (null) })}>Export to S3 Bucket</MenuItem>}

            </Menu>

            <MDBox mt={-1} mr={-1} pt={0}>
              <MDButton
                // size="medium"
                disableRipple
                color="dark"
                variant="text"
                onClick={handleOpenMenu}
                sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                iconOnly
                aria-label="options"
              >
                <Icon px={0} py={0} alignItems="end">more_horiz</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" >
            {/* <MDBox data-testid = {((Math.round(item["amount"], 0) === Math.round(totalSpend, 0) ? "Fully Allocated" : item["amount"] < totalSpend ? "Allocated" : "Allocated")).toLowerCase().replaceAll(' ', '')} pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
              {Math.round(item["amount"], 0) === Math.round(totalSpend, 0) ? "Fully Allocated" : item["amount"] < totalSpend ? "Allocated" : "Allocated"}<br />
              {totalSpend ? formatAmount(totalSpend) : formatAmount(0)}&nbsp;
            </MDBox> */}
            <MDBox data-testid = {"chargeback"} pt={2} pb={2} sx={{ height: '100%', fontSize: "18px", fontWeight: "bold" }}>
              {"Chargeback"}<br />
              {totalSpend ? formatAmount(totalSpend) : formatAmount(0)}&nbsp;
            </MDBox>
            {Math.round(item["amount"], 0) === Math.round(totalSpend, 0) ? <Icon fontSize="large" color='success'>done</Icon> : <CircularProgressWithLabel value={totalSpend && totalSpend > 0 ? 100 * (totalSpend ?? 1) / item["amount"] : 0} aria-label="percent"/>}
          </MDBox>
          <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" omponent="li" />
          <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
            <MDTypography data-testid = {('Business Unit' + typeFilter).toLowerCase().replaceAll(' ', '')} sx={{ fontSize: "14px", pt: "8px" }}>{'Business Unit' + " " + typeFilter}:</MDTypography>
            <MDTypography data-testid = {(item["amount"] ? formatAmount(item["amount"],2) : 'No ' + typeFilter ).toLowerCase().replaceAll(' ', '')} sx={{ fontSize: "14px", pt: "8px" }}>{item["amount"] ? formatAmount(item["amount"],2) : 'No ' + typeFilter}</MDTypography>
            {/* <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{categoryFilter === "Tower" ? "Cost Pool Spend" : typeFilter}</MDTypography>
            <MDTypography sx={{ fontSize: "14px", pt: "8px" }}>{totalSpend ? formatAmount(totalSpend,2) : 'No '+typeFilter}</MDTypography> */}
          </MDBox>
          {typeFilter === "Spend" && 
            <>
              <MDBox display="flex" width="100%" flexDirection="row" justifyContent="space-between" >
                <MDTypography data-testid = {"chargeback"} sx={{ fontSize: "14px", pt: "8px", pb: "8px" }}>Chargeback</MDTypography>
                <MDTypography 
                 data-testid = {"view>"}
                 variant="link"
                  sx={{
                    fontSize: "14px",
                    padding: "8px",
                    borderRadius: "5px",
                    color: "#4A5AED",
                    "&:hover": {
                      cursor: "pointer",
                      color: "#435EC3",
                      backgroundColor: "#eceff8"
                    }
              }} fontWeight="medium" onClick={totalSpend ? () => { handleChargeBackView(), (null)} : () => {handleChargeBackPreview()}}
            >{ totalSpend ? "View >" : "+ Create"}</MDTypography>
              </MDBox>
            </>
          }
        </MDBox>
      </MDBox>
      }
    </Card>
  )
}
function CircularProgressWithLabel(props) {
  const { value, others } = { ...props }
  return (
    <MDBox sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={80} thickness={2} sx={{
        color: (theme) =>
          theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
      }} variant="determinate" value={100} {...others} aria-label="percent" />
      <CircularProgress size={80} thickness={2} sx={{
        color: (theme) => (theme.palette.mode === 'light' ? '' : '#308fe8'),
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }} disableShrink color={value > 100 ? "error" : "dprogress"} variant="determinate" value={isNaN(value) ? 0 : value > 100 ? 100 : value} {...others} aria-label="percent" />
      <MDBox
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MDTypography variant="caption" component="div" color="text.secondary">
          {isNaN(props.value) ? `0%` : props.value <= 100 ? `${Math.floor(props.value)}%` : `${Math.ceil(props.value)}%`}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default AnimatedRoute(MapRule);
