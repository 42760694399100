import MDBox from "components/MDBox";
import { Card, Icon, IconButton, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useYADialog } from "components/YADialog";
import MDTypography from "components/MDTypography";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import DataTable from "components/DataTable";
import colors from "assets/theme/base/colors";
import PropTypes from "prop-types";
import YAScrollbar from "components/YAScrollbar";
import EmptyState from 'components/EmptyState';
import no_data from 'assets/svg/choose_list.svg';
import { useMediaQuery, useTheme } from "@mui/material";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function notin(a, b) {
    return a.filter((value) => b.indexOf(value.id) === -1);
  }

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function TransferList({canSearch, left, setLeft, leftTitle, right, setRight, rightTitle}) {
  const [checked, setChecked] = React.useState([]);
  const [leftSearch, setLeftSearch] = React.useState("");
  const [rightSearch, setRightSearch] = React.useState("");
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items, search) => () => {
    let itms = items?.filter((item) =>
      `${item.name.toLowerCase()} (${item.code.toLowerCase()})`.includes(
        search.toLowerCase()
      )
    );
    if (numberOfChecked(itms) === itms.length) {
      setChecked(not(checked, itms));
    } else {
      setChecked(union(checked, itms));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items, search) => (
    <>
    <Card>
      <CardHeader
        sx={{ px: 0, py: 1 }}
        avatar={
          <Checkbox
            sx={{
              color: "primary",
            '&.Mui-checked': {
                color: "red",
            font: 'inherit',
            fontSize: '12px'
              },
            }}
            onClick={handleToggleAll(items, search)}
            // style={{outline: '1px solid #000'}}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={`${title} (${numberOfChecked(items)}/${items.length} selected)`}
      />
      <Divider />
      {canSearch && (
        <MDInput
          placeholder="Search..."
          inputProps={{ type: "search" }}
          value={search}
          size="small"
          sx={{ width: "100%", minWidth: "10rem", maxWidth: "24rem", margin: 0.5, pl: 0.5,}}
          onChange={({ currentTarget }) =>
            title === "Available Cost Centers"
              ? setLeftSearch(currentTarget.value)
              : setRightSearch(currentTarget.value)
          }
        />
      )}
      <Divider />
      <List
        sx={{ width: 400, height: 200, bgcolor: "background.paper", overflow: "auto" }}
        dense
        role="list"
      >
        {items?.filter(item => (`${item.name.toLowerCase()} (${item.code.toLowerCase()})`).includes(search.toLowerCase())).map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  />
                </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name} (${value.code})`} />
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Card>
    </>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        flexDirection: { xs: "column", lg: "row" }, // Vertical stack on small screens, horizontal on large
      }}
    >
      {/* Left Transfer List */}
      <Grid item xs={12} lg={5}>
        {customList(leftTitle, left, leftSearch)}
      </Grid>

      {/* Middle Section for Icons */}
      <Grid
        item
        xs={12}
        lg={0.7}
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "row" : "column", // Side by side on small screens
          alignItems: "center",
          justifyContent: "center",
          gap: isSmallScreen ? 1 : 2, // Adjust spacing between icons (smaller on small screens)
          my: { xs: 1, lg: 0 }, // Margin adjustment for vertical alignment
        }}
      >
        <IconButton
          color="info"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          title="Move Selected"
          sx={{
            p: 0.5, // Reduce padding to minimize space between icons
          }}
        >
          <Icon fontSize={isSmallScreen ? "large" : "medium"}>
            {isSmallScreen ? "keyboard_arrow_down" : "arrow_forward_ios"}
          </Icon>
        </IconButton>

        <IconButton
          color="info"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          title="Remove Selected"
          sx={{
            p: 0.5, // Reduce padding to minimize space between icons
          }}
        >
          <Icon fontSize={isSmallScreen ? "large" : "medium"}>
            {isSmallScreen ? "keyboard_arrow_up" : "arrow_back_ios"}
          </Icon>
        </IconButton>
      </Grid>

      {/* Right Transfer List */}
      <Grid item xs={12} lg={5}>
        {customList(rightTitle, right, rightSearch)}
      </Grid>
    </Grid>
  );
}

TransferList.propTypes = {
  left: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLeft: PropTypes.func.isRequired,
  leftTitle: PropTypes.string.isRequired,
  right: PropTypes.arrayOf(PropTypes.string).isRequired,
  setRight: PropTypes.func.isRequired,
  rightTitle: PropTypes.string.isRequired,
};


const UserCostCentres = () => {

    const handleError = useHandleError();
    const { showSnackbar } = useYADialog();
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [costCentres, setCostCentres] = useState([]);
    const [selectedCostCentres, setSelectedCostCentres] = useState([]);
    const [unassignedCostCentres, setUnassignedCostCentres] = useState([]);
    const [assignedCostCentres, setAssignedCostCentres] = useState([]);
    const [refresh] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpenAssigned, setDialogOpenAssigned] = useState(false);
    const [typeFilter, setTypeFilter] = useState("assignUser")
    const [selectedOption, setSelectedOption] = useState(null)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

    const tabStyles = (_theme, { selected }) => ({
      color: selected ? "#435EC3" : "#adadad",
      textTransform: "none",
      backgroundColor: "#F7F8FD",
      "& .MuiButtonBase-root": {
          fontSize: "18px!important",
          transform: "none",
          backgroundColor: "#435EC3",
          
      },
      "&::after": selected ? {
          content: '""',
          position: "absolute",
          bottom: 0,
          height: 4,
          width: "60%",
          borderRadius: "0px",
          backgroundColor: "#435EC3"
      } : {}
    });

    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/users/lookup/user`);
            if (err) {
                handleError(err);
            }
            else {
                setUsers(data)
                getUnAssignedCostCentres();
                getAssignedCostCentres();
                setLoading(false)
            }
        }
        getList();
    }, [refresh])

    const handleDialogOpen = () => {
      setDialogOpen(false)
    }  
    
    const handleAssignedDialogOpen = () => {
      setDialogOpenAssigned(false)
    }

    const getUnAssignedCostCentres = async () => {
      var [err, data] = await fetchRequest.get(`/api/users/lookup/costCentre`);
      if (err) {
          handleError(err);
      }
      else {
          var [err1, data1] = await fetchRequest.get(`/api/users/lookup/allUserCostCentre`);
          if (err1) {
              handleError(err1);
          }
          else {
              setUnassignedCostCentres(notin(data,data1.map((key) =>  key.id)))
          }
      }
    }

    const getAssignedCostCentres = async () => {
      var [err, data] = await fetchRequest.get(`/api/users/lookup/costCentre`);
      if (err) {
          handleError(err);
      }
      else {
          var [err1, data1] = await fetchRequest.get(`/api/users/lookup/allUserCostCentre`);
          if (err1) {
              handleError(err1);
          }
          else {
              const assignedCostCentres = data.filter(costCenter => data1.some(userCostCenter => userCostCenter.id === costCenter.id));
              setAssignedCostCentres(assignedCostCentres);
              var [err2, data2] = await fetchRequest.get(`/api/users/lookup/allAllocatedUserCostCentre`)
              if(err2) { 
                handleError(err2) 
              }
              else {  
                setAssignedCostCentres(data2)
              }
          }
      }
    }

    const handleUser = async (newValue) => {
        var [err, data] = await fetchRequest.get(`/api/users/lookup/costCentre`);
        if (err) {
            handleError(err);
        }
        else {
            var [err1, data1] = await fetchRequest.get(`/api/users/lookup/userCostCentre/${newValue.id}`);
            if (err1) {
                handleError(err1);
            }
            else {
                setSelectedCostCentres(data1)
                setCostCentres(notin(data,data1.map((key) =>  key.id),))
                setUser(newValue)
                getUnAssignedCostCentres()
                getAssignedCostCentres()
                setLoading(false)
                setSelectedOption(newValue)
            }
        }
    }

    const handleSave = async () => {
        var scc = selectedCostCentres.map((o)=>  { return ({"userId": user.id, "costCentreId": o.id}) })
        var [err, data] = await fetchRequest.post(`/api/users/save/${user.id}`, JSON.stringify(scc));
        if (err) {
            console.error('err')
            handleError(err);
        }
        else {
            showSnackbar(data.message, "success");
            // setUser(null)
            // setCostCentres([])
            // setSelectedCostCentres([])
            getUnAssignedCostCentres()
            getAssignedCostCentres()
        }
    }

    if (loading) {
        return <YASkeleton variant="dashboard-loading" />;
    } 

    const columns = [
      { Header: "Cost Center Code", accessor: "code", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Center Name", accessor: "name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    ] 
    
    const assignedColumns = [
      { Header: "User", accessor: "user", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Center", accessor: "costCenter", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "User ID", accessor: "userId", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    ]

    const AssignUser = () => {
      const [searchText, setSearchText] = useState("");
    
      return (
        <>
          <MDBox data-testid={"user"} pt={2} p={1} sx={{ height: "100%" }}>
            <MDBox borderRadius="6px" border="1px solid #ddd" width="100%" height="100%" display="flex" overflow="hidden" >
              <MDBox minWidth="360px" borderRight="1px solid #ddd" px={1} py={5} sx={{ background: "#FFFFFF" }}>
                {unassignedCostCentres.length ? <><MDButton data-testid = {"viewunassignedcostcenters"} onClick={() => setDialogOpen(true)} color='light' sx={{ width: "100%", height: "40px", fontSize: "11.5px" }}>View Unassigned Cost Centers <IconButton aria-label="Unassigned Cost Centers"><Icon size="small" color="error" title={"Unassigned Cost Centers"}>error</Icon></IconButton> </MDButton> </> : ''}
                <MDInput
                placeholder="Search users..."
                inputProps={{ type: "search" }}
                value={searchText}
                size="small"
                sx={{ width: "100%", mt: 2, pl: .5, mb: 2}}
                onChange={({ currentTarget }) => setSearchText(currentTarget.value)}
                />
                <YAScrollbar>
                  {users.filter(user => user.name.toLowerCase().includes(searchText.toLowerCase())).map((u) => {
                    let userName = u.name + ' (' + u.email + ')';
                    let shortName = userName.length > 70
                    return (
                      <>
                      <List>
                        <ListItem key={u.name}>
                          <Tooltip title={u.name + ' (' + u.email + ')'} placement="right">
                            <MDButton
                              data-testid={u.name?.toLowerCase().replaceAll(' ', '')}
                              name={u.name}
                              variant="text"
                              color="info"
                              sx={{
                                textTransform: "none",
                                py: 1,
                                pr: 1.5,
                                mb: 0.25,
                                background: selectedOption?.name === u.name ? '#F1EFEF' : "inherit",
                                textAlign: "left",
                                width: "100%",
                                justifyContent: "flex-start",
                                whiteSpace: "nowrap", // Prevents the text from wrapping
                                overflow: "hidden", // Hides overflow text
                                textOverflow: "ellipsis",
                              }}
                              onClick={() => { handleUser(u)}}
                            >
                              {shortName ? userName.substring(0, 45)+'...' : userName}
                            </MDButton>
                          </Tooltip>
                        </ListItem>
                        
                        <Divider style={{ background: '#adadad', margin: '2px' }} variant="middle" component="li"/>
                      </List>
                      </>
                    )
                  })}
                </YAScrollbar>
              </MDBox>
              <YAScrollbar>
                  <MDBox sx={{ m: 5 }} minHeight="calc(100vh - 300px)">   
                    <MDBox
                      sx={{
                        width: '100%',
                        maxWidth: '950px',
                        mx: 'auto',
                      }}
                    >
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          my: 2,
                          width: '100%',  
                          px: 4  
                        }}
                      >
                        {/* User Name */}
                        <MDBox display="flex" flex={1} alignItems="center">
                          <MDTypography
                            data-testid={selectedOption?.name.toLowerCase().replaceAll(' ', '')}
                            ml={1.5}
                            variant="h3"
                            color="text"
                          >
                            {selectedOption?.name}
                          </MDTypography>
                        </MDBox>

                        {/* Save Button */}
                        {isSmallScreen ? "" 
                        : 
                        user && (
                          <MDBox display="flex" mr={1.5} mt={2.5} alignItems="center" justifyContent="center">
                            <MDButton onClick={handleSave} color="info" startIcon={<Icon>save</Icon>}>
                              Save
                            </MDButton>
                          </MDBox>
                        )  
                    }
                      </MDBox>
                    </MDBox>
                    {user ? 
                    <TransferList canSearch={true} left={costCentres} setLeft={setCostCentres} leftTitle={'Available Cost Centers'} right={selectedCostCentres} setRight={setSelectedCostCentres}  rightTitle={'Assigned Cost Centers'} userName={selectedOption?.name}/> 
                    : 
                    <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="calc(100vh - 300px)"
                    >
                      <EmptyState
                        size="large"
                        image={no_data}
                        title={``}
                        description={
                          `choose the user from the list`
                        }
                      />
                    </MDBox>
                    }
                    {isSmallScreen ? user && (
                          <MDBox display="flex" mr={1.5} mt={2.5} alignItems="center" justifyContent="center">
                            <MDButton onClick={handleSave} color="info" startIcon={<Icon>save</Icon>}>
                              Save
                            </MDButton>
                          </MDBox>
                        ) 
                        : 
                        ""  
                    }
                  </MDBox>
              </YAScrollbar>
            </MDBox>
          </MDBox>
        </>
      );
    };
    return (
        <>
        <MDBox bgColor={colors.dashboardBackground} minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0, md: 6, sm: 6, xs: 6 }}>
            <Modal open={dialogOpen} onClose={handleDialogOpen}>
                <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Card sx={{ height: "80%", width: "60%", overflow: 'hidden' }}>
                        <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                            <MDBox data-testid = {"unassignedcostcenters"}>
                            <MDTypography variant="h6" component="span" color="text">
                                Unassigned Cost Centers
                            </MDTypography>
                            </MDBox>
                            <MDBox display="flex">
                            <IconButton onClick={handleDialogOpen} title="Close">
                                <Icon>close</Icon>
                            </IconButton>
                            </MDBox>
                        </MDBox>
                        <DataTable
                            variant="tile"
                            table={{ columns, rows: unassignedCostCentres }}
                            containerMaxHeight={424}
                            showTotalEntries={true}
                            isSorted={true}
                            noEndBorder
                            entriesPerPage={true}
                            canSearch={true}
                        >
                    </DataTable> 
                    </Card>
                </MDBox>
            </Modal>

            <Modal open={dialogOpenAssigned} onClose={handleAssignedDialogOpen}>
                <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Card sx={{ height: "80%", width: "60%", overflow: 'hidden' }}>
                        <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                            <MDBox data-testid = {"unassignedcostcenters"}>
                            <MDTypography variant="h6" component="span" color="text">
                                Assigned Cost Centers
                            </MDTypography>
                            </MDBox>
                            <MDBox display="flex">
                            <IconButton onClick={handleAssignedDialogOpen} title="Close">
                                <Icon>close</Icon>
                            </IconButton>
                            </MDBox>
                        </MDBox>
                        <DataTable
                            variant="tile"
                            table={{ columns: assignedColumns, rows: assignedCostCentres }}
                            containerMaxHeight={424}
                            showTotalEntries={true}
                            isSorted={true}
                            noEndBorder
                            entriesPerPage={true}
                            canSearch={true}
                        >
                    </DataTable> 
                    </Card>
                </MDBox>
            </Modal>
        
            <PageHeader title={"Cost Center Access"} subtitle={"Assign Cost Centers to a User. Only Data specific to the assigned Cost Centers will be visible for the User"} /> 
            <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", borderBottom: "1px solid #edeef3", borderTop: "1px solid #e3e3e3", display: "inline-flex" }} justifyContent="space-between">
                    <MDBox display="flex">
                      <MDButton data-testid={"assign"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "assignUser" })} onClick={() => setTypeFilter("assignUser")}>
                          <Icon color="dark" sx={{ marginRight: "6px" }} fontSize="50%">bookmarks</Icon>
                          ASSIGN
                      </MDButton>
                  </MDBox>
                  
            </MDBox>
            {typeFilter === 'assignUser' && <AssignUser/>}
          </MDBox>
        </>
    );
};

export default AnimatedRoute(UserCostCentres);