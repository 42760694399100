import { memo, useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Alert, Autocomplete, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import DrilldownFilterListItem from "../DrilldownFilterListItem";
import fetchRequest from "utils/fetchRequest";

const DrilldownTab = memo(({ config, visualizationData, globalFilters, onDrilldownConfigChange }) => {

  const [dashboardList, setDashboardList] = useState([]);

  useEffect(() => {
    async function getDashboards() {
      var [err, data] = await fetchRequest.get(`/api/dashboard/shared-list`)
      if (!err) {
        setDashboardList(data?.list || []);
      }
    }

    getDashboards();
  }, []);

  const onDashboardChange = (value) => {
    if (onDrilldownConfigChange)
      onDrilldownConfigChange("drilldownTo", value)
  };

  const handleAddFilterAsDependant = (filter) => {
    if (onDrilldownConfigChange)
      onDrilldownConfigChange("dependencies", (config?.dependencies || []).concat(filter));
  };

  const handleRemoveFilterAsDependant = (filter) => {
    if (onDrilldownConfigChange)
      onDrilldownConfigChange("dependencies", (config?.dependencies || []).filter(f => f !== filter));
  };
  
  const axisFields = visualizationData?.axis?.map(a => ({ queryName: a.name, name: a.title })) || [];
  const legendFields = visualizationData?.legend?.map(a => ({ queryName: a.name, name: a.title })) || [];
  const defaultFilters = [].concat(axisFields).concat(legendFields);
  const defaultFilterNames = defaultFilters.map(f => f.queryName);

  const globalFiltersFinal = (globalFilters || [])?.filter(f => !defaultFilterNames.includes(f.queryName));
  const dependentFilters = globalFiltersFinal?.filter(f => (config?.dependencies || []).includes(f.queryName));
  const remainingFilters = globalFiltersFinal?.filter(f => !(config?.dependencies || []).includes(f.queryName));

  return <MDBox px={2} py={1.5}>
    <MDTypography py={3} variant="caption" color="text" fontWeight="medium">Drilldown To</MDTypography>
    <Autocomplete
      options={dashboardList}
      value={config?.drilldownTo || null}
      onChange={(_, item) => {
        onDashboardChange(item?.value)
      }}
      getOptionLabel={option => {
        if (typeof option === "number")
          return dashboardList.find(op => op.value === option)?.label || "";
        if (typeof option === "string")
          return dashboardList.find(op => op.value?.toString().toLowerCase() === option?.toLowerCase())?.label || "";
        return option?.label || ""
      }}
      renderInput={params =>
        <TextField {...params}
          size="small"
          name={"drilldownTo"}
          placeholder={"Choose a dashboard"}
          variant={"outlined"}
          fullWidth={true}
        />
      }
    />
    {
      config?.drilldownTo &&
      <MDBox pt={3} pb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">Drilldown Filters</MDTypography>
        <MDBox>
          {
            defaultFilters?.map(f => (
              <DrilldownFilterListItem key={f.queryName} filter={f} defaultFilter />
            ))
          }
        </MDBox>
        {
          globalFiltersFinal?.length > 0 && (
            <Alert
              severity={"info"}
              sx={{ mt: 1, mb: 2, py: 0, px: 1.5, fontSize: "13px", textAlign: "left", border: "1px solid #ddd", "& .MuiAlert-icon": { mr: 1 } }}
            >
              Select any additional filters you want to pass on while drilldown.
            </Alert>
          )
        }
        <MDBox>
          {
            dependentFilters?.map(f => (
              <DrilldownFilterListItem key={f.queryName} filter={f} removeFromSelection onClick={handleRemoveFilterAsDependant} />
            ))
          }
        </MDBox>
        <MDBox>
          {
            remainingFilters?.map(f => (
              <DrilldownFilterListItem key={f.queryName} filter={f} onClick={handleAddFilterAsDependant} />
            ))
          }
        </MDBox>

      </MDBox>
    }
  </MDBox>
});

export default DrilldownTab;