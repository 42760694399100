import React from 'react';
import PropTypes from 'prop-types';
import TableRenderer from 'components/VisualizationRenderer/components/TableRenderer';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorBox from 'components/ErrorBox';
import YASkeleton from 'components/YASkeleton';
import MDBox from 'components/MDBox';
import ChartRenderer from './components/ChartRenderer';
import StatisticsRenderer from './components/StatisticsRenderer';
import TextRenderer from './components/TextRenderer';
import HeaderRenderer from './components/HeaderRenderer';
import useCubeMultiQueries from 'hooks/useCubeMultiQueries';

const TypeToChartComponent = {
    "table": ({ loading, title, subtitle, fluidLayout, vizOptions, resultSet, pivotConfig }) => {
        return (
            <TableRenderer
                fluidLayout={fluidLayout}
                loading={loading}
                title={title}
                subtitle={subtitle}
                vizOptions={vizOptions}
                resultSet={resultSet}
                pivotConfig={pivotConfig}
            />
        );
    },
    "pivot-table": ({ loading, title, subtitle, fluidLayout, vizOptions, resultSet, pivotConfig }) => {
        return (
            <TableRenderer
                pivotTable
                fluidLayout={fluidLayout}
                loading={loading}
                title={title}
                subtitle={subtitle}
                vizOptions={vizOptions}
                resultSet={resultSet}
                pivotConfig={pivotConfig}
            />
        );
    },
    "chart": ({ loading, title, subtitle, chartType, vizOptions, resultSet }) => {
        return (
            <ChartRenderer
                loading={loading}
                title={title}
                subtitle={subtitle}
                chartType={chartType}
                vizOptions={vizOptions}
                resultSet={resultSet}
            />
        );
    },
    "stats": ({ loading, title, subtitle, vizOptions, resultSet }) => {
        return (
            <StatisticsRenderer
                loading={loading}
                title={title}
                subtitle={subtitle}
                vizOptions={vizOptions}
                resultSet={resultSet}
            />
        );
    },
    "text": ({ title, subtitle, vizOptions }) => {
        return (
            <TextRenderer
                title={title}
                subtitle={subtitle}
                vizOptions={vizOptions}
            />
        );
    },
    "header": ({ title, subtitle, vizOptions }) => {
        return (
            <HeaderRenderer
                title={title}
                subtitle={subtitle}
                vizOptions={vizOptions}
            />
        );
    },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
    .map((key) => ({
        [key]: React.memo(TypeToChartComponent[key]),
    }))
    .reduce((a, b) => ({ ...a, ...b }));

const renderSkeleton = () => {
    return <MDBox height="100%" display="flex" alignItems="center" justifyContent="center">
        <YASkeleton variant="chart" />
    </MDBox>
};

const renderChart = (vizOptions, fluidLayout, Component, setDataCount) =>
    ({ isLoading, resultSet, error, chartType, pivotConfig }) => {
        if (error) {
            return <ErrorBox error={error} />
        }
        if (isLoading && !resultSet) {
            return renderSkeleton();
        }
        if (!isLoading && resultSet && setDataCount) {
            setDataCount((Array.isArray(resultSet) ? resultSet?.[0] : resultSet)?.tablePivot()?.length)
        }
        const finalResultSet = chartType === "combination-chart" ? resultSet : resultSet?.[0];
        return <ErrorBoundary>
            <Component fluidLayout={fluidLayout} chartType={chartType} vizOptions={vizOptions} resultSet={finalResultSet} pivotConfig={pivotConfig} />
        </ErrorBoundary>
    };

const Renderer = ({ vizOptions, fluidLayout, query, chartType, pivotConfig, setDataCount }) => {
    const component = TypeToMemoChartComponent[chartType] || TypeToMemoChartComponent["chart"];
    let renderProps = {};
    if (query)
        renderProps = useCubeMultiQueries(chartType === "combination-chart" ? query : [query]);
    // else if (query)
    //     renderProps = useCubeQuery(query);
    return component && renderChart(vizOptions, fluidLayout, component, setDataCount)({ ...renderProps, chartType, pivotConfig });
};

const VisualizationRenderer = ({ fluidLayout, vizState, vizOptions, setDataCount }) => {
    const { query, chartType, pivotConfig } = vizState;
    return <Renderer {...{ vizOptions, fluidLayout, query, chartType, pivotConfig, setDataCount }} />
};

VisualizationRenderer.propTypes = {
    vizState: PropTypes.object,
    vizOptions: PropTypes.object,
    cubeApi: PropTypes.object,
};

VisualizationRenderer.defaultProps = {
    fluidLayout: false,
    vizState: {},
    vizOptions: {},
    cubeApi: null,
};

export default VisualizationRenderer;