import { Icon, List, MenuItem, Select } from "@mui/material";
import CubeFilter from "components/CubeFilter";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { memo } from "react";
import { useDrop } from "react-dnd";
import { DataFieldTypes } from "components/DataField";

const conditionTypeStyles = ({ palette: { text, grey }, functions: { pxToRem } }) => {
    return {
        my: .7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        "& .MuiOutlinedInput-root fieldset": {
            border: 'none'
        },
        "& .MuiSelect-select": {
            color: text.main,
            fontWeight: "600",
            px: 1,
            py: .25,
            paddingRight: `${pxToRem(24)}!important`,
        },
        "& .MuiSelect-select:hover": {
            backgroundColor: grey[200],
        }
    }
};

const ConditionType = memo(({ conditionType, onConditionTypeChange }) => {
    const handleChange = (event) => {
        if (onConditionTypeChange)
            onConditionTypeChange(event.target.value);
    };

    return <MDBox component="li" sx={(theme) => conditionTypeStyles(theme)}>
        <Select
            value={conditionType || "and"}
            onChange={handleChange}
            size="small"
        >
            <MenuItem value="and">And</MenuItem>
            <MenuItem value="or">Or</MenuItem>
        </Select>
    </MDBox>
});

const FiltersPanel = memo(({ dashboardMode, chartType, filters, filters1, filterConditionType, filterConditionType1, onFilterConditionTypeChange, onFilterDrop, onFilterDelete, onFilterChange, onNewFilterAddition }) => {
    const [{ isOver, canDrop }, dropRef] = useDrop({
        accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE],
        drop: (item) => onFilterDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        })
    });

    const [{ isOver: isOver1, canDrop: canDrop1 }, dropRef1] = useDrop({
        accept: [DataFieldTypes.DIMENSION, DataFieldTypes.MEASURE],
        drop: (item) => onFilterDrop(item, false),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        })
    });

    const handleOnNewFilterAddition = (primary = true) => {
        onNewFilterAddition(primary);
    }

    let borderVal = '2px dashed #ddd'
    const isActive = (isOver && canDrop) || (isOver1 && canDrop1);
    if (isActive) {
        borderVal = '3px solid #4CAF50'
    } else if (canDrop) {
        borderVal = '3px solid #67bb6a'
    }
    return (
        <MDBox px={1.5}>
            {
                chartType === "combination-chart" && <MDTypography variant="caption" color="text" fontWeight="medium">Primary Chart</MDTypography>
            }
            {
                filters && filters?.map((f, i) =>
                    <List key={f.name} sx={{ pt: 1 }}>
                        <MDBox>
                            <CubeFilter key={f.name} filterDef={f} onFilterDelete={onFilterDelete} onFilterChange={onFilterChange} />
                            {
                                filters.length !== i + 1 &&
                                <ConditionType
                                    key={f.name}
                                    conditionType={filterConditionType}
                                    onConditionTypeChange={onFilterConditionTypeChange}
                                />
                            }
                        </MDBox>
                    </List>
                )
            }
            <MDBox ref={dropRef} px={.5} my={2} border={borderVal} borderRadius="6px" minHeight={50} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                {
                    !dashboardMode && <MDTypography data-testid={"dropfieldhereonly"} py={.75} variant="caption" color="text">Drop field here only</MDTypography>
                }
                {
                    dashboardMode && <MDTypography data-testid={"addafilter"} py={.75} variant="caption" color="text" display="flex" alignItems="center" gap={.5} sx={{ cursor: "pointer" }} onClick={handleOnNewFilterAddition}><Icon sx={{ fontSize: "20px!important" }} color="info">add</Icon> Add a filter</MDTypography>
                }
            </MDBox>
            {
                chartType === "combination-chart" && <>
                    <MDTypography mt={2} variant="caption" color="text" fontWeight="medium">Secondary Chart</MDTypography>
                    {
                        filters1 && filters1?.map((f, i) =>
                            <List key={f.name} sx={{ pt: 1 }}>
                                <MDBox>
                                    <CubeFilter key={f.name} filterDef={f} onFilterDelete={(name) => onFilterDelete(name, false)} onFilterChange={(name, config) => onFilterChange(name, config, false)} />
                                    {
                                        filters1.length !== i + 1 &&
                                        <ConditionType
                                            key={f.name}
                                            conditionType={filterConditionType1}
                                            onConditionTypeChange={(type) => onFilterConditionTypeChange(type, false)}
                                        />
                                    }
                                </MDBox>
                            </List>
                        )
                    }
                    <MDBox ref={dropRef1} px={.5} my={2} border={borderVal} borderRadius="6px" minHeight={50} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        {
                            !dashboardMode && <MDTypography data-testid={"dropfieldhereonly"} py={.75} variant="caption" color="text">Drop field here only</MDTypography>
                        }
                        {
                            dashboardMode && <MDTypography data-testid={"addafilter"} py={.75} variant="caption" color="text" display="flex" alignItems="center" gap={.5} sx={{ cursor: "pointer" }} onClick={() => handleOnNewFilterAddition(false)}><Icon sx={{ fontSize: "20px!important" }} color="info">add</Icon> Add a filter</MDTypography>
                        }
                    </MDBox>
                </>
            }
        </MDBox >
    )
});

export default FiltersPanel;