import { createRef, useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';
import { providerColorSet } from 'utils/charts';
import colors from "assets/theme/base/colors";
import numeral from 'numeral';

const PiesChartRenderer = ({ vizOptions }) => {

    const chartRef = createRef();
    const { width, height } = useResizeDetector();
    let range = [];
    let totalValue = 0
    let totalPercentage = 0
    let totalPrevValue = 0
    let perValue = 0
    let i = 0

    const [loading, setLoading] = useState(true);
    const [resultSet1, setResultSet1] = useState(null);
    
    useEffect(() => {
        async function getData() {
            if (vizOptions['graphData']) {
                setResultSet1(vizOptions['graphData'])
            } else {
                setResultSet1([])
            }
            setLoading(false);
        }
        getData(); 
    }, [vizOptions]);
    
    if (!loading && resultSet1) {
        resultSet1.map((item) => {
            if (vizOptions && vizOptions.series) {
                vizOptions.series.map((col) => {
                    range.push({
                        nameForColour: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                        name: col.useNameString ? col.name : (col.displayName ? (item[col.displayName] ? item[col.displayName] : (item[col.name] ? item[col.name] : '')) : ''),
                        color: colors.pieColors[i++],
                        y: Number(item[col.value]),
                        percentage: 0
                    })
                    totalValue = totalValue + col.value1 ?  Number(item[col.value1]) :  Number(item[col.value])
                })
            }
        });
    }

    for (let i = 0; i < range.length; i++) {
        range[i].percentage = Number(((range[i].y / totalValue) * 100).toFixed(0))
    }

    perValue = totalPrevValue === 0 ? 0 : vizOptions.wigetType === "doughnutpercent" ? Math.round((totalPrevValue / totalValue) * 100) : Math.round(((totalValue - totalPrevValue) / totalPrevValue) * 100);

    var obj = [
        {
            name: 'Total Spend',
            color: colors.grey[400],
            y: 100 - perValue,
            percentage: 0
        },
        {
            name: 'Cloud Spend',
            color: colors.pieColors[1],
            y: perValue,
            percentage: 0
        }
    ]

    if (vizOptions.piesType === "multipleMessure") 
        obj = [
        {
            name: vizOptions.label1 || '',
            color: colors.grey[400],
            y: 100 - perValue,
            amount: totalValue - totalPrevValue,
            percentage: 0
        },
        {
            name: vizOptions.label2 || '',
            color: colors.pieColors[1],
            y: perValue,
            amount: totalPrevValue,
            percentage: 0
        }
    ]

    range = vizOptions.wigetType === "doughnutpercent" ? obj : range
    var textColour = (totalValue > totalPrevValue ? "red" : "green");
    var perText = vizOptions.wigetType === "doughnutpercent" ? (Math.abs(perValue) + '%') : (textColour === 'green' ? ' ▼ ' : textColour === 'red' ? ' ▲ ' : '') + '' + (Math.abs(perValue) + '% ')
    var withText = (textColour === 'green' ? ' down from last month' : textColour === 'red' ? ' up from last month' : '')

    const totWidthScreen = window.innerWidth
    var titleSize, subtitleSize, ySize

    if (totWidthScreen <= 360) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 20 : 15; subtitleSize = vizOptions.wigetType === "doughnutpercent" ? 10 : 8; ySize = 20 }
    else if (totWidthScreen <= 450 && totWidthScreen > 360) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 30 : 19; subtitleSize = 15; ySize = 26 }
    else if (totWidthScreen <= 660 && totWidthScreen > 450) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 15; ySize = 26 }
    else if (totWidthScreen <= 1024 && totWidthScreen > 660) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 14; ySize = 32 }
    else if (totWidthScreen <= 1090 && totWidthScreen > 1024) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 30 : 18; subtitleSize = 10; ySize = 32 }
    else if (totWidthScreen <= 1280 && totWidthScreen > 1090) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 12; ySize = 32 }
    else if (totWidthScreen <= 1400 && totWidthScreen > 1280) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 15; ySize = 32 }
    else if (totWidthScreen > 1400) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 15; ySize = 32 }



    if (vizOptions.providerview) {
        range.forEach((item) => {
            item.color = providerColorSet(item.nameForColour, 0, colors.providerColors)
        })
    }

    var opts = {
        chart: {
            plotBackgroundColor: null,
            backgroundColor: vizOptions.backgroundColor??null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            width: vizOptions.width??width,
            height: vizOptions.wigetType === "doughnutpercent" ? vizOptions.height??height : vizOptions.height??height * 0.945,
            style: { fontFamily: 'inherit', paddingTop: '0', fontSize: '20px', color: "#9EAEE5" }
        },
        legend: {
            itemStyle: { fontFamily: 'inherit', fontWeight: 500 },
        },
        title: vizOptions.showTitle ?
            // {
            //     text: vizOptions.wigetType === "doughnutpercent" ? vizOptions.subcategories ? '<span style="font-size: ' + subtitleSize + 'px;">Total Cloud Spend</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + numeral(totalPrevValue.toFixed(2)).format('$0,0') + '</span></b>' :
            //         '<span style="font-weight: bold; font-size: 25px;"><br>' + perText + '</span>'
            //         :
            //         '<span style="font-size: ' + titleSize + 'px;">Total Spend</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + numeral(totalValue.toFixed(2)).format('$0,0') + '</span></b><br/><span style="font-size: ' + subtitleSize + 'px;"><span style="color:' + textColour + '">' + perText + '</span><br/>' + withText + '</span>',
            //     align: 'center',
            //     verticalAlign: 'middle',
            //     y: 12
            // }
            {
                text: vizOptions.wigetType === "doughnutpercent" ? vizOptions.subcategories ? ' <span style="font-size: ' + subtitleSize + 'px;">'+"  "+'</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + totalPercentage + '%' + '</span></b>' :
                    '<span style="font-weight: bold; font-size: 25px;"><br>&nbsp;' + perText + '</span>'
                    :
                    vizOptions.recommendation?'<span style="font-size: ' + titleSize + 'px;">Total Saving</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + numeral(totalValue.toFixed(2)).format('$0,0') + '</span></b><br/><span style="font-size: ': vizOptions.disableComparison ? '<span style="font-size: ' + titleSize + 'px;">Total Spend</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + numeral(totalValue.toFixed(2)).format('$0,0') + '</span></b><br/><span style="font-size: ': vizOptions.disableComparison ? '<span style="font-size: ' + titleSize + 'px;">Total Saving</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + numeral(totalValue.toFixed(2)).format('$0,0') + '</span></b><br/><span style="font-size: ' : '<span style="font-size: ' + titleSize + 'px;">Total Spend</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + numeral(totalValue.toFixed(2)).format('$0,0') + '</span></b><br/><span style="font-size: ' + subtitleSize + 'px;"><span style="color:' + textColour + '">' + perText + '</span><br/>' + withText + '</span>',
                align: 'center',
                verticalAlign: 'middle',
                y: vizOptions.wigetType === "doughnutpercent" ? ySize : 12
            }
            : '',
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: vizOptions.wigetType === "doughnutpercent" ? false : true,
                cursor: (vizOptions["popupTo"] || vizOptions["drillTo"]) ? "pointer" : "default",
                innerSize: vizOptions.wigetType === "doughnut" ? vizOptions.innerSize??'80%' : vizOptions.wigetType === "doughnutpercent" ? vizOptions.innerSize??'60%' : '0',
                center: ['50%', '50%'],
                size: vizOptions.showTitle ? '60%' : '50%',
                dataLabels: {
                    // enabled: vizOptions.wigetType === "doughnutpercent" ? false : true,
                    enabled: vizOptions.dataLabels??true,
                    style: {
                        fontSize: vizOptions.title ? '15px' : '11px',
                        fontFamily: 'inherit',
                        fontWeight: 400,
                    },
                    // distance: -40,
                    // formatter: function () {
                    //     return `${this.point.name}<br><b>${Highcharts.numberFormat(this.point.percentage, 0)}%</b>&nbsp;($${Highcharts.numberFormat(this.point.y, 0, ".", ",")})`;
                    // }
                    formatter: vizOptions.wigetType === "doughnutpercent" ? function () {
                        return '<b>' + this.point.name + '</b>' + ': $' + Highcharts.numberFormat(this.point.amount, 0, ".", ",") + ` (${Highcharts.numberFormat(this.point.percentage, 0)}%)`;
                    } :
                        function () {
                        return `${this.point.name}<br><b>${Highcharts.numberFormat(this.point.percentage, 0)}%</b>&nbsp;($${Highcharts.numberFormat(this.point.y, 0, ".", ",")})`;
                    }
                },
                point: {
                    showInLegend: true,
                    cumulative: 100,
                },
            },
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        enabled: vizOptions.wigetType === "doughnutpercent" ? false : true
                    }
                }
            }
        },
        tooltip: {
            enabled: vizOptions.wigetType === "doughnutpercent" ? true : true,
            outside: false,
            formatter: vizOptions.wigetType === "doughnutpercent" ? vizOptions.subcategories ? function () {
                return '<b>' + this.point.name + '</b>' + ': $' + Highcharts.numberFormat(this.point.amount, 0, ".", ",") + ` (${this.point.y}%)`;
            } :
                function () {
                    return '<b>' + this.point.name + '</b>' + ': ' + this.point.y + '%';
                }
                :
                function () {
                    return '<b>' + this.point.name + '</b>' + ': $' + Highcharts.numberFormat(this.point.y, 0, ".", ",");
                }
        },
        series: [{
            name: 'Spend',
            // colorByPoint: true,
            data: range,
        }]
    }
    
    return (
        <div style={{ position: 'flex', left: 0, top: 0, bottom: 0, right: 0 }}>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
        </div>
    )
}

export default PiesChartRenderer;