import MDBox from "components/MDBox";
import YAScrollbar from "components/YAScrollbar";
import WidgetConfigPanel from "../WidgetConfigPanel";
import ErrorBoundary from "components/ErrorBoundary";
import { useImmer } from "use-immer";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { getQueryDetails } from "utils/visualization";
import { getVizConfig } from "utils/visualization";
import { DEFAULT_PIVOT_CONFIG_TEMPLATE } from "utils/visualization";
import { chartTypeSettingDefs } from "components/VisualizationRenderer/components/ChartRenderer/constants";
import { rebuildWidget } from "context/CustomDashboardEditorContext";
import { useCustomDashboardEditorContext } from "context/CustomDashboardEditorContext";
import { DataFieldTypes } from "components/DataField";
import { constructVisualizationData } from "utils/visualization";

const getConfigItem = (chartType, configType) => {
    const configItems = chartTypeSettingDefs.find(s => s.type === chartType).configItems;
    return configItems?.find(c => c.type === configType) || null;
};

const WidgetConfigDrawer = ({ selectedWidgetId, onClose }) => {

    const [state, dispatch] = useCustomDashboardEditorContext();
    const { widgets, filters: globalFilters } = state.config;
    const selectedWidget = useMemo(() => selectedWidgetId ? widgets?.find(c => c.id === selectedWidgetId) : null, [selectedWidgetId]);

    const [visualizationData, setVisualizationData] = useImmer(constructVisualizationData(selectedWidget) || {});
    const hoverIndex = useRef(null);

    useEffect(() => {
        if (Object.keys(visualizationData).length > 0) {
            const { query, pivotConfig } = getQueryDetails(visualizationData.chartType, visualizationData, state.variables || {});

            const vizData = getVizConfig(visualizationData.chartType, visualizationData.cubeId, query, pivotConfig || DEFAULT_PIVOT_CONFIG_TEMPLATE, visualizationData, visualizationData.config, visualizationData.drilldown)

            rebuildWidget(dispatch, selectedWidgetId, vizData.vizState, vizData.vizOptions, visualizationData.filters, visualizationData.filters1, visualizationData.filterConditionType, visualizationData.filterConditionType1)

        } else {
            // setQuery(DEFAULT_QUERY_TEMPLATE);
            // setPivotConfig(DEFAULT_PIVOT_CONFIG_TEMPLATE);
        }
    }, [visualizationData]);

    const handleOnSelection = useCallback((type, item, checked) => {
        if (type) {
            const configItem = getConfigItem(visualizationData.chartType, type);
            const singleItemConfig = configItem.singleItem || false;

            if (checked) {
                setVisualizationData(draft => {
                    let visualizationData = draft[type];
                    if (!visualizationData?.find(c => c.name === item.name)) {
                        let visItem = { ...item };
                        if (type === 'sort')
                            visItem = { ...item, ...{ sortName: item.sortName || item.name, sortAsc: true, dropType: undefined } };

                        if (!visualizationData)
                            draft[type] = [];
                        if (singleItemConfig)
                            draft[type] = [visItem];
                        else
                            draft[type].push(visItem);
                    }
                });
            } else {
                setVisualizationData(draft => {
                    ["rows", "columns", "values", "values1", "sort", "filters", "filters1", "axis", "legend"].forEach(t => {
                        if (draft[t] && draft[t].length > 0)
                            draft[t] = draft[t].filter(c => c.name !== item.name);
                    });
                });
            }
        }
    }, [visualizationData])

    const handleOnFilterSelection = useCallback(
        (item, primary = true) => {
            setVisualizationData(draft => {
                let filters = draft[primary ? "filters" : "filters1"];
                if (!filters?.find(c => c.name === item.name)) {
                    if (!filters)
                        draft[primary ? "filters" : "filters1"] = [];
                    draft[primary ? "filters" : "filters1"].push(item)
                }
            });
        },
        []
    );

    const resetVisualizationData = useCallback((prevChartType, newChartType) => {
        if (newChartType === "stats") {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                ["axis", "columns", "values1", "axis", "legend"].forEach(type => {
                    if (draft[type])
                        draft[type] = [];
                });
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                };
                draft["drilldown"] = {};
                // draft["values"] = (draft.values || []).length > 0 ? (draft.values || [])[0] : [];
            });
        }
        else if (prevChartType === "table" && newChartType === "pivot-table") {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                draft.columns = [];
                draft.rows = draft.values?.filter(v => v.type === DataFieldTypes.DIMENSION);
                draft.values = draft.values?.filter(v => v.type === DataFieldTypes.MEASURE);
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                };
            });
        }
        else if (prevChartType === "pivot-table" && newChartType === "table") {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                let values = [];
                draft.rows?.forEach(item => {
                    if (!values.find(f => f.name === item.name))
                        values.push(item);
                });
                draft.columns?.forEach(item => {
                    if (!values.find(f => f.name === item.name))
                        values.push(item);
                });
                draft.values?.forEach(item => {
                    if (!values.find(f => f.name === item.name))
                        values.push(item);
                });
                draft.values = values;
                draft.columns = [];
                draft.rows = [];
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                }
            });
        }
        else if (newChartType === "table") {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                let values = [];
                ["rows", "columns", "values", "values1", "axis", "legend"].forEach(type => {
                    draft[type]?.forEach(item => {
                        if (!values.find(f => f.name === item.name))
                            values.push(item);
                    });
                    if (draft[type])
                        draft[type] = [];
                });
                draft.values = values;
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                }
                draft["drilldown"] = {};
            });
        }
        else if (newChartType === "pivot-table") {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                let rows = [], values = [];
                if (["bar-chart", "stacked-bar-chart", "column-chart", "stacked-column-chart", "area-chart"].includes(prevChartType)) {
                    ["rows", "columns", "values", "values1", "axis"].forEach(type => {
                        draft[type]?.forEach(item => {
                            if (item.type === DataFieldTypes.MEASURE && !values.find(f => f.name === item.name))
                                values.push(item);
                            else if (item.type === DataFieldTypes.DIMENSION && !rows.find(f => f.name === item.name))
                                rows.push(item);
                        });
                        if (draft[type])
                            draft[type] = [];
                    });
                    draft.values = values;
                    draft.rows = rows;
                    draft.columns = draft.legend || [];
                    draft["drilldown"] = {};
                }
                else {
                    ["rows", "columns", "values", "values1", "axis", "legend"].forEach(type => {
                        draft[type]?.forEach(item => {
                            if (item.type === DataFieldTypes.MEASURE && !values.find(f => f.name === item.name))
                                values.push(item);
                            else if (item.type === DataFieldTypes.DIMENSION && !rows.find(f => f.name === item.name))
                                rows.push(item);
                        });
                        if (draft[type])
                            draft[type] = [];
                    });
                    draft.values = values;
                    draft.rows = rows;
                    draft.columns = [];
                }
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                };
                draft["drilldown"] = {};
            });
        }
        else if (prevChartType === "table") {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                let axis = [], values = [];
                draft["values"]?.forEach(item => {
                    if (item.type === DataFieldTypes.MEASURE)
                        values.push(item);
                    else if (item.type === DataFieldTypes.DIMENSION)
                        axis.push(item);
                });
                draft.values = values;
                draft.axis = axis;
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                }
            });
        }
        else if (prevChartType === "pivot-table") {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                if (["bar-chart", "stacked-bar-chart", "column-chart", "stacked-column-chart", "area-chart"].includes(newChartType)) {
                    draft.axis = draft.rows;
                    draft.legend = draft.columns;
                }
                else {
                    draft.axis = (draft.rows || []).concat(draft.columns || []);
                }
                draft.columns = [];
                draft.rows = [];
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                }
            });
        }
        else if (prevChartType === "combination-chart" || newChartType === "combination-chart") {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                let axis = [], values = [];
                ["axis", "columns", "values", "values1", "axis", "legend"].forEach(type => {
                    draft[type]?.forEach(item => {
                        if (item.type === DataFieldTypes.MEASURE && !values.find(f => f.name === item.name))
                            values.push(item);
                        else if (item.type === DataFieldTypes.DIMENSION && !axis.find(f => f.name === item.name))
                            axis.push(item);
                    });
                    if (draft[type])
                        draft[type] = [];
                });
                draft.values = values;
                draft.axis = axis;
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                }
                draft["drilldown"] = {};
            });
        }
        else if (
            ((["stats", "pie-chart", "donut-chart", "line-chart", "spline-chart", "treemap-chart", "sankey-chart", "bar-chart", "stacked-bar-chart", "column-chart", "stacked-column-chart", "area-chart"].includes(prevChartType) && ["bar-chart", "stacked-bar-chart", "column-chart", "stacked-column-chart", "area-chart"].includes(newChartType))) ||
            ((["pie-chart", "donut-chart", "line-chart", "spline-chart", "treemap-chart", "sankey-chart"].includes(prevChartType) && ["pie-chart", "donut-chart", "line-chart", "spline-chart", "treemap-chart", "sankey-chart"].includes(newChartType)))
        ) {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                }
            });
        }
        else if (
            (["stats", "bar-chart", "stacked-bar-chart", "column-chart", "stacked-column-chart", "area-chart"].includes(prevChartType) && ["pie-chart", "donut-chart", "line-chart", "spline-chart", "treemap-chart", "sankey-chart"].includes(newChartType))
        ) {
            setVisualizationData(draft => {
                draft.chartType = newChartType;
                draft.axis = (draft.axis || []).concat(draft.legend || []);
                draft.legend = [];
                draft["config"] = {
                    card_title: draft.config?.card_title,
                    card_subtitle: draft.config?.card_subtitle,
                }
            });
        }
        else {
            setVisualizationData(draft => ({
                cubeId: draft.cubeId,
                chartType: newChartType,
                config: {
                    card_title: draft.config.card_title,
                    card_subtitle: draft.config.card_subtitle,
                }
            }));
        }
        return {};
    },
        []
    );

    const handleOnFilterConditionTypeChange = (value, primary = true) => {
        setVisualizationData(draft => {
            draft[primary ? "filterConditionType": "filterConditionType1"] = value;
        });
    };

    const handleOnChangeChartType = (value) => {
        const previousChartType = visualizationData.chartType;
        resetVisualizationData(previousChartType, value);
    };

    const handleOnChangeCubeId = (value) => {
        setVisualizationData(draft => ({
            cubeId: value,
            chartType: draft.chartType,
            config: {
                ...(draft.config || {})
            }
        }));
    };

    const handleOnChangeChartConfig = (configName, value) => {
        setVisualizationData(draft => {
            // remove old config if exists;
            if (!draft.config)
                draft.config[configName] = {};

            draft.config[configName] = value;
        });
    };

    const handleOnChangeDrilldownConfig = (configName, value) => {
        setVisualizationData(draft => {
            // remove old config if exists;
            if (!draft.drilldown)
                draft.drilldown = {};

            draft.drilldown[configName] = value;
        });
    };

    const handleOnFilterDrop = useCallback(
        (item, primary = true) => {
            setVisualizationData(draft => {
                let filters = draft[primary ? "filters": "filters1"];
                if (!filters?.find(c => c.name === item.name)) {
                    if (!filters)
                        draft[primary ? "filters": "filters1"] = [];
                    draft[primary ? "filters": "filters1"].push(item)
                }
            });
        },
        []
    );

    const handleOnFilterDelete = useCallback(
        (itemName, primary = true) => {
            setVisualizationData(draft => {
                if (draft[primary ? "filters": "filters1"]) {
                    draft[primary ? "filters": "filters1"] = draft[primary ? "filters": "filters1"].filter(c => c.name !== itemName);
                }
            });
        },
        [visualizationData]
    );

    const handleOnFilterChange = useCallback(
        (filterName, filter, primary = true) => {
            setVisualizationData(draft => {
                let filterRef = draft[primary ? "filters": "filters1"]?.find(c => c.name === filterName);
                if (filterRef) {
                    filterRef.member = filter.name;
                    filterRef.operator = filter.operator;
                    filterRef.values = filter.values;
                }
            });
        },
        []
    )

    const handleOnFieldSort = useCallback(
        (fieldName) => {
            setVisualizationData(draft => {
                let field = draft["sort"]?.find(f => f.name === fieldName);
                if (field)
                    field.sortAsc = !field.sortAsc;
            });
        },
        []
    );

    const handleOnFieldSettingChange = useCallback(
        (type, fieldName, settingName, settingValue) => {
            setVisualizationData(draft => {
                let field = draft[type]?.find(f => f.name === fieldName);
                if (field)
                    field[settingName] = settingValue;
            });
        },
        []
    );

    const handleOnFieldHover = useCallback(
        (dropType, index) => {
            hoverIndex.current = { dropType, index };
        },
        [hoverIndex]
    );

    const handleOnFieldDrop = useCallback(
        (type, singleItem, item) => {
            if (type) {
                setVisualizationData(draft => {
                    // if dropped from other drop location, delete it from there first
                    if (type !== "sort" && item.dropType !== "sort" && item.dropType && item.dropType !== type) {
                        draft[item.dropType] = draft[item.dropType].filter(c => c.name !== item.name);
                    }

                    let dropTypeData = draft[type];
                    if (!dropTypeData?.find(c => c.name === item.name)) {
                        if (!dropTypeData)
                            draft[type] = [];

                        if (type !== "sort" && singleItem)
                            draft[type] = [{ ...item, ...{ dropType: undefined } }];
                        else {
                            let visItem = null;
                            if (type === "sort") {
                                let found = 0;
                                ["rows", "columns", "values", "sort"].forEach((t) => {
                                    if (!draft[t]) draft[t] = []
                                    if (draft[t]?.filter((o) => o.name === item?.name)?.length > 0) found = 1
                                })
                                if (found === 0 && draft.chartType === 'table') draft['values'].push(item);
                                else if (found === 0 && draft.chartType === 'pivot-table') {
                                    if (item?.type === 'DIMENSION') draft['rows'].push(item);
                                    else draft['values'].push(item)
                                }
                                visItem = { ...item, ...{ sortName: item.sortName || item.name, sortAsc: true, dropType: undefined } };
                            } else {
                                visItem = { ...item, ...{ dropType: undefined } };
                            }

                            if (!isNaN(hoverIndex.current?.index) && hoverIndex.current?.dropType === type) {
                                draft[type].splice(hoverIndex.current.index, 0, visItem);
                            }
                            else {
                                draft[type].push(visItem);
                            }
                        }
                    }
                });
                //reset hoverIndex
                hoverIndex.current = null;
            }
        },
        [visualizationData, hoverIndex]
    );

    const handleOnFieldDelete = useCallback(
        (type, item) => {
            if (type && item) {
                setVisualizationData(draft => {
                    if (draft[type]) {
                        draft['sort'] = draft['sort']?.filter(c => c.name !== item.name);
                        draft[type] = draft[type].filter(c => c.name !== item.name);
                    }
                });
            }
        },
        [visualizationData]
    );

    const handleOnFieldMove = useCallback(
        (type, field, fromIndex, toIndex) => {
            if (fromIndex !== undefined && toIndex !== undefined) {
                setVisualizationData(draft => {
                    if (type === field.dropType && draft[type]) {
                        const element = draft[type].splice(fromIndex, 1)[0];
                        draft[type].splice(toIndex, 0, element);
                    }
                });
            }
        },
        []
    );

    return <MDBox
        minWidth={350}
        maxWidth={350}
        borderLeft="1px solid #ddd"
        backgroundColor="#fff!important"
        boxShadow="0 8px 16px #1a488e1f!important"
        position="fixed"
        right={0}
        bottom={0}
        top={0}
        zIndex={10}
    >
        <ErrorBoundary>
            <YAScrollbar>
                <MDBox px={.5} height="100vh">
                    <WidgetConfigPanel
                        chartType={visualizationData.chartType}
                        cubeId={visualizationData.cubeId}
                        visualizationData={visualizationData}
                        globalFilters={globalFilters}
                        onChartTypeChange={handleOnChangeChartType}
                        onCubeIdChange={handleOnChangeCubeId}
                        onHover={handleOnFieldHover}
                        onDrop={handleOnFieldDrop}
                        onDelete={handleOnFieldDelete}
                        onMove={handleOnFieldMove}
                        onFieldSort={handleOnFieldSort}
                        onFieldSettingChange={handleOnFieldSettingChange}
                        onConfigChange={handleOnChangeChartConfig}
                        onNewFieldAddition={handleOnSelection}
                        onNewFilterAddition={handleOnFilterSelection}
                        onFilterConditionTypeChange={handleOnFilterConditionTypeChange}
                        onFilterDrop={handleOnFilterDrop}
                        onFilterDelete={handleOnFilterDelete}
                        onFilterChange={handleOnFilterChange}
                        onDrilldownConfigChange={handleOnChangeDrilldownConfig}
                        onClose={onClose}
                    />
                </MDBox>
            </YAScrollbar>
        </ErrorBoundary>
    </MDBox>
}

export default WidgetConfigDrawer;