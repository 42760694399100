import { Icon, IconButton } from "@mui/material";
import AnimatedRoute from "components/AnimatedRoute";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useRef, useState } from "react";
import ErrorBoundary from "components/ErrorBoundary";
import { useSocketContext } from "context/SocketContext";
import EmptyState from "components/EmptyState";
import MDButton from "components/MDButton";
import ThinkingBox from "./ThinkingBox";
import { generateUUID } from "utils";
import { AnimatedComponentChildren } from "components/AnimatedComponent";

const ResponseText = ({ text }) => <MDTypography component="p" variant="button" fontWeight="light" lineHeight={1.5} whiteSpace="pre-wrap">{text}</MDTypography>

const dialogStyles = () => ({
    width: "100%",
    maxWidth: "500px",
    height: "100vh",
    // borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    // justifyContent="center"
    flexDirection: "column",
    margin: "0 0 0 auto",
    overflow: "hidden",
    "@keyframes cursorBlinkAnim": {
        '0%': {
            borderRightColor: "initial"
        },
        '100%': {
            borderRightColor: "transparent"
        }
    },
    "@keyframes answerFadeInAnim": {
        '0%': {
            opacity: 0
        },
        '100%': {
            opacity: 1
        }
    },
    "& .cursorBox": {
        whiteSpace: "pre-wrap",
    },
    "& .cursorBox::after": {
        content: '""',
        paddingRight: .5,
        borderRight: "12px solid #ddd",
        animation: "cursorBlinkAnim .6s infinite alternate",
    },
    "& .answerFadeIn": {
        animation: "answerFadeInAnim .5s ease-in-out forwards",
        opacity: 0
    }
});

const normalizeData = (data) => data?.map(obj => Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.includes(".") ? key.split(".")[1] : key, value]))) || [];
const normalizeFilters = (filters) => filters?.filter(f => (f.values || []).length > 0)?.map(f => ({ name: f.name, value: (f.values || []).join(", ") })) || [];

const DashboardCopilotContainer = ({ sessionId, config, onClose }) => {

    const [submitting, setSubmitting] = useState(true);
    const [response, setResponse] = useState(null);
    const [retry, setRetry] = useState(null);
    const [error, setError] = useState(false);
    const scrollbarRef = useRef();

    const { socket, socketConnected } = useSocketContext();

    useEffect(() => {
        setError(false);
        if (socketConnected && config.data?.length > 0) {
            setSubmitting(true);
            const dashboardConfig = {
                sessionId: sessionId,
                title: config.title,
                description: config.description,
                data: normalizeData(config.data),
                filters: normalizeFilters(config.filters)
            };
            socket.emit("explainGraph", { ...dashboardConfig });
        }
    }, [socket, socketConnected, retry]);

    useEffect(() => {

        socket?.on('disconnect', () => {
            setError(true);
            setSubmitting(false);
        });

        socket?.on("explainGraphResponse", (message) => {
            if (message?.data === "ERROR") {
                setResponse(null);
                setError(true);
                setSubmitting(false);
            } else {
                if (sessionId === message.sessionId) {
                    setResponse(message.data);
                    setError(false);
                    setSubmitting(false);
                }
            }

        });

        () => {
            socket?.off("explainGraphResponse");
            socket?.off("disconnect");
        }

    }, [socket]);

    useEffect(() => {
        setTimeout(() => {
            if (scrollbarRef?.current) {
                scrollbarRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
    }, [response]);

    const render = () => {
        if (!socketConnected)
            return <MDBox display="flex" flexDirection="column" alignItems="center" pt={5}>
                <EmptyState
                    iconName="error"
                    variant="error"
                    size="medium"
                    title={"Connection interrupted."}
                />
            </MDBox>

        if (error)
            return <MDBox display="flex" flexDirection="column" alignItems="center" pt={5}>
                <EmptyState
                    iconName="error"
                    variant="error"
                    size="medium"
                    title={"Something went wrong!"}
                    actions={
                        <MDButton color="info" variant="outlined" size="small" onClick={() => setRetry(Math.random())}>Try again</MDButton>
                    }
                />
            </MDBox>

        if ((config.data || [])?.length === 0)
            return <MDBox display="flex" flexDirection="column" alignItems="center" pt={5}>
                <EmptyState
                    iconName="warning"
                    variant="warning"
                    size="medium"
                    title={"No data to analyze."}
                />
            </MDBox>

        if (submitting)
            return <MDBox px={3} pt={1} width="100%">
                <ThinkingBox />
            </MDBox>

        return <MDBox flex={1} width="100%" overflow="auto" display="flex" pt={1} mb={3} px={3}>
            <AnimatedComponentChildren>
                <ResponseText text={response} />
            </AnimatedComponentChildren>
        </MDBox>
    }

    return (
        <MDBox width="100%" height="100%" display="flex" flexDirection="column">
            <MDBox width="100%">
                <MDBox
                    pl={3}
                    pr={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    height={48}
                >
                    <MDTypography variant="subtitle2" fontWeight={"medium"}>Ask YäRKEN</MDTypography>
                    <IconButton onClick={onClose} title="Close">
                        <Icon>close</Icon>
                    </IconButton>
                </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="column" py={1.5} px={2} my={2} mx={3} gap={.5} bgColor="#efefef">
                <MDTypography variant="caption" fontWeight={"medium"}>{config.title}</MDTypography>
                <MDTypography variant="caption">{config.description}</MDTypography>
            </MDBox>
            {render()}
        </MDBox>
    );
};


const DashboardCopilotExplain = ({ config, onClose }) => {
    const sessionId = generateUUID();
    return <MDBox bgColor="white" sx={theme => dialogStyles(theme)}>
        <ErrorBoundary>
            <DashboardCopilotContainer sessionId={sessionId} config={config} onClose={onClose} />
        </ErrorBoundary>
    </MDBox>
};

export const DashboardCopilotExplainSidebar = ({ config, onClose }) => {
    const sessionId = generateUUID();
    return <MDBox bgColor="white" position="absolute" top={0} right={0} bottom={0} height="100%" width="100%" maxWidth="500px" borderLeft="1px solid #ddd" overflow="auto">
        <ErrorBoundary>
            <DashboardCopilotContainer sessionId={sessionId} config={config} onClose={onClose} />
        </ErrorBoundary>
    </MDBox>
};

export default AnimatedRoute(DashboardCopilotExplain);