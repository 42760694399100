import _ from "lodash";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { Icon, Card, FormControlLabel, Switch as MDSwitch, Modal,  IconButton, Autocomplete, Checkbox, Chip, Tooltip   } from "@mui/material";
import { createContext, useEffect, useRef, useState } from "react";
import { getDomain, sanitizeCell } from 'utils';
import PageHeader from "components/PageHeader";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useYADialog } from "components/YADialog";
import EmptyState from "components/EmptyState";
import new_item_img from "assets/svg/add_new.svg";
import MDButton from "components/MDButton";
import numeral from "numeral";
import { BusinessCaseNonCloudItemForm, BusinessCaseCloudItemForm } from "components/BusinessCaseItemForm";
import * as XLSX from 'xlsx';
import Axios from "axios";
import { useImmer } from "use-immer";
import { useLocation, useParams } from "react-router-dom";
import AnimatedRoute from "components/AnimatedRoute";
import moment from "moment";
import BusinessCaseHeader from "components/BusinessCaseHeader";
import CommentsDrawer from "components/CommentsDrawer";
import { getFiscalMonthsArray } from "utils/budget";
// import colors from "assets/theme/base/colors";
import FilteredUploadedFiles from "components/FilteredUploadedFiles";
import FilteredUploadRules from 'components/FilteredUploadRules';
import { useAppController } from "context";
import MDInput from "components/MDInput";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNotificationSubscription } from 'hooks/useNotificationManager';
import { NotificationType } from 'constants';


const BusinessCaseContext = createContext();

const defaultColumns = [
    // { Header: "Year", accessor: "yearName__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Description", accessor: "description", Cell: ({ cell: { value } }) => { return value } },
    { Header: "Expense Type", accessor: "expenseType__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Account", accessor: "account__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Center", accessor: "costCentre__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Vendor", accessor: "vendor__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Pool", accessor: "costPool__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Sub Cost Pool", accessor: "subCostPool__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Tower", accessor: "tower__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Sub Tower", accessor: "subTower__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    // { Header: "Solution Offering", accessor: "solutionOffering__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    // { Header: "Business Unit", accessor: "businessUnit__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Estimate", accessor: "total", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value).format('$0,0')}</MDTypography> } },
];

const defaultCloudColumns = [
    // { Header: "Year", accessor: "yearName__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Description", accessor: "description", Cell: ({ cell: { value } }) => { return value } },
    { Header: "Provider", accessor: "provider__providerCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Account", accessor: "account__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Center", accessor: "costCentre__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Vendor", accessor: "vendor__value", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Service Name", accessor: "serviceName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Service Category", accessor: "serviceCategory", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Pool", accessor: "costPool__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Sub Cost Pool", accessor: "subCostPool__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Tower", accessor: "tower__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Sub Tower", accessor: "subTower__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    // { Header: "Solution Offering", accessor: "solutionOffering__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    // { Header: "Business Unit", accessor: "businessUnit__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Estimate", accessor: "total", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value).format('$0,0')}</MDTypography> } },
];

const defaultExportColumns = [
    { Header: "Year", accessor: "yearName__name" },
    { Header: "Description", accessor: "description" },
    { Header: "Expense Type", accessor: "expenseType__name" },
    { Header: "Account Code", accessor: "account__code" },
    { Header: "Account Name", accessor: "account__name" },
    { Header: "Cost Center Code", accessor: "costCentre__code" },
    { Header: "Cost Center Name", accessor: "costCentre__name" },
    { Header: "Vendor Code", accessor: "vendor__code" },
    { Header: "Vendor Name", accessor: "vendor__name" },
    { Header: "Cost Pool", accessor: "costPool__name" },
    { Header: "Sub Cost Pool", accessor: "subCostPool__name" },
    { Header: "Tower", accessor: "tower__name" },
    { Header: "Sub Tower", accessor: "subTower__name" },
    // { Header: "Solution Offering", accessor: "solutionOffering__name" },
    // { Header: "Business Unit", accessor: "businessUnit__name" },
    { Header: "Total", accessor: "total" },
];


const defaultBusinessCaseCompanionMetricColumn = [
    { Header: "Actions", accessor: "actions", 'align': 'left', "disableSorting": true, maxWidth: 30 },
    { Header: "Name", accessor: "companionMetricLookup__name", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{(value)}</MDTypography> } },
    { Header: "Target", accessor: "companionMetricLookup__target", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{(value)}</MDTypography> } },
    { Header: "Units", accessor: "companionMetricLookup__units", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
];

const defaultExportCloudColumns = [
    { Header: "Year", accessor: "yearName__name" },
    { Header: "Description", accessor: "description" },
    { Header: "Account Code", accessor: "account__code" },
    { Header: "Account Name", accessor: "account__name" },
    { Header: "Cost Center Code", accessor: "costCentre__code" },
    { Header: "Cost Center Name", accessor: "costCentre__name" },
    { Header: "Vendor Code", accessor: "vendor__code" },
    { Header: "Vendor Name", accessor: "vendor__name" },
    { Header: "Provider", accessor: "provider__providerCode", cloud: true },
    { Header: "Service Name", accessor: "serviceName", cloud: true },
    { Header: "Service Category", accessor: "serviceCategory", cloud: true },
    { Header: "Cost Pool", accessor: "costPool__name" },
    { Header: "Sub Cost Pool", accessor: "subCostPool__name" },
    { Header: "Tower", accessor: "tower__name" },
    { Header: "Sub Tower", accessor: "subTower__name" },
    // { Header: "Solution Offering", accessor: "solutionOffering__name" },
    // { Header: "Business Unit", accessor: "businessUnit__name" },
    { Header: "Total", accessor: "total" },
];

const defaultBusinessListColumns = [
    {
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
                <Icon fontSize="medium">{row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
            </span>
        ),
        width: 50,
        disableSorting: true
    },
    { Header: "Year", accessor: "yearName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cloud Estimate", accessor: "cloudTotal", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value).format('$0,0')}</MDTypography> } },
    { Header: "Non-Cloud Estimate", accessor: "nonCloudTotal", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value).format('$0,0')}</MDTypography> } },
    { Header: "Total Estimate", accessor: "total", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value).format('$0,0')}</MDTypography> } },
    { Header: "", accessor: "actions", "align": "left", "disableSorting": true }
];

const buildGroupedRows = (data) => {
    const rows = [];
    data.forEach((r) => {
        let row = {};
        Object.keys(r).forEach((k) => {
            if (["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].includes(k))
                row[k.replace(/\./g, "__")] = Number(r[k])
            else
                row[k.replace(/\./g, "__")] = r[k]
        });
        rows.push(row);
    });
    return rows;
}

const buildBusinessCaseCompanionMetricRows = async ( headerDetails, data, onDeleteMetric) => {
    const rows = [];
    data.forEach((r) => {
        let row = {}
        Object.keys(r).forEach((k) => {
            row[k.replace(/\./g, "__")] = r[k]
        })
        row['actions'] = (
            <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
               { headerDetails?.status === "DRAFT" && (
                <>
                    {/* <MDTypography display="flex" alignItems="center" ml={1.5} onClick={() => onDeleteMetric(row["id"], 'metric')} component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <Icon fontSize="small" color="error">delete</Icon>
                    </MDTypography> */}
                    <Tooltip title="Delete" placement="top">
                        <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onDeleteMetric(row["id"], 'metric')}>
                            <Icon fontSize="small" color="error">delete</Icon>
                        </IconButton>
                    </Tooltip>
                </>
              ) }
            </MDBox>
        )
        rows.push(row)
    })
    return rows;
}

const buildRows = (headerDetails, data, onEdit, onDelete, onShowCell, onComment, typeFilter) => {
    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                if (["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].includes(k))
                    row[k.replace(/\./g, "__")] = Number(r[k])
                    else{
                        if(k === 'description')
                            row[k.replace(/\./g, "__")] = r[k] ? <MDTypography variant="caption" color="dark" sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }} onClick={() => onShowCell(r[k])} title = {r[k]}> {r[k]} </MDTypography> : null
                        else {row[k.replace(/\./g, "__")] = r[k]}
                    }
            });
            const total = (row?.jan || 0) +
                (row?.feb || 0) +
                (row?.mar || 0) +
                (row?.apr || 0) +
                (row?.may || 0) +
                (row?.jun || 0) +
                (row?.jul || 0) +
                (row?.aug || 0) +
                (row?.sep || 0) +
                (row?.oct || 0) +
                (row?.nov || 0) +
                (row?.dec || 0);

            row["total"] = total;
            row["actions"] = (
                <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
                    {
                        headerDetails?.status === "DRAFT" && (
                            <>
                                <Tooltip title="Edit" placement="top">
                                    <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onEdit(row["id"], typeFilter)}>
                                        <Icon fontSize="small">edit</Icon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                    <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onDelete(row["id"])}>
                                        <Icon fontSize="small" color="error"> delete </Icon>
                                    </IconButton>
                                </Tooltip>
                                {/* <MDTypography display="flex" alignItems="center" ml={1.5} component="a" href="#" onClick={() => onEdit(row["id"], typeFilter)} variant="caption" color="text" fontWeight="medium">
                                    <Icon fontSize="small" >edit</Icon>
                                </MDTypography>
                                <MDTypography display="flex" alignItems="center" ml={1.5} component="a" href="#" onClick={() => onDelete(row["id"])} variant="caption" color="text" fontWeight="medium">
                                    <Icon fontSize="small" color="error">delete</Icon>
                                </MDTypography> */}
                            </>
                        )
                    }
                </MDBox>
            );
            rows.push(row);
        });
    }
    return rows;
}

const getTotalAmount = (data) => {
    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                if (["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].includes(k))
                    row[k.replace(/\./g, "__")] = Number(r[k])
            });
            const total = (row?.jan || 0) +
                (row?.feb || 0) +
                (row?.mar || 0) +
                (row?.apr || 0) +
                (row?.may || 0) +
                (row?.jun || 0) +
                (row?.jul || 0) +
                (row?.aug || 0) +
                (row?.sep || 0) +
                (row?.oct || 0) +
                (row?.nov || 0) +
                (row?.dec || 0);

            row["total"] = total;
            rows.push(row);
        });
    }
    return rows;
}

const getExportColumnsList = (financialYearStartMonth) => {
    const columnsListVal = [...defaultExportColumns];
    const fiscalMonthsArray = getFiscalMonthsArray(financialYearStartMonth);
    fiscalMonthsArray.forEach(fiscalMonth => {
        columnsListVal.push({ Header: fiscalMonth, accessor: fiscalMonth.toLowerCase() });
    });
    return columnsListVal;
};

const getExportCloudColumnsList = (financialYearStartMonth) => {
    const columnsListVal = [...defaultExportCloudColumns];
    const fiscalMonthsArray = getFiscalMonthsArray(financialYearStartMonth);
    fiscalMonthsArray.forEach(fiscalMonth => {
        columnsListVal.push({ Header: fiscalMonth, accessor: fiscalMonth.toLowerCase() });
    });
    return columnsListVal;
};

const getColumnsList = (financialYearStartMonth, typeFilter) => {
    const columnsListVal = typeFilter === 'Non Cloud' ? [...defaultColumns] : [...defaultCloudColumns];
    const fiscalMonthsArray = getFiscalMonthsArray(financialYearStartMonth);
    fiscalMonthsArray.forEach(fiscalMonth => {
        columnsListVal.push({ Header: fiscalMonth, accessor: fiscalMonth.toLowerCase(), align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(value).format('$0,0')}</MDTypography> } });
    });
    columnsListVal.unshift({ Header: "Actions", accessor: "actions", "align": "left", "disableSorting": true, maxWidth: 60 });
    return columnsListVal;
};

const BuildDataTable = (props) => {
    const { headerDetails, data, setRefresh, businessCaseId, handleDelete, handleDeleteMultiple, handleComment, type, setType, userInfo } = props
    const [monthlyView, setMonthlyView] = useState(false);
    const [rows, setRows] = useImmer([]);
    // const [totalAmount, setTotalAmount] = useState(0);
    const { showCustomForm } = useYADialog();
    const [typeFilter, setTypeFilter] = useState(type ? type : "Cloud")
    const [cellVal, setCellVal] = useState(null)
    const [modal, setModal] = useState(false)
    // const [totalNonCloudAmount, setTotalNonCloudAmount] = useState(0);
    // const [totalCloudAmount, setTotalCloudAmount] = useState(null);

    const handleClose = () => {
        setRefresh(Math.random())
    }

    // const handleAddButtonClick = () => {
    //     if (typeFilter === 'Cloud') {
    //         showCustomForm(`New Cloud Item`, () => <BusinessCaseCloudItemForm userInfo={userInfo} businessCaseId={businessCaseId} headerDetails={headerDetails} onClose={handleClose} />, null, null, null, 'md', handleClose);
    //     } else {
    //         showCustomForm(`New Non-Cloud Item`, () => <BusinessCaseNonCloudItemForm businessCaseId={businessCaseId} headerDetails={headerDetails} onClose={handleClose} />, null, null, null, 'md', handleClose);
    //     }
    // }

    const handleShowMonthlyView = (checked) => {
        setMonthlyView(checked)
    }

    const handleEdit = (pkId) => {
        if (typeFilter === 'Cloud') {
            showCustomForm(`Edit Cloud Item`, () => <BusinessCaseCloudItemForm userInfo={userInfo} onClose={handleClose} mode="edit" businessCaseId={businessCaseId} headerDetails={headerDetails} pkId={pkId} />, handleClose, "edit", pkId, "md");
        } else {
            showCustomForm(`Edit Non-Cloud Item`, () => <BusinessCaseNonCloudItemForm onClose={handleClose} mode="edit" businessCaseId={businessCaseId} headerDetails={headerDetails} pkId={pkId} />, handleClose, "edit", pkId, "md");
        }
    }

    useEffect(() => {
        setType(typeFilter)
        if (typeFilter === 'Cloud') {
            if (data?.cloudData && Array.isArray(data?.cloudData) && data?.cloudData.length > 0) {
                setRows(buildRows(headerDetails, data?.cloudData, handleEdit, handleDelete, handleShowCellVal, handleDeleteMultiple, handleComment, typeFilter));
            } else {
                setRows([])
            }
        }
        if (typeFilter !== 'Cloud') {
            if (data?.nonCloudData && Array.isArray(data?.nonCloudData) && data?.nonCloudData.length > 0) {
                setRows(buildRows(headerDetails, data?.nonCloudData, handleEdit, handleDelete, handleShowCellVal, handleDeleteMultiple, handleComment, typeFilter));
            } else {
                setRows([])
            }
        }
    }, [headerDetails, data, typeFilter])

    // useEffect(() => {
    //     let total = 0
    //     let cloudTotal = 0
    //     let nonCloudTotal = 0
    //     rows?.map(a => {
    //         total = total + a.total
    //     })
    //     if (data?.cloudData.length > 0) {
    //         getTotalAmount(data?.cloudData)?.map(a => {
    //             cloudTotal = cloudTotal + a.total
    //         })   
    //     }
    //     if (data?.nonCloudData.length > 0) {
    //         getTotalAmount(data?.nonCloudData)?.map(a => {
    //             nonCloudTotal = nonCloudTotal + a.total
    //         })
    //     }
    //     setTotalAmount(total)
    //     setTotalCloudAmount(cloudTotal);
    //     setTotalNonCloudAmount(nonCloudTotal);
    // },[rows, data, typeFilter, totalCloudAmount, totalNonCloudAmount])

    const tabStyles = (_theme, { selected }) => ({
        color: selected ? "#435EC3" : "#adadad",
        textTransform: "none",
        backgroundColor: "#F7F8FD",
        minWidth: "130px",
        /* width: width ? width : "10%", */
        "& .MuiButtonBase-root": {
            fontSize: "18px!important",
            transform: "none",
            backgroundColor: "#435EC3",
        },
        "&::after": selected ? {
            content: '""',
            position: "absolute",
            bottom: 0,
            height: 4,
            width: "90%",
            borderRadius: "0px",
            backgroundColor: "#435EC3"
        } : {}
    });

    const tabRender = () => (
        <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", display: "flex" }}>
            <MDButton data-testid={"cloud"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "Cloud" })} onClick={() => setTypeFilter("Cloud")}>
                <Icon sx={{ marginRight: "6px" }}color="dark" fontSize="50%" >filter_drama</Icon>
                Cloud
                {/* Cloud { typeFilter === 'Cloud' ? '' : `(${numeral(totalCloudAmount).format('$0,0')})`}</MDButton> */}
            </MDButton>
            <MDButton data-testid = {"noncloud"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "Non Cloud", width: '17%' })} onClick={() => setTypeFilter("Non Cloud")} >
                <Icon sx={{ marginRight: "6px" }} color="dark" fontSize="50%">cloud_off</Icon>
                Non-Cloud
                {/* Non-Cloud {typeFilter === "Non Cloud" ? '' : `(${numeral(totalNonCloudAmount).format('$0,0')})`} </MDButton> */}
            </MDButton>
        </MDBox>
    )

    const addButton = () => headerDetails?.status === "DRAFT" ? (
        <MDBox color="text" display="flex" alignItems="center">
            <FormControlLabel
                control={
                    <MDSwitch name={"showMonthlyView"} checked={monthlyView}
                        variant="standard"
                        color="success"
                        onChange={
                            (_, checked) => {
                                handleShowMonthlyView(checked)
                            }
                        }
                    />
                }
                label={"Show Detailed View"}
                labelPlacement="end"
            />
            {/* <MDButton sx={{ ml: 4, mr: 3 }} variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
                New Item
            </MDButton> */}
        </MDBox>
    ) : (
        <MDBox color="text" display="flex" alignItems="center">
            <FormControlLabel
                control={
                    <MDSwitch name={"showMonthlyView"} checked={monthlyView}
                        variant="standard"
                        color="success"
                        onChange={
                            (_, checked) => {
                                handleShowMonthlyView(checked)
                            }
                        }
                    />
                }
                label={"Show Detailed View"}
                labelPlacement="end"
                sx={{ mr: 3 }}
            />

        </MDBox>
    );
    const handleShowCellVal = (data)=> {
        if(Array.isArray(data) && data.length > 1)  
        data = data.slice(1)
        setCellVal(data)
        setModal(true)
    }

    const onCloseModal = () => {
        setModal(false)
    }
    
    const modalStyle = {
        position: 'relative', 
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        textAlign : 'justify',
        display : 'flex',
        flexDirection : 'column',
        maxHeight : 500,
        overflowY: 'auto',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius:'7px'
      };

    const showCellVal = ()=> modal ?  (<Modal open={modal} onClose={onCloseModal}>
        <MDBox sx= {{...modalStyle, }}>
            <IconButton sx= {{ position : 'absolute', top : 5 , right : 5}} onClick={onCloseModal}><Icon>close</Icon></IconButton>
             <MDTypography id="model_description" variant = 'h3'>Description</MDTypography>
            <MDTypography id="modal-modal-description" > {cellVal}</MDTypography>

        </MDBox>  
       </Modal>) : ""

    const columnsList = getColumnsList(headerDetails?.financialYearStartMonth, typeFilter);

    let columns = columnsList.filter(c => {
        if (!monthlyView && typeFilter === 'Cloud' && (["yearName__name", "expenseType__name", "account__value", "costCentre__value", "vendor__value","costPool__name", "subCostPool__name", "solutionOffering__name", "businessUnit__name", "tower__name", "subTower__name", "jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].includes(c.accessor))) {
            return false;
        }
        if (!monthlyView && typeFilter === 'Non Cloud' && (["yearName__name", "expenseType__name", "account__value", "costCentre__value", "subCostPool__name", "solutionOffering__name", "businessUnit__name", "subTower__name", "jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].includes(c.accessor))) {
            return false;
        }
        return (true);
    });

    return (
        <MDBox>
            <Card sx={{ height: "100%", mt: 1 }} px={0}>
                <DataTable
                    table={{ columns, rows }}
                    containerMaxHeight={500}
                    showTotalEntries={true}
                    isSorted={true}
                    isSelectable={headerDetails?.status === "DRAFT" ? true : false}
                    entriesPerPage={true}
                    canSearch={true}
                    primaryActions={addButton()}
                    // primaryRender={headerData()}
                    showCellVal = {showCellVal()}
                    tabRender={tabRender()}
                    deleteMultiple={true}
                    onDeleteMultiple={handleDeleteMultiple}
                />
            </Card>
        </MDBox>
    )

}
const BusinessCaseDetails = () => {

    const { businessCaseId } = useParams();
    const domain = getDomain();
    const handleError = useHandleError();
    const { showCustomDrawer, hideDrawer, showUploadDialog, showCustomForm, showAlert, showPrompt, showSnackbar } = useYADialog();
    const [headerDetails, setHeaderDetails] = useState(null);
    const [step, setStep] = useState("LOADING");
    const [bStep, setBStep] = useState("LOADING");
    const [rows, setRows] = useImmer([]);
    const [groupedRows, setGroupedRows] = useImmer([]);
    const [refresh, setRefresh] = useState(null);
    const [type, setType] = useState(null);
    const [controller,] = useAppController()
    const { userInfo, appDef: { chargeBackMonthly } } = controller
    const [metrics, setMetrics] = useState()
    const [lookUpMetrics, setLookUpMetrics] = useState();
    const [typeFilter, setTypeFilter] = useState("manage")
    const [businessCaseCompanionMetricRow, setBusinessCaseCompanionMetricRow] = useImmer([]);
    const [ metricTabRefresh, setmetricTabRefresh ] = useState(null)

    useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
        if (notification.details?.destinationTable && notification.details?.destinationTable === 'businessCaseDetails') {
          setRefresh(Math.random());
        }
    });

    const tabStyles = (_theme, { selected }) => ({
        color: selected ? "#435EC3" : "#adadad",
        textTransform: "none",
        backgroundColor: "#F7F8FD",
        "& .MuiButtonBase-root": {
            fontSize: "18px!important",
            transform: "none",
            backgroundColor: "#435EC3",
            
        },
        "&::after": selected ? {
            content: '""',
            position: "absolute",
            bottom: 0,
            height: 4,
            width: "60%",
            borderRadius: "0px",
            backgroundColor: "#435EC3"
        } : {}
    });

    const commentsDrawerRef = useRef();

    const handleClose = () => {
        setRefresh(Math.random())
    }

    const handleCloseCommentsDrawer = () => {
        hideDrawer(commentsDrawerRef.current);
        if (headerDetails?.status === "DRAFT")
            handleClose();
    };

    const handleComment = (commentTypePkId) => {
        const mode = headerDetails?.status === "DRAFT" ? "edit" : "";
        commentsDrawerRef.current = showCustomDrawer(() => <CommentsDrawer mode={mode} commentType="businessCase-item" commentTypePkId={commentTypePkId} onClose={handleCloseCommentsDrawer} />, 500, "temporary");
    };

    const handleEdit = (pkId, typeFilter) => {
        if (typeFilter === 'Cloud') {
            showCustomForm(`Edit Cloud Item`, () => <BusinessCaseCloudItemForm userInfo={userInfo} onClose={handleClose} mode="edit" businessCaseId={businessCaseId} headerDetails={headerDetails} pkId={pkId} />, handleClose, "edit", pkId, "md");
        } else {
            showCustomForm(`Edit Non-Cloud Item`, () => <BusinessCaseNonCloudItemForm onClose={handleClose} mode="edit" businessCaseId={businessCaseId} headerDetails={headerDetails} pkId={pkId} />, handleClose, "edit", pkId, "md");
        }
    }

    const handleBCApprove = async () => {
        var [err, data] = await fetchRequest.post(`/api/businessCases/changeStatus/${headerDetails?.id}`, { "status": "APPROVED" });
        if (err) {
            showAlert("Business Case Approve", "Something went wrong. Contact your administrator.");
        } else if (data) {
            showSnackbar(data.message, "success");
            handleClose();
        }
    }

    const handleBCCancel = async () => {
        var [err, data] = await fetchRequest.post(`/api/businessCases/changeStatus/${headerDetails?.id}`, { "status": "CANCELED" });
        if (err) {
            showAlert("Business Case Cancel", "Something went wrong. Contact your administrator.");
        } else if (data) {
            showSnackbar(data.message, "success");
            handleClose();
        }
    }

    const deleteBusinessCaseItem = async (pkId) => {
        const response = await Axios.delete(`${domain}/api/businessCases/businessCaseItem/${pkId}`);
        if (response.data && response.data.result === true) {
            showSnackbar(response.data?.message, "success");
            // handleClose();
        }
        else {
            showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
    }

    const deleteMetricItem = async (pkId) => {
        // setRefresh(Math.random())
        const response = await Axios.delete(`${domain}/api/businessCases/companionMetric/${pkId}`);
        if (response.data && response.data.result === true) {
            showSnackbar(response.data?.message, "success");
            setmetricTabRefresh(Math.random())
            // handleClose();

        }
        else {
            showAlert("Delete", "Something went wrong. Contact your administrator.");
        }

    }

    const handleDeleteSuccess = (pkId, type) => {
        if (type && type === 'metric') {
            deleteMetricItem(pkId);
        } else {
            deleteBusinessCaseItem(pkId);
        }
    }

    const handleDelete = (pkId, type) => {
        if (type && type === 'metric') {
            showPrompt("Delete", "Are you sure you want to delete?", () => { 
                    handleDeleteSuccess(pkId, type);
            });
        } else {
            showPrompt("Delete", "Are you sure you want to delete?", () => {
                    handleDeleteSuccess(pkId);
                    setRefresh(Math.random())
            });
        }
    }

    const handleDeleteMultiple = (selectedRows) => {
        showPrompt("Delete", "Are you sure you want to delete?", () =>
            deleteBusinessCaseItemMultipleRecords(JSON.stringify(selectedRows))
        );
    };

    const deleteBusinessCaseItemMultipleRecords = async (selectedRows) => {
        const [err, data] = await fetchRequest.post(
            `/api/businessCases/businessCaseItems/delete`,
            selectedRows
        );

        if (err) {
            showAlert("Delete", "Something went wrong. Contact your administrator.");
        } else if (data) {
            showSnackbar(data, "success");
            handleClose();
        }

    };

    useEffect(() => {
        async function getDetails() {
            var [err, data] = await fetchRequest.get(`/api/businessCases/${businessCaseId}`);
            if (err) {
                handleError(err);
            }
            else {
                if (data) {
                    setHeaderDetails(data);
                }
            }
        }
        getDetails();
    }, [refresh])

    useEffect(() => {
        async function getList() {
            setStep("LOADING");
            var [err, data] = await fetchRequest.get(`/api/businessCases/${businessCaseId}/detailsList`);
            if (err) {
                handleError(err);
            }
            else {
                if (data && Array.isArray(data) && data.length > 0) {
                    const groupedResponse = _.chain(data).groupBy('yearName.name').map( (value, key) => ({yearName: key, cloudData: value.filter( val => {if (val.cloud === 1 || val.cloud === true) return val}), nonCloudData: value.filter( val => {if (val.cloud === 0 || val.cloud === false) return val})})).orderBy('yearName', 'desc').value();
                    groupedResponse.map( data => {
                        let total = 0
                        let cloudTotal = 0
                        let nonCloudTotal = 0
                        rows?.map(a => {
                            total = total + a.total
                        })
                        if (data?.cloudData.length > 0) {
                            getTotalAmount(data?.cloudData)?.map(a => {
                                cloudTotal = cloudTotal + a.total
                            })   
                        }
                        if (data?.nonCloudData.length > 0) {
                            getTotalAmount(data?.nonCloudData)?.map(a => {
                                nonCloudTotal = nonCloudTotal + a.total
                            })
                        }
                        data['total'] = cloudTotal + nonCloudTotal;
                        data['cloudTotal'] = cloudTotal
                        data['nonCloudTotal'] = nonCloudTotal
                    })
                    setGroupedRows(buildGroupedRows(groupedResponse))
                    setRows(buildRows(headerDetails, data, handleEdit, handleDelete, handleDeleteMultiple, handleComment));
                    setStep("LOADED");
                    // setTypeFilter("manage");
                }
                else {
                    setStep("EMPTY");
                    // setTypeFilter("manage");
                }
            }
        }
        // if (headerDetails && (headerDetails?.status === "DRAFT" || headerDetails?.status === "CANCELED" || headerDetails?.status === "APPROVED"))
        getList();
        // else
        //     setStep("EMPTY");
    }, [headerDetails, refresh])

    useEffect(() => {
        const getMetricsList = async () => {

            var [err, metricData] = await fetchRequest.get(`/api/businessCases/lookupMetric/${businessCaseId}`)
            if (err) {
                handleError(err);
            }
            else {
                setLookUpMetrics(metricData)
            }
        }
        getMetricsList();
        const getBusinessCaseCompanionMetricList = async () => {

            var [err, bccompanionMetricData] = await fetchRequest.get(`/api/businessCases/businessCaseCompanionMetric/${businessCaseId}`)
            if (err) {
                handleError(err);
            }
            else {
                let resultData = await buildBusinessCaseCompanionMetricRows(headerDetails, bccompanionMetricData, handleDelete)
                setBusinessCaseCompanionMetricRow(resultData);
            }
        }
        getBusinessCaseCompanionMetricList()

    }, [headerDetails, metricTabRefresh])



    useEffect(() => {
        async function getBusinessCaseCostCentreList() {
            setBStep("LOADING");
            var [err, data] = await fetchRequest.get(`/api/businessCases/${businessCaseId}`);
            if (err) {
                handleError(err);
            }
            else {
                if (data && Array.isArray(data) && data.length > 0) {
                    // setBusinessCaseCostCentreListRows(buildCcBusinessCaseRows(data, handleReject));
                    setBStep("LOADED");
                }
                else {
                    setBStep("EMPTY");
                }
            }
        }
        // if (headerDetails && (headerDetails?.status !== "DRAFT" || headerDetails?.status !== "CANCELED" || headerDetails?.status !== "COMPLETED"))
            getBusinessCaseCostCentreList();
        // else
        //     setStep("EMPTY");
    }, [headerDetails, refresh])

    const handleAddButtonClick = () => {
        showCustomForm(`New Non-Cloud Item`, () => <BusinessCaseNonCloudItemForm businessCaseId={businessCaseId} headerDetails={headerDetails} onClose={handleClose} />, null, null, null,'md', handleClose);
    }

    const handleAddCloudButtonClick = () => {
        showCustomForm(`New Cloud Item`, () => <BusinessCaseCloudItemForm userInfo={userInfo} businessCaseId={businessCaseId} headerDetails={headerDetails} onClose={handleClose} />, null, null, null,'md', handleClose);
    }
    const handleCloudCsvExport = async () => {
        const exportCloudColumns = getExportCloudColumnsList(headerDetails?.financialYearStartMonth);

        var data = [];
        rows?.forEach(element => {
            let obj = {}
            if (Boolean(element["cloud"]) === true) {
                exportCloudColumns?.forEach((e) => {
                    if(element[e.accessor]){
                        obj[e.Header] = sanitizeCell(element[e.accessor])
                    }
                    if (typeof element[e.accessor] == "number") {
                        obj[e.Header] = Math.round(element[e.accessor])
                    }
                    if (e.accessor === 'description' && typeof element[e.accessor] !== "string"){
                        obj[e.Header] = element[e.accessor] && element[e.accessor].title ? element[e.accessor].title : element[e.accessor]['props']['children'][1]
                    }
                    else {
                        obj[e.Header] = element[e.accessor]
                    }
                })   
            }
            data.push(obj)
        });

        const cloudData = data.filter( data => {if (!data["Expense Type"]) return data})
        const wb2 = XLSX.utils.book_new()
        const ws2 = XLSX.utils.json_to_sheet(cloudData)
        XLSX.utils.book_append_sheet(wb2, ws2,'Cloud')
        XLSX.writeFile(wb2, `Business_Case_Cloud_(${headerDetails?.name})_${moment(Date()).format("YYYYMMDDHHmmss")}.csv`)
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Cloud data for ${headerDetails?.name} Business Case` })
    }

    const handleNonCloudCsvExport = async () => {
        const exportColumns = getExportColumnsList(headerDetails?.financialYearStartMonth);
        var data = [];
        rows?.forEach(element => {
            let obj = {}
            if (Boolean(element["cloud"]) === false) {
                exportColumns?.forEach((e) => {
                    if (typeof element[e.accessor] == "number") {
                        obj[e.Header] = Math.round(element[e.accessor])
                    }
                    if(element[e.accessor]){
                        element[e.accessor] = sanitizeCell(element[e.accessor])
                    }
                    if (e.accessor === 'description' && typeof element[e.accessor] !== "string"){
                        obj[e.Header] = element[e.accessor]?.['props']?.['children']?.[1]
                    }
                    else {
                        obj[e.Header] = element[e.accessor]
                    }
                })
            }
            data.push(obj)
        });

        const nonCloudData = data.filter(data => { if (data["Expense Type"]) return data })
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(nonCloudData);
        XLSX.utils.book_append_sheet(wb, ws,'Non Cloud');
        XLSX.writeFile(wb, `Business_Case_Non-Cloud_(${headerDetails?.name})_${moment(Date()).format("YYYYMMDDHHmmss")}.csv`)
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Non-Cloud data for ${headerDetails?.name} Business Case` })
    }

    if (step === "LOADING" || bStep === "LOADING") {
        return <YASkeleton variant="dashboard-loading" />;
    }

    // const viewUploadRule = () => {
    //     setUploadRuleView(true)
    //     setUploadedFileView(false)
    // }

    // const renderUploadButton = () => (
    //     <MDBox>
    //         {
    //             (headerDetails?.["status"] === "DRAFT" && step !== "EMPTY") && (
    //                 <MDBox>
    //                     <MDButton sx={{ mr: 2 }} variant="gradient" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={handleImportButtonClick}> UPLOAD</MDButton>
    //                     {/* <MDButton variant="gradient" color="info" onClick={handleLoadBusinessCaseButtonClick}>Send to CT</MDButton> */}
    //                 </MDBox>
    //             )
    //         }
    //     </MDBox>
    // )

    const getSecondaryActions = () => {
        let actions = [];
        if (step !== "EMPTY") {
            if (headerDetails?.["status"] === 'DRAFT' && _.includes(userInfo?.role, "admin")) {
                actions.push({ label: "Approve", onClick: handleBCApprove })
                actions.push({ label: "Cancel", onClick: handleBCCancel })
            }
        }
        return actions;
    }

    const getMenuActions = () => {
        let actions = [];
        if (step !== "EMPTY") {
            actions.push({ label: "Download Cloud", onClick: handleCloudCsvExport })
            actions.push({ label: "Download Non-Cloud", onClick: handleNonCloudCsvExport })
        }
        return actions;
    }

    const handleUploadDialogClose = (uploadSuccess) => {
        if (uploadSuccess)
            handleClose();
    };

    const handleImportButtonClick = () => {
        const uploadConfig = {
            uploadType: null,
            uploadCategory: "Business Case",
            pkId: businessCaseId
        };

        showUploadDialog("Import Business Case File", uploadConfig, handleUploadDialogClose);
        setTypeFilter("manage")
    }

    const renderOptions = () => (
        <MDBox>
            <MDButton sx={{ m: 2 }} variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
                New Non-Cloud Item
            </MDButton>
            <MDButton sx={{ m: 2 }} variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={() => { handleAddCloudButtonClick(); setType('Cloud') }}>
                New Cloud Item
            </MDButton>
            <MDButton sx={{ mt: 2, ml: 20 }} variant="gradient" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={handleImportButtonClick}>
                UPLOAD
            </MDButton>
        </MDBox>
    )

    const renderAddButton = () => (
        <MDBox>
            <MDButton variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
                New Non-Cloud Item
            </MDButton>
            <MDButton sx={{ml: 2}} variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={() => { handleAddCloudButtonClick(); setType('Cloud') }}>
                New Cloud Item
            </MDButton>
            <MDButton sx={{ ml: 2 }} variant="gradient" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={handleImportButtonClick}>
                UPLOAD
            </MDButton>
        </MDBox>
    )

    const renderHeader = () => (headerDetails && <BusinessCaseHeader headerDetails={headerDetails} />)
    const route = useLocation().pathname.split("/").slice(1);
    let drillDown = { keyMatch: "businessCases", routeMatch: `/${route[0]}` }
    let previoustitle = "Business Cases"

    const renderRowSubComponent = (row) => {
        return <BuildDataTable userInfo={userInfo} type={type} setType={setType} headerDetails={headerDetails} step={step} data={row.row.original} setRefresh={setRefresh} businessCaseId={businessCaseId} deleteBusinessCaseItem={deleteBusinessCaseItem} handleEdit={handleEdit} handleDelete={handleDelete} handleDeleteMultiple={handleDeleteMultiple} handleComment={handleComment} />
    }
    const handleAddMetricButtonClick = async () => {
        if (metrics.length > 0 ) {
            const filterLookup = lookUpMetrics.filter((item) => !metrics?.some((itm)=>  itm.companionMetricLookupId == item.companionMetricLookupId))
            setLookUpMetrics(filterLookup)
            var [err, ] = await fetchRequest.post(`/api/businessCases/businessCaseCompanionMetric/${headerDetails?.id}`, metrics);
            if (err) {
                handleError(err);
            }
            try {
                // let resultData = await buildBusinessCaseCompanionMetricRows(headerDetails, data, handleDelete)
                // setBusinessCaseCompanionMetricRow(resultData);
                setmetricTabRefresh(Math.random())
            } catch (error) {
                console.error('Error while fetching data', error);
            }
        }
        setMetrics([])
    }
    const renderTags = (value, getTagProps) => {
        const limt = value.slice(0,1)
        return (
            <>
             <MDBox  sx={{
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
  }}>
            {limt.map((opt, idx)=> (
                <Chip
                color = "secondary"
                variant = "filled"
                key= {idx}
                label = {opt.name}
                {...getTagProps({ idx })}/>
            ))}
            {value.length > 1 && <Chip sx = {{alignItems : 'right'}}label={`+${value.length - 1} more`} />}
            </MDBox>
            </>
        )
    }
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
        <BusinessCaseContext.Provider>
            <MDBox minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0, md: 6, sm: 6, xs: 6 }}>
                <PageHeader title="Business Case Details" subtitle="" keyMatch={drillDown.keyMatch} routeMatch={drillDown.routeMatch} previoustitle={previoustitle} />
                <PageHeader headerComponent={renderHeader} secondaryActions={getSecondaryActions} hideBreadcrumbs={true} noTopPadding={true} />
                <MDBox display="flex" width="100%" sx={{ backgroundColor: "#F7F8FD", borderBottom: "1px solid #edeef3", borderTop: "1px solid #e3e3e3", display: "inline-flex" }} justifyContent="space-between">
                    <MDBox display="flex">
                        <MDButton data-testid={"details"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "manage" })} onClick={() => setTypeFilter("manage")}>
                            <Icon color="dark" sx={{ marginRight: "6px" }}fontSize="50%">business_center</Icon>
                            ESTIMATES
                        </MDButton>
                        <MDButton data-testid={"metrics"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "metric" })} onClick={() => setTypeFilter("metric")}>
                            <Icon color="dark"  sx={{ marginRight: "6px" }}fontSize="50%">bookmarks</Icon>
                            METRICS
                        </MDButton>
                        <MDButton data-testid = {"upload"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "upload" })} onClick={() => setTypeFilter("upload")} >
                            <Icon color="dark"  sx={{ marginRight: "6px" }} fontSize="50%">file_present</Icon>
                            UPLOADED FILES
                        </MDButton>
                        {/* <MDButton data-testid = {"uploadRule"} sx={(theme) => tabStyles(theme, { selected: typeFilter === "uploadRule" })} onClick={() => setTypeFilter("uploadRule")} >
                            <Icon color="dark" fontSize="50%" sx={{ transform: "rotate(360deg)" }}>file_present</Icon>
                            UPLOAD RULES
                        </MDButton> */}
                    </MDBox>
                </MDBox>
                <MDBox p={3} pt={1}>
                    { 
                        // (headerDetails?.status === "DRAFT" || headerDetails?.status === "CANCELED" || headerDetails?.status === "COMPLETED") && (
                        <>
                            {
                                typeFilter === 'metric' && 
                                <MDBox p={3} pt={1} >
                                <MDBox p={-1} pt={1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Autocomplete
                                    id= "Business-case-metrics"
                                    multiple
                                    className="customAutocomplete"
                                    options={lookUpMetrics || []}
                                    disableClearable= {false}
                                    disableCloseOnSelect
                                    noOptionsText= "No metrics available"
                                    value = {metrics || []}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.name === value.name} 
                                    renderOption= {(props, options, {selected})=> (
                                        <li {...props}>
                                            <Checkbox icon= {icon} checkedIcon = {checkedIcon} style = {{marginRight : '8px'}} checked = {selected}/>
                                            {options.name}
                                        </li>
                                    )}
                                    sx={{
                                        ml: 1.5,
                                        // justifyContent: 'space-between',
                                        "& .MuiOutlinedInput-root": {
                                        height: 48,
                                        width: 330,
                                        boxShadow: "0 8px 16px #1a488e1f",
                                        },
                                        "& .MuiOutlinedInput-input": {
                                        fontSize: 14,
                                        //   display: "inline-block !important",
                                        //   display: "flex",
                                        //   justifyContent: "space-between"
                                        
                                        },

                                        "& .MuiAutocomplete-tag": {
                                            display: "inline-block !important",
                                            backgroundColor: "rgba(0, 0, 0, 0.08)",
                                            color : "rgba(0, 0, 0, 0.87)",
                                            maxWidth: "150px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"

                                        },
                                        '& .MuiAutocomplete-tag .MuiSvgIcon-root, & .MuiAutocomplete-tag .MuiSvgIcon-root:hover, & .MuiAutocomplete-tag .MuiSvgIcon-root:focus': {
                                            color: '#003',
                                            visibility: 'hidden'
                                        }
                                        }}
                                    renderInput={(params) => <MDInput sx={{ display: 'flex', justifyContent: 'space-between' }} label="Metrics" {...params} />}
                                    onChange = {(event, newValue)=> {
                                        setMetrics(newValue);
                                        }}
                                    renderTags={renderTags}
                                />
                                    <MDButton sx={{ m: 2 }} variant="gradient" color="info" startIcon={<Icon>add</Icon>} disabled = {(!lookUpMetrics?.length  || !metrics?.length  ||  headerDetails?.status !== "DRAFT" ) ? true : false } onClick={handleAddMetricButtonClick}>
                                        Add
                                    </MDButton>
                                    </MDBox>
                                    {businessCaseCompanionMetricRow?.length > 0 && <MDBox>
                                        <Card sx={{ height: "100%", mt: 1 }} px={5}>
                                            <DataTable
                                                table={{ columns: defaultBusinessCaseCompanionMetricColumn, rows: businessCaseCompanionMetricRow }}
                                                title={'Metrics'}
                                                isSorted={true}
                                                entriesPerPage={true}
                                                canSearch={true}
                                            // onDelete= {}
                                            />
                                        </Card>
                                    </MDBox>}
                                </MDBox>
                            }
                            {
                                typeFilter === 'manage' && step === "LOADED" &&
                                <MDBox>
                                    <Card sx={{ height: "100%", mt: 1 }} px={0}>
                                        <DataTable
                                            table={{ columns: defaultBusinessListColumns, rows: groupedRows }}
                                            title={'Estimates'}
                                            showTotalEntries={true}
                                            isSorted={true}
                                            entriesPerPage={true}
                                            canSearch={true}
                                            primaryActions={renderAddButton()}
                                            renderRowSubComponent={renderRowSubComponent}
                                            menuActionRender={getMenuActions()}
                                        />
                                    </Card>
                                </MDBox>
                            }
                            {
                                typeFilter === 'manage' && step === "EMPTY" &&(
                                    <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
                                        <EmptyState
                                            size="large"
                                            image={new_item_img}
                                            title={headerDetails?.status === "CANCELED" ? "Business Case Canceled" : "Start Creating Your Business Case Now"}
                                            description={headerDetails?.status === "CANCELED" ? "" : "Click on the 'import file' button to import all the Business Case data at once or click on the '+ new Business Case item' button to add individual line items."}
                                            actions={headerDetails?.status === "CANCELED" ? undefined : renderOptions}
                                            maxWidth={450}
                                        />
                                    </MDBox>
                                )
                            }
                            {
                                 typeFilter === 'upload' && (
                                    <Card sx={{ height: '100%' }} px={0}>
                                        <FilteredUploadedFiles containerHeight="calc(100vh - 370px)" canFilter={true} chargeBackMonthly={chargeBackMonthly} condition={{type: 'businessCaseId', condition: {businessCaseHeaderId: businessCaseId}}} setRefresh={setRefresh} />
                                    </Card>
                                )
                            }
                            {
                                typeFilter === 'uploadRule' && (
                                    <Card sx={{ height: '100%' }} px={0}>
                                        <FilteredUploadRules containerHeight="calc(100vh - 500px)" tableName={['businessCaseCloud', 'businessCaseNonCloud']} />
                                    </Card>
                                )
                            }
                        </>
                        // )
                    }
                </MDBox>
            </MDBox>
        </BusinessCaseContext.Provider>
    );
};

export default AnimatedRoute(BusinessCaseDetails);