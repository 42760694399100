import { Suspense, lazy, useEffect, useRef } from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import { useAppController, setUserAuthorized, setError, setNoRole } from './context';
import { getDomain } from 'utils';
import HomePage from 'pages/Home';
// import CloudForecast from 'pages/CloudForecastOverview';
import DashboardPage from 'pages/DashboardPage';
import ForecastPage from 'pages/ForecastPage';
import CustomDashboardPage from 'pages/CustomDashboardPage';
import ReportPage from 'pages/ReportPage';
import MasterPage from 'pages/Masters';
import CubeRefreshKeyPage from 'pages/CubeRefreshKey';
// import DataflowPage from 'pages/Dataflow';
import AutomatePage from 'pages/Automate';
import DataDictionaryPage from 'pages/DataDictionary'
import LoginPage from 'pages/Login';
import LogoutPage from 'pages/Logout';
import UserCostCentres from 'pages/UserCostCentres';
import PageLayout from 'layouts/PageLayout';
import Budget from 'pages/Budget';
import BusinessCase from 'pages/BusinessCase';
import NotFound from 'pages/NotFound';
import AccessDenied from 'pages/AccessDenied';
import Error from 'pages/Error';
import BudgetDetails from 'pages/BudgetDetails';
import Analytics from 'pages/Analytics1';
import CostCentreBudgetDetails from 'pages/CostCentreBudgetDetails';
import BudgetList from 'pages/BudgetList';
import Dataload from 'pages/Dataload';
import Alerting from 'pages/Alerting';
import AlertDetails from 'pages/Alerting/AlertDetails';
// import NotificationChannels from 'pages/NotificationChannels';
import AppSettings from 'pages/AppSettings';
import Designer from 'pages/Designer';
import Dashboards from 'pages/Dashboards';
import ConsumptionDataloadPage from 'pages/ConsumptionDataloadPage'
// import CompanionMetricPage from 'pages/CompanionMetricPage';
import AuditLogs from 'pages/AuditLogs'
import ProcessLogs from 'pages/ProcessLogs'
import fetchRequest from 'utils/fetchRequest';
import DataWorkspacePage from 'pages/DataWorkspaces';
import BusinessCaseDetails from 'pages/BusinessCaseDetails';
const CostExplorerPage = lazy(() => import('pages/CostExplorer'));
import UpgradeLicense from 'pages/UpgradeLicense';
import { LogoutBox } from 'pages/Logout';
import Overallocation from 'pages/Overallocation';
import Metrics from 'pages/Metrics';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/css/app.css';
import { Userpilot } from 'userpilot'
import Interfaces from 'pages/Interfaces';
import Mappings from 'pages/Mappings';
import ChargeBack from 'pages/ChargeBack';
import CompanionMetric from 'pages/CompanionMetric';
import InputProjectedForecast from 'pages/InputProjectedForecast';
import { useSocketContext } from 'context/SocketContext';
import Recommendation from 'pages/Recommendation';
// import UnallocatedSpend from 'pages/UnallocatedSpend';
import Users from 'pages/Users'
import Tenants from 'pages/Tenants'
import Teams from 'pages/Teams';
import Benchmark from 'pages/Benchmark';

const hideLoadingScreen = () => {
  setTimeout(() => {
    document.getElementById("loadingScreen")?.remove();
  }, 500)
}

const AuthError = ({ type, status, message, desc }) => {
  if (type === "server") {
    if (status === 401 || status === 403) {
      return <AccessDenied message={message} dec={desc} />
    }
    else if (status === 404) {
      return <NotFound />
    }
    else {
      return <Error />
    }
  }
  else {
    return <Error errorType="client" />
  }
}


const App = () => {
  const domain = getDomain();
  const location = useLocation();
  const navigate = useNavigate();
  const [controller, dispatch] = useAppController();
  const { appLoading, authorizedUser, loggedOut, error, userInfo } = controller;
  const { pathname } = useLocation();
  const { initializeSocket } = useSocketContext();
  const userAnalticsEnabled = useRef(false);

  useEffect(() => {
    async function checkAuth() {
      const [authErr, authRes] = await fetchRequest.get(`/auth/loggedIn`);
      if (authErr) {
        setError(dispatch, {
          type: authErr.type,
          status: authErr.status,
        });
        hideLoadingScreen();
      }
      else {
        if (authRes.data.redirect === true) {
          window.location.href = `${domain}/auth/login`;
        }
        else if (authRes.data.redirect === false) {
          navigate("/login")
          setUserAuthorized(dispatch);
          hideLoadingScreen();
        }
        else if (authRes.data.role?.[0] === "norole") {
          await fetchRequest.post(`/auth/logout`);
          setNoRole(dispatch);
          setError(dispatch, {
            type: "server",
            status: 403,
            message: "No roles assigned.",
          });
          hideLoadingScreen();
        }
        else {
          const [appErr, appRes] = await fetchRequest.get(`/api/app`);
          if (appErr) {
            setError(dispatch, {
              type: appErr.type,
              status: appErr.status,
            });
          }
          else {

            initializeSocket();

            let packages = {}
            appRes?.licenseDetails?.map(pkg => {
              packages[pkg?.name?.toLowerCase().replace("_", "-")] = pkg?.enabled
            })
            if (typeof (Storage) !== "undefined")
              appRes.defaults?.map((item) => {
                if (!sessionStorage[item.name])
                  return sessionStorage[item.name] = item.value
              })
            setUserAuthorized(dispatch, authRes.data, appRes);
            // console.log(authRes.data)
            if (authRes.data?.auth !== "AD") {
              userAnalticsEnabled.current = true;
              Userpilot.initialize('NX-5fd7234b')
              Userpilot.identify(
                authRes.data.sub,
                {
                  name: authRes?.data?.displayName,
                  admin: authRes?.data?.role?.includes("admin"),
                  yarken: authRes?.data?.userName?.indexOf("@yarken.com") > 0 ? true : false,
                  partner: false,
                  // email: authRes.data.email, 
                  // created_at: authRes.data..created_at, // ISO8601 Date,
                  company:  // optional 
                  {
                    id: authRes.data.tenantName, // Company Unique ID
                    ...packages
                    // created_at: company.created_at // ISO8601 Date
                  }
                  // Additional user properties
                  // plan: user.plan,
                  // trialEnds: user.trial_ends ISO8601 Date
                }
              )
            }
          }
          hideLoadingScreen();
        }
      }
    }
    checkAuth();
  }, [dispatch]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (userAnalticsEnabled.current)
      Userpilot.reload()
  }, [pathname, userAnalticsEnabled]);

  if (error) {
    return <AuthError {...error} />
  }

  if (loggedOut) {
    return <LogoutBox />
  }

  const otheruser = !userInfo?.role?.includes("admin") &&
    !userInfo?.role?.includes("powerUser") &&
    !userInfo?.role?.includes("viewer") &&
    !userInfo?.role?.includes("budgetProcessOwner") &&
    !userInfo?.role?.includes("budgetContributor") &&
    !userInfo?.role?.includes("chargebackApprover") &&
    !userInfo?.role?.includes("chargebackViewer");

  if (!appLoading && authorizedUser === true)
    return (
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route element={<PageLayout />}>
            {(otheruser && userInfo?.role.includes("cloudCostOwner")) && <Route exact path={'/'} element={<Navigate to="/forecast/forecasting-overview-admin" />} key={'cloud-forecast-owner'} />}
            {(otheruser && userInfo?.role.includes("cloudEngineer")) && <Route exact path={'/'} element={<Navigate to="/forecast/forecasting-overview-member" />} key={'cloud-forecast-engineer'} />}
            {(otheruser && (!userInfo?.role.includes("cloudCostOwner") && !userInfo?.role.includes("cloudEngineer"))) && <Route exact path={'/'} element={<HomePage />} key={'home'} />}
            {!otheruser && <Route exact path={'/'} element={<HomePage />} key={'home'} />}
            {/* <Route exact path={'/'} element={ <HomePage />} key={'home'} /> */}
            {/* <Route exact path={'/cloud-forecast'} element={<CloudForecast />} key={'cloud-forecast'} /> */}
            <Route exact path={'/dashboard/:dashboardId/report/:reportId'} element={<ReportPage />} key={'report'} />
            <Route exact path={'/dashboard/:dashboardId'} element={<DashboardPage />} key={'dashboard'} />
            <Route exact path={'/forecast/:dashboardId/team-forecast'} element={<InputProjectedForecast />} key={'forecast'} />
            <Route exact path={'/forecast/:dashboardId'} element={<ForecastPage />} key={'forecast'} />
            <Route exact path={'/dashboard/custom/:dashboardId'} element={<CustomDashboardPage />} key={'custom-dashboard'} />
            <Route exact path={'/dashboards'} element={<Dashboards />} key={'dashboards'} />
            <Route exact path={'/report/:reportId'} element={<ReportPage />} key={'report'} />
            <Route exact path={'/master/:masterId'} element={<MasterPage />} key={'master'} />
            <Route exact path={'/recommendations'} element={<Recommendation />} key={'cloud-recommendations'} />
            <Route exact path={'/admin/automate'} element={<AutomatePage />} key={'templates'} />
            <Route exact path={'/admin/data-dictionary'} element={<DataDictionaryPage />} key={'data-dictionary'} />
            <Route exact path={'/admin/cube-refresh'} element={<CubeRefreshKeyPage />} key={'cube-refresh'} />
            {/* <Route exact path={'/admin/data-management'} element={<DataflowPage />} key={'data-management'} /> */}
            <Route exact path={'/admin/data-upload'} element={<Dataload />} key={'dataload'} />
            <Route exact path={'/admin/companion-metric'} element={<CompanionMetric />} key={'companion-metrics-lookup'} />
            <Route exact path={'/admin/consumption-data-upload'} element={<ConsumptionDataloadPage />} key={'consumption-dataload-page'} />
            {/* <Route exact path={'/admin/companion-metrics-upload'} element={<CompanionMetricPage />} key={'companion-metrics-dataload-page'} /> */}
            <Route exact path={'/budget'} element={<Budget />} key={'budget'} />
            <Route exact path={'/budget/:budgetId/budget-details'} element={<BudgetDetails />} key={'budget-details'} />
            <Route exact path={'/business-cases'} element={<BusinessCase />} key={'business-cases'} />
            <Route exact path={'/business-cases/:businessCaseId/business-case-details'} element={<BusinessCaseDetails />} key={'business-case-details'} />
            <Route exact path={'/cost-center-budget'} element={<BudgetList />} key={'budget-list'} />
            <Route exact path={'/cost-center-budget/:budgetId/budget-details'} element={<CostCentreBudgetDetails />} key={'cost-center-budget-details'} />
            <Route exact path={'/user-cost-centers'} element={<UserCostCentres />} key={'UserCostCenters'} />
            <Route exact path={'/not-found'} element={<NotFound />} key={'not-found'} />
            <Route exact path={'/access-denied'} element={<AccessDenied />} key={'access-denied'} />
            <Route exact path={'/error'} element={<Error />} key={'error'} />
            <Route exact path={'/admin/metrics'} element={<Metrics />} key={'metrics'} />
            <Route exact path={'/admin/alerting'} element={<Alerting />} key={'alerting'} />
            <Route exact path={'/alerting/:alertId/alert-details'} element={<AlertDetails />} key={'alert-details'} />
            <Route exact path={'/analytics/:cubeId'} element={<Analytics />} key={'analytics'} />
            <Route exact path={'/admin/settings/:settingId?'} element={<AppSettings />} key={'settings'} />
            {/* <Route exact path={'/admin/notification-channels'} element={<NotificationChannels />} key={'notification-channels'} /> */}
            <Route exact path={'/designer'} element={<Designer />} key={'designer'} />
            <Route exact path={'/cost-explorer'} element={<Suspense fallback={<div>Loading...</div>}><CostExplorerPage /></Suspense>} key={'cost-explorer'} />
            <Route exact path={'/data-workspaces'} element={<DataWorkspacePage />} key={'data-workspace'} />
            <Route exact path={'/admin/overallocation'} element={<Overallocation />} key={'overallocation'} />
            <Route exact path={'/admin/audit-logs'} element={<AuditLogs />} key={'audit-logs'} />
            <Route exact path={'/admin/background-processes'} element={<ProcessLogs />} key={'background-processes'} />
            <Route exact path={'/upgrade-license'} element={<UpgradeLicense />} key={'upgrade-license'} />
            <Route exact path={'/admin/integrations'} element={<Interfaces />} key={'integrations'} />
            <Route exact path={'/admin/data-mapping-templates'} element={<Mappings type={'data-store-mapping'} />} key={'data-mapping-templates'} />
            <Route exact path={'/admin/service-mapping'} element={<Mappings type={'service-mapping'} />} key={'service-mapping'} />
            <Route exact path={'/chargeback'} element={<ChargeBack />} key={'chargeback'} />
            <Route exact path={ '/users'} element= {<Users /> } key={'users'} /> 
            <Route exact path={ '/tenants'} element= {<Tenants /> } key={'tenants'} />
            <Route exact path={ '/teams'} element= {<Teams /> } key={'teams'} /> 
            <Route exact path={'/team-forecast'} element={<InputProjectedForecast />} key={'forecast'} />
            <Route exact path={'/benchmark'} element={<Benchmark />} key={'benchmark'} />
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Route>
          <Route exact path={'/login'} element={<LoginPage />} key={'login'} />
          <Route exact path={'/logout'} element={<LogoutPage />} key={'logout'} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
    );

  return null;
}

export default App;
