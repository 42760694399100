import { CircularProgress, Icon, IconButton, Tooltip } from "@mui/material";
import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import numeral from "numeral";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import { useState } from "react";
import TowerRuleModal from "../TowerRuleModal";
import LoadRuleModal from "../LoadRuleModal";
import { backgroundProcessCheck } from 'utils'
import { useAppController } from "context"
import { useYADialog } from "components/YADialog";
import { useNotificationSubscription } from "hooks/useNotificationManager";
import { NotificationType } from "constants";



const MultiTowerRule = (props) => {
    const [showAddRuleModal, setShowAddRuleModal] = useState(false)
    const [showLoadRuleModal, setShowLoadRuleModal] = useState(false)
    const [consumptionLoad, setConsumptionLoad] = useState(false)
    const [errors, setErrors] = useState({});
    const [rows, setRows] = useState([]);
    const [ruleName, setRuleName] = useState(null)
    const [currentItem, setCurrentItem] = useState({})
    const [rowId, setRowId] = useState(null)
    const { showAlert } = useYADialog();
    const [controller] = useAppController();
    const [allocationFileId, setAllocationFileId] = useState(null)
    const [assetRelationships, setAssetRelationships] = useState(null)
    const { enableAssetDistribution, yearFilter, monthFilter, costElementsRemaining, selectedRows, isSubmitting, submitRules, subTowers, mappingType, edit, editValue, columnFilterCondition } = props;

    const portion = Number(_.sumBy(rows, (o) => { return parseFloat(o.portion) }))

    const validateData = () => {
        var err = false;
        var e = {};
        if (selectedRows.length === 0 && !mappingType === 3) {
            e.selectedRows = true;
            err = true;
        }
        if (rows.length === 0) {
            e.rows = true;
            err = true;
        }
        if (portion !== 100) {
            e.rows = true;
            err = true;
        }

        return [err, e];
    }

    const saveRules = async (evt) => {
        let bgData = await backgroundProcessCheck(monthFilter, yearFilter);
        if (bgData.length > 0) {
            showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
        }
        else {
            evt.preventDefault();
            const [err, e] = validateData();

            if (!err) {
                let ceSelected = []
                if (selectedRows.length > 0) {
                    selectedRows?.forEach((i) => {
                        let srlNo = 1
                        rows?.forEach((twItem) => {
                            if (twItem.splitStrategy === "byTier") {
                                twItem.tierAllocation?.forEach((trItem, j) => {
                                    if (trItem && trItem !== 0 && trItem !== "") {
                                        ceSelected.push({
                                            ...costElementsRemaining[i], "towerId": parseInt(twItem.name.split(" | ")[0]), "subTowerId": parseInt(twItem.name.split(" | ")[1]), "id": null,
                                            "yearNameId": yearFilter, "monthNameId": monthFilter, "tier": 'Tier ' + (j + 1), "srlNo": srlNo, "portion": ((parseInt(trItem) / 100) * parseFloat(twItem.portion)) / 100, "destinationTable": twItem.destinationTable, "destinationTableName": twItem.destinationTableName, "condition": twItem.condition,
                                            "uploadedFileId": null, "weight": twItem.weight, "ruleName": ruleName
                                        })
                                        srlNo ++;
                                    }
                                })
                            }
                            else {
                                ceSelected.push({
                                    ...costElementsRemaining[i], "towerId": parseInt(twItem.name.split(" | ")[0]), "subTowerId": parseInt(twItem.name.split(" | ")[1]), "id": null,
                                    "yearNameId": yearFilter, "monthNameId": monthFilter, "tier": null, "srlNo": srlNo, "portion": (parseFloat(twItem.portion) / 100), "destinationTable": twItem.destinationTable, "destinationTableName": twItem.destinationTableName, "condition": twItem.condition,
                                    "uploadedFileId": null, "weight": twItem.weight, "assetLink": twItem.assetLink, "ruleName": ruleName
                                })
                                srlNo ++;
                            }
                        })
                    })
                } else {
                    let srlNo = 1
                    rows?.forEach((twItem) => {
                        if (twItem.splitStrategy === "byTier") {
                            twItem.tierAllocation?.forEach((trItem, j) => {
                                if (trItem && trItem !== 0 && trItem !== "") {
                                    ceSelected.push({
                                        "towerId": parseInt(twItem.name.split(" | ")[0]), "subTowerId": parseInt(twItem.name.split(" | ")[1]), "id": null,
                                        "yearNameId": yearFilter, "monthNameId": monthFilter, "tier": 'Tier ' + (j + 1), "srlNo": srlNo, "portion": ((parseInt(trItem) / 100) * parseFloat(twItem.portion)) / 100, "destinationTable": twItem.destinationTable, "destinationTableName": twItem.destinationTableName, "condition": twItem.condition,
                                        "uploadedFileId": null, "weight": twItem.weight, "ruleName": ruleName
                                    })
                                    srlNo ++;
                                }
                            })
                        }
                        else {
                            ceSelected.push({
                                "towerId": parseInt(twItem.name.split(" | ")[0]), "subTowerId": parseInt(twItem.name.split(" | ")[1]), "id": null,
                                "yearNameId": yearFilter, "monthNameId": monthFilter, "tier": null, "srlNo": srlNo, "portion": (parseFloat(twItem.portion) / 100), "destinationTable": twItem.destinationTable, "destinationTableName": twItem.destinationTableName, "condition": twItem.condition,
                                "uploadedFileId": null, "weight": twItem.weight, "assetLink": twItem.assetLink, "ruleName": ruleName
                            })
                            srlNo ++;
                        }
                    })
                }

                if (allocationFileId) {
                    ceSelected.map((a) => a["allocationFileId"] = allocationFileId)
                }

                submitRules(ceSelected,assetRelationships);

            } else {
                setErrors(e);
            }
        }
    }
    const onAddRuleClose = () => {
        setShowAddRuleModal(false)
    }

    const onAddRuleSave = (rule, rowId) => {
        if (rowId) {
            delete rows[rowId];
            rows[rowId] = rule;
        } else {
            let newRows = rows?.filter(r => r.name !== rule.name);
            newRows.push(rule);
            setRows(newRows);
            setRowId(null)
        }
    }
    const onLoadRuleSave = (givenRuleName, uploadId) => {    
        setRuleName(givenRuleName)
        setAllocationFileId(uploadId)
    }

    const handleAdd = () => {
        setErrors({})
        setCurrentItem({})
        setRowId(null)
        setShowAddRuleModal(true);
    }

    const onLoadRuleClose = () => {
        setShowLoadRuleModal(false)
        setConsumptionLoad(true)
    }

    const handleLoad = () => {
        setErrors({})
        setShowLoadRuleModal(true);
    }

    const handleDelete = (item) => {
        setErrors({})
        let newRows = rows?.filter(r => r.name !== item.name);
        setRows(newRows);
    }
    const handleEdit = (item, id) => {
        setRowId(id);
        setCurrentItem(item)
        setErrors({})
        setShowAddRuleModal(true);
    }

    const adjustPortion = () => {
        setErrors({})
        let newRows = rows
        let totalportiondiif = 100 - _.sumBy(rows, (o) => { return parseFloat(o.portion) })
        newRows[newRows.length - 1].portion = parseFloat(newRows[newRows.length - 1].portion) + totalportiondiif
        setRows(newRows)

    }
    const multiTowerColumns = enableAssetDistribution ?
        [
            { Header: "Actions", accessor: "name", width: 80, disableSorting: true, Cell: ({ row }) => { return (
            <MDBox display="flex" alignItems="left" mt={{ xs: 2, sm: 0 }}>
                {!consumptionLoad ? <><Tooltip title="Delete" placement="top"><IconButton onClick={() => handleDelete(row.values)} sx={{ padding: 0, paddingRight: 0.3 }}><Icon color="error">delete</Icon></IconButton></Tooltip></> : ''}
                <Tooltip title="Edit" placement="top"><IconButton onClick={() => handleEdit(row.original, row.id)} sx={{ padding: 0, paddingLeft: 0.3 }}><Icon color="info">edit</Icon></IconButton></Tooltip>
            </MDBox>
            ) } },
            { Header: "Tower", accessor: "desc", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Portion", accessor: "portion", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{`${numeral(value).format('0.00')}%`}</MDTypography> } },
            { Header: "Asset", accessor: "destinationTable", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{_.startCase(value)}</MDTypography> } },
            { Header: "Split", accessor: "weight", disableSorting: true, Cell: ({ row: { original } }) => { return <MDTypography variant="caption" color="dark">{((original?.destinationTable || "") !== "" ? ((original?.weight || "") !== "" ? `By Weight(${_.startCase(original?.weight)})` : ((original?.splitStrategy || "") === "byTier") ? "By Tier" : ((original?.splitStrategy || "") === "byConsumption") ? "By Consumption" : ((original?.splitStrategy || "") === "bySpend") ? "By Asset ID (GL)" : "Equally") : "")}</MDTypography> } },
            { Header: "Condition", accessor: "condition", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{(value || "") !== "" && <Tooltip title={value}><Icon fontSize="medium" color="text">info</Icon></Tooltip>}</MDTypography> } },
        ]
        : [
            { Header: "Actions", accessor: "name", width: 80, disableSorting: true, Cell: ({ row }) => { return (
                <MDBox display="flex" alignItems="left" mt={{ xs: 2, sm: 0 }}>
                    <Tooltip title="Delete" placement="top"><IconButton onClick={() => handleDelete(row.values)} sx={{ padding: 0, paddingRight: 0.3 }}><Icon color="error">delete</Icon></IconButton></Tooltip>
                    <Tooltip title="Edit" placement="top"><IconButton onClick={() => handleEdit(row.original, row.id)} sx={{ padding: 0 }}><Icon color="info">edit</Icon></IconButton></Tooltip>
                </MDBox>
            ) } },
            // { Header: "", accessor: "name", width: 70, disableSorting: true, Cell: ({ row }) => { return <IconButton onClick={() => handleDelete(row.values)} sx={{ padding: 0 }}><Icon color="error">delete</Icon></IconButton> } },
            // { Header: "", accessor: "edit", width: 70, disableSorting: true, Cell: ({ row }) => { return <IconButton onClick={() => handleEdit(row.original, row.id)} sx={{ padding: 0 }}><Icon color="info">edit</Icon></IconButton> } },
            { Header: "Tower", accessor: "desc", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Portion", accessor: "portion", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{`${numeral(value).format('0.00')}%`}</MDTypography> } },
        ];

    const selectedTowers = rows?.map(r => r.name) || [];

    const portionRoundOff = (portion) => {
        if (!isNaN(portion))
            return portion?.toString()?.match(/^-?\d+(?:\.\d{0,2})?/)[0]
    }

    useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
        if (notification.details?.towerGroupedResult) {
            setRows(notification.details.towerGroupedResult);
        }
        if (notification.details?.assetRelationshipGroupedResult) {
            setAssetRelationships(notification.details.assetRelationshipGroupedResult);
        }
    });
  
    

    return (
        <MDBox height="100%" width="100%" textAlign="center" >

            {showAddRuleModal && !allocationFileId && <TowerRuleModal enableAssetDistribution={enableAssetDistribution} yearFilter={yearFilter} monthFilter={monthFilter} costElementsRemaining={costElementsRemaining} selectedRows={selectedRows} selectedTowers={selectedTowers} submitRules={submitRules} subTowers={subTowers} onAddRuleSave={onAddRuleSave} onAddRuleClose={onAddRuleClose} item={currentItem} rowId={rowId} ruleName={ruleName} edit={edit} editValue={editValue} columnFilterCondition={columnFilterCondition}/>}
            {showLoadRuleModal && !allocationFileId && <LoadRuleModal enableAssetDistribution={enableAssetDistribution} yearFilter={yearFilter} monthFilter={monthFilter} costElementsRemaining={costElementsRemaining} selectedRows={selectedRows} selectedTowers={selectedTowers} submitRules={submitRules} subTowers={subTowers} onLoadRuleSave={onLoadRuleSave} onClose={onLoadRuleClose} uploadType={"towerConsumption"} />}
            {allocationFileId && rows.length === 0  ? <CircularProgress color="info" sx={{ marginTop: 9 }} size={30} /> : ''}
            <MDBox height="calc(100vh - 430px)" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent={rows && rows.length > 0 ? "flex-start" : "center"}>
                <MDBox pt={1} pb={1} width="100%" display="flex" flexDirection="column" alignItems="flex-start" sx={{ overflow: "auto" }}>
                    {
                        rows && rows.length > 0 && (
                            <>
                                {!ruleName && (<MDButton size="small" startIcon={<Icon size="medium" fontSize="medium">add</Icon>} variant="text" color="info" onClick={handleAdd} sx={{ marginBottom: 1, marginRight: 2, alignSelf: "flex-end" }}>Add Tower</MDButton>)}
                                <DataTable
                                    variant="tile"
                                    table={{ columns: multiTowerColumns, rows }}
                                    // containerMaxHeight={424}
                                    showTotalEntries={false}
                                    isSorted={false}
                                    noEndBorder
                                    entriesPerPage={false}
                                    canSearch={false}
                                />
                            </>
                        )
                    }
                    {rows.length > 0 &&
                        <MDBox pt={1} width="100%" textAlign="center">
                            <MDTypography variant="caption" color="info">Allocated: {portionRoundOff(portion)}%</MDTypography> |
                            <MDTypography variant="caption" color="info"> Remaining: {portionRoundOff((100 - portionRoundOff(portion)))}% </MDTypography>
                        </MDBox>}
                    {(portion > 100 || (errors && errors.rows)) &&
                        <MDBox pt={0} width="100%" textAlign="center" >
                            <MDTypography variant="caption" mt={0} pt={0} color="error">Allocated portions should sum-up to 100%</MDTypography>
                        </MDBox>}
                </MDBox>
                {
                    ((!rows || rows.length === 0) && !allocationFileId) && (
                        <MDBox width="100%" textAlign="center" >
                            <MDButton sx={{ width: 300, marginTop: 6 }} startIcon={<Icon>add</Icon>} variant="outlined" color="info" onClick={handleAdd}>Add Tower</MDButton>
                            {controller['appDef']['name'] !== 'vendor-app' && <MDButton sx={{ width: 300, marginTop: 6 }} startIcon={<Icon>upload</Icon>} variant="outlined" color="info" onClick={handleLoad}>Add Towers From Allocation File</MDButton>}
                        </MDBox>
                    )
                }
            <MDBox display = "flex"  flexDirection="row" justifyContent="center" alignItems="center">
            {
                rows && rows.length > 0 && (
                    <MDBox py={1} mx={1}  sx={{ backgroundColor: "white" }}>
                        <MDButton
                            name="saveRules"
                            variant="gradient"
                            color="info"
                            onClick={saveRules}
                            disabled={portion !== 100 ? true : false}
                            startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined}
                        >
                            Save Rules
                        </MDButton>
                    </MDBox>
                )
            }
            {
                rows && rows.length > 0 && (
                    <MDBox py={1} sx={{ backgroundColor: "white" }}>
                        <MDButton
                            name="adjustPortion"
                            variant="gradient"
                            color="info"
                            onClick={adjustPortion}
                            disabled={Math.abs(100-portion) > 0.01 || portion === 100 ? true : false}
                        >
                            Adjust Portion
                        </MDButton>
                    </MDBox>
                )
            }
            </MDBox>
            </MDBox>
        </MDBox>
    );
};

export default MultiTowerRule;