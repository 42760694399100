import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
// import * as XLSX from 'xlsx';
import MDTypography from "components/MDTypography";
import { Icon, Card, Tooltip, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
// import { getDomain } from 'utils';
// import Axios from "axios";
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useYADialog } from "components/YADialog";
// import MDButton from 'components/MDButton';
// import { useNavigate } from "react-router-dom";
// import InterfaceForm from '../Interfaces/components/InterfaceForm';
import { DataStoreInterfaceHandler } from "components/FilteredUploadedFiles/helpers/DataStoreInterfaceHandler";
const Automate = () => {

    const { showAlert, showCustomForm } = useYADialog();
    const [interfaceList, setInterfaceList] = useState([])
    let dataStoreExcludes = ["BUConsumption", "businessCaseCloud", "businessCaseNonCloud", "towerConsumption", "budgetDetail", "costCentreBudgetDetail", "Tower Consumption", "Business Unit Consumption"]
    
    let isdataStoreExclude = (destinationTable) => {
        return dataStoreExcludes.find(elem => elem === destinationTable)
    }
    // let navigate = useNavigate();

    const handleError = useHandleError();
    let getInterfaceList = async () => {
        const [err1, data] = await fetchRequest.get('/api/interface/list')
        if (!err1) {
            setInterfaceList(data)
        } else {
            console.log(err1)
        }

    }

    useEffect(() => {
        getInterfaceList()
    }, [])
    const buildColumns = () => {
        return [
            {
                "Header": "Actions",
                "disableSorting": true,
                "accessor": "actions",
                "Cell": ({ cell: { value } }) => {
                    return <MDTypography variant="caption" color="dark">
                        {value}
                    </MDTypography>
                }
            },
            {
                "Header": "Name",
                "accessor": "displayName",
                "Cell": ({ cell: { value } }) => {
                    return <MDTypography variant="caption" color="dark">
                        {value}
                    </MDTypography>
                }
            },
            {
                "Header": "Description",
                "accessor": "description",
                "Cell": ({ cell: { value } }) => {
                    return <MDTypography variant="caption" color="dark">
                        {value}
                    </MDTypography>
                }
            }
        ]
    }
    
    // const newInterface = (fileSource) => {
    //     showCustomForm("New Interface", () => <InterfaceForm fileSource={fileSource} />, null, null, null, 'sm');
    // }

    const handleAutomate = async (item) => {
        const modelData = {};
        modelData["Name"] = item.displayName ? item.displayName : "not configured"
        modelData["DestinationTable"] = item.destinationTable
        modelData["FileType"] = item.fileType
        modelData["MappingFields"] = JSON.stringify(item.mappingFields ? item.mappingFields : [])
        modelData["DefaultFields"] = JSON.stringify(item.defaultFields ? item.defaultFields : [])

        if (JSON.parse(modelData["MappingFields"]).length === 0 || item.destinationTable === "" || item.fileType === "")
            showAlert("Automate", "[Type], [Destination] OR [Mapping] not configured for this source");
        else {
            DataStoreInterfaceHandler(modelData, showAlert, showCustomForm, false, getInterfaceList)
        }
    }

    let colorSet = (item) => {
        let color
        let sourceDataCheck = interfaceList.find(obj => obj.name === item.displayName)
        sourceDataCheck ? color = "info" : color = ""
        return color
    }
    const buildRows = (data) => {
        const rows = [];
        if (Array.isArray(data) && data.length > 0) {
            data.forEach((r) => {
                let row = {};
                Object.keys(r).forEach((k) => {
                    row[k.replace(/\./g, "__")] = r[k]
                });
                row["actions"] = (
                    <>
                        <MDBox display="flex" alignItems="left" mt={{ xs: 2, sm: 0 }}>
                            {
                                !isdataStoreExclude(r.destinationTable) ?
                                    <Tooltip title="Automate" placement="top">
                                        <IconButton onClick={() => handleAutomate(r)} aria-label="Automate">
                                            <Icon fontSize="small" color={colorSet(r)} >bolt</Icon>
                                        </IconButton>
                                    </Tooltip> : <MDTypography display="flex" alignItems="left" component="a" href="#" variant="caption" color="text" fontWeight="medium" aria-label="empty">
                                        <MDBox width={"40px"} />
                                    </MDTypography>
                            }
                            {/* <Tooltip title="Download" placement="top">
                                <IconButton onClick={() => r.type === "Asset" ? onDownload(r.type , r.def, r.displayName, r.mappingFields) : onDownload(r["name"], r["fileName"],null, r.mappingFields)} >
                                    <Icon fontSize="small" >download</Icon>
                                </IconButton>
                            </Tooltip> */}
                        </MDBox>
                    </>
                )
               if(!isdataStoreExclude(r.destinationTable))
                rows.push(row);
            });
        }
        return rows;
    }

    // const handleDownload = async (name, fileDef, displayName, mappingFields) => {
    //     if(name === "Asset") {
    //         let def = JSON.parse(fileDef)
    //         let headerArr = []
    //         let headers = Object.assign([], def.fields.map( def => { return def.displayName }))
    //         headerArr.push(headers)
    //         const wb = XLSX.utils.book_new();
    //         const ws = XLSX.utils.json_to_sheet([]);
    //         XLSX.utils.sheet_add_aoa(ws, headerArr);
    //         XLSX.utils.book_append_sheet(wb, ws, '');
    //         XLSX.writeFile(wb, `${displayName}.xlsx`, { type: "array", bookType: "xlsx" });
    //         await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Template - [${displayName}.xlsx]` })
    //     }else {
    //         // const response = await Axios.get(`${domain}/api/templates/download/${name}?${("nc=" + Math.random()).replace(".", "")}`, { responseType: "blob" });
    //         // var blob = new Blob([response.data]);
    //         // var url = URL.createObjectURL(blob);
    //         // const link = document.createElement('a');
    //         // link.setAttribute("href", url);
    //         // link.setAttribute("download", fileDef);
    //         // link.style = "visibility:hidden";
    //         // document.body.appendChild(link);
    //         // link.click();
    //         // document.body.removeChild(link);
    //         let headerArr = []
    //         let headers = Object.assign([], mappingFields.map( e => { return e.sourceColumn }))
    //         headerArr.push(headers)
    //         const wb = XLSX.utils.book_new();
    //         const ws = XLSX.utils.json_to_sheet([]);
    //         XLSX.utils.sheet_add_aoa(ws, headerArr);
    //         XLSX.utils.book_append_sheet(wb, ws, '');
    //         XLSX.writeFile(wb, fileDef, { type: "array", bookType: "xlsx" });
    //         await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Template - [${fileDef}]` })
    //     }
    // }

    // const domain = getDomain();
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const columns = buildColumns();

    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/templates/list`);
            var [err1, dataRes] = await fetchRequest.get(`/api/dataflow/assetTypes`);
            var [err2, dataDef] = await fetchRequest.get(`/api/dataflow/mappingFields`);
            
            if (err || err1 || err2) {
                handleError(err);
            }
            else {
                let data11 = Object.assign([], data)
                let data1 = data11.filter(o => o.hidden !== true)
                data1.map(data => {
                    if (!data["hidden"]) {
                        data["mappingFields"] = dataDef.find(itm => itm.key === data.name)?.mappingFields
                        data["destinationTable"] = data.name
                        data["fileType"] = dataDef.find(itm => itm.key === data.name)?.type
                    }
                })
                dataRes.map(data => {
                    data["destinationTable"] =  data["name"]
                    data["fileType"] = "Assets"
                    data["type"] = "Asset"
                    let def = JSON.parse(data["def"]).fields.map(item => {
                        return {
                            "destinationColumn": item.schemaName,
                            "sourceColumn": item.displayName
                        }
                    })
                    data["mappingFields"] = def
                    data["description"] = `${data.displayName} file - Add list of ${data.displayName.toLowerCase()} available across the organization`
                    data1.push(data)
                })
                setRows(buildRows(data1));
                setLoading(false);
            }
        }
        getList();
    }, [interfaceList])

    if (loading) {
        return <YASkeleton variant="dashboard-loading" />;
    }

    if (loading === false && !rows) {
        return (
            <div>
                no data
            </div>
        );
    }

    // const renderPrimaryActions = ()=> {
    //     return <MDButton
    //     data-testid={"download"}
    //     variant="gradient"
    //     color="info"
    //     onClick={()=> {navigate('/admin/data-dictionary') }}
    //   >
    //     Data Dictionary
    //   </MDButton>
    //   }

    return (
        <>
            <PageHeader title="Automate" subtitle="Automate files from here for all your data uploads." />
            <MDBox p={3} pt={1}>
                <Card sx={{ height: "100%" }} px={0}>
                    <DataTable
                        table={{ columns, rows }}
                        showTotalEntries={true}
                        isSorted={true}
                        newStyle1={true}
                        noEndBorder
                        entriesPerPage={true}
                        canSearch={true}
                    />
                </Card>
            </MDBox>
        </>
    );
};

export default AnimatedRoute(Automate);