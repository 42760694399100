import { useContext, useEffect, useState } from "react";
import { CubeContext } from "@cubejs-client/react";

const useCubeMultiQueries = (queries) => {
    const { cubeApi } = useContext(CubeContext);
    const [result, setResult] = useState({ isLoading: false, resultSet: null, error: null });

    useEffect(() => {
        if (!Array.isArray(queries) || queries.some(query => typeof query !== 'object')) {
            setResult({ isLoading: false, resultSet: [], error: new Error('Invalid queries format') });
            return;
        }

        const fetchData = async () => {
            setResult({ isLoading: true, resultSet: null, error: null });
            try {
                const resultSet = await Promise.all(
                    queries.map(query => {
                        if (query?.measures?.length === 0) return Promise.resolve(null);
                        else
                            return cubeApi.load(query).then(rs => rs)
                    })
                );
                setResult({ isLoading: false, resultSet, error: null });
            } catch (error) {
                setResult({ isLoading: false, resultSet: [], error });
            }
        };
        fetchData();
    }, [JSON.stringify(queries)]);

    return result;
}

export default useCubeMultiQueries;