import { createContext, useReducer, useMemo, useContext } from "react";
import produce from "immer";
import { getDefaultReportVariables } from "utils/dashboard";
import { cloneDeep } from "lodash";

const CustomDashboardContext = createContext();

const dashboardInitialState = {
  dashboardDef: {},
  widgets: [],
  filters: [],
  variables: {},
  selectedFilters: []
}

const applyDefaultFilterForQuery = (filter, query) => {
  const applyFilter = (q) => {
    if (!q.filters) {
      q.filters = [];
    }

    const existingFilter = q.filters.find(f => f.member === filter.queryName);
    if (existingFilter) {
      existingFilter.values = filter.defaultValue;
    } else {
      q.filters.push({
        member: filter.queryName,
        operator: "equals",
        values: filter.defaultValue
      });
    }
  };

  if (Array.isArray(query)) {
    query.forEach(q => applyFilter(q));
  } else {
    applyFilter(query);
  }
};

export const applyDefaultFilters = (state) => {
  if (state.widgets?.length > 0 && state.filters?.length > 0)
    state.filters.forEach(filter => {
      if ((filter.dependencies || []).length > 0 && (filter.defaultValue || []).length > 0) {
        state.widgets
          .filter(w => filter.dependencies.includes(w.id))
          .forEach((widget) => {
            applyDefaultFilterForQuery(filter, widget.vizState.query)
          })
        filter.defaultSelected = true;
        filter.values = filter.session ?
          sessionStorage[filter.defaultValue[0]]?.split(",") : filter.defaultValue;
      }
    })
}

const setSelectedFilterForQuery = (originalWidgetQuery, query, member, operator, values) => {
  const applyFilter = (originalQuery, q) => {
    let originalQueryHasSelectedFilter = (originalQuery?.filters || [])?.find(f => f.member === member);
    let selectedFilter = q.filters?.find(f => f.member === member);
    let filterValues = [...values];
    if (originalQueryHasSelectedFilter)
      filterValues = [...filterValues, ...originalQueryHasSelectedFilter.values];
    if (selectedFilter) {
      selectedFilter.values = filterValues;
    } else {
      q.filters?.push({ member, operator, values: filterValues });
    }

    if ((values || []).length === 0) {
      if (originalQueryHasSelectedFilter) {
        let queryFilter = (q?.filters || [])?.find(f => f.member === member);
        queryFilter.values = originalQueryHasSelectedFilter.values?.concat([]);
      } else
        q.filters = (q.filters || [])?.filter(f => f.member !== member);
    }
  };

  if (Array.isArray(query)) {
    query.forEach((q, index) => applyFilter(Array.isArray(originalWidgetQuery) ? originalWidgetQuery[index] : originalWidgetQuery, q));
  } else {
    applyFilter(originalWidgetQuery, query);
  }
};

const deleteSelectedFilterForQuery = (originalWidgetQuery, query, filterName) => {
  const applyFilter = (originalQuery, q) => {
    let originalQueryHasSelectedFilter = (originalQuery?.filters || [])?.find(f => f.member === filterName);
    if (originalQueryHasSelectedFilter) {
      let selectedFilter = (q?.filters || [])?.find(f => f.member === filterName);
      selectedFilter.values = originalQueryHasSelectedFilter.values;
    } else {
      q.filters = (q.filters || [])?.filter(f => filterName !== f.member);
    }
  };

  if (Array.isArray(query)) {
    query.forEach((q, index) => applyFilter(Array.isArray(originalWidgetQuery) ? originalWidgetQuery[index] : originalWidgetQuery, q));
  } else {
    applyFilter(originalWidgetQuery, query);
  }
};

const applyDrilldownFilters = (state, drilldownFilters) => {
  if (drilldownFilters?.length > 0 && state.filters?.length > 0) {
    const drilldownFilterNames = drilldownFilters.map(f => f.queryName);
    state.filters.forEach(filter => {
      const drilldownFilter = drilldownFilters.find(f => f.queryName === filter.queryName);
      if (drilldownFilter) {
        filter.defaultSelected = true;
        filter.defaultValue = drilldownFilter.values;
      }
    })

    state.selectedFilters = state.filters.filter(f => drilldownFilterNames.includes(f.queryName));
  }
}

const dashboardReducer = (state, action) => {
  switch (action.type) {
    case "INIT_CONTEXT": {
      const variables = getDefaultReportVariables(action.dashboardDef?.variables?.yearNameFormat, action.dashboardDef?.variables?.firstMonth);
      return produce(state, draft => {
        draft.dashboardDef = cloneDeep(action.dashboardDef || {});
        draft.widgets = cloneDeep(action.dashboardDef?.config?.widgets || []);
        draft.filters = cloneDeep(action.dashboardDef?.config?.filters || []);
        draft.variables = variables;

        applyDrilldownFilters(draft, action.drilldownFilters);

        applyDefaultFilters(draft);
      });
    }
    case "SET_FILTER": {
      return produce(state, draft => {
        let selectedFilter = draft.selectedFilters?.find(f => f.name === action.selectedFilter.name);
        if (selectedFilter) {
          selectedFilter.values = action.selectedFilter.values;
          // set dependent widget filter values
          if (selectedFilter.dependencies?.length > 0)
            draft.widgets?.filter(w => selectedFilter.dependencies.includes(w.id))?.map(w => {
              const originalWidget = draft.dashboardDef?.config?.widgets?.find(ow => ow.id === w.id);
              setSelectedFilterForQuery(originalWidget?.vizState?.query, w.vizState.query, selectedFilter.queryName, selectedFilter.operator, selectedFilter.values || [])
            })
        }
      });
    }
    case "SELECT_FILTER": {
      return produce(state, draft => {
        let selectedFilter = draft.filters?.find(f => f.name === action.selectedFilter.name);
        if (selectedFilter)
          draft.selectedFilters.push(selectedFilter);
      });
    }
    case "DELETE_FILTER": {

      return produce(state, draft => {
        draft.selectedFilters = draft.selectedFilters?.filter(f => f.queryName !== action.selectedFilterName);
        let selectedFilter = draft.filters?.find(f => f.queryName === action.selectedFilterName);
        // set dependent widget filter values
        if (selectedFilter && selectedFilter.dependencies?.length > 0)
          draft.widgets?.filter(w => selectedFilter.dependencies.includes(w.id))?.map((w) => {
            const originalWidget = draft.dashboardDef?.config?.widgets?.find(ow => ow.id === w.id);
            deleteSelectedFilterForQuery(originalWidget?.vizState?.query, w.vizState.query, action.selectedFilterName);
          })
          selectedFilter.values = [];
      });
    }
    case "CLEAR_FILTERS": {
      return produce(state, draft => {
        draft.selectedFilters = [];
        draft.widgets = cloneDeep(draft.dashboardDef?.config?.widgets || []);
        draft.filters = cloneDeep(draft.dashboardDef?.config?.filters || []);
      });
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const CustomDashboardContextProvider = (props) => {
  const [state, dispatch] = useReducer(dashboardReducer, dashboardInitialState);

  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  return (
    <CustomDashboardContext.Provider value={value}>
      {props.children}
    </CustomDashboardContext.Provider>
  );
}

export const useCustomDashboardContext = () => {
  return useContext(CustomDashboardContext);
}

export const initDashboard = (dispatch, dashboardDef, drilldownFilters) => dispatch({ type: "INIT_CONTEXT", dashboardDef, drilldownFilters });
export const setFilter = (dispatch, selectedFilter) => dispatch({ type: "SET_FILTER", selectedFilter });
export const selectFilter = (dispatch, selectedFilter) => dispatch({ type: "SELECT_FILTER", selectedFilter });
export const deleteFilter = (dispatch, selectedFilterName) => dispatch({ type: "DELETE_FILTER", selectedFilterName });
export const clearFilters = (dispatch) => dispatch({ type: "CLEAR_FILTERS" });