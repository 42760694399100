import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { Icon, Card, IconButton, Tooltip } from "@mui/material";
import Switch from '@mui/material/Switch';
import { useContext, useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useYADialog } from 'components/YADialog';
import { CubeContext } from '@cubejs-client/react';
import moment from 'moment';
import YASkeleton from "components/YASkeleton";
import { CircularProgress } from "@mui/material";

const loadQueryResult = async (cubeApi, results) => {
    for (const result of results) {
        let query = {"measures": [`${result.cubeQueryColumn}`]}
        result.refreshRequested = moment(result.refreshRequested).format("DD MMM YYYY HH:mm:ss") 
        try {
            const response = await cubeApi.load(query);
            if (response?.loadResponses[0]?.data[0][result.cubeQueryColumn])
                result.lastRefreshed = moment(response?.loadResponses[0]?.data[0][result.cubeQueryColumn]).format("DD MMM YYYY HH:mm:ss")
            else
                result.lastRefreshed = moment(response?.loadResponses[0]?.lastRefreshTime).format("DD MMM YYYY HH:mm:ss")
            if ((moment(result.lastRefreshed) - moment(result.refreshRequested)) >= 0)
                result.refreshStatus = "Refreshed" // + ((moment(response?.loadResponses[0]?.lastRefreshTime) - moment(result.refreshRequested)) / 1000) + " seconds"
            else
                result.refreshStatus = "Refreshing" 
        } catch (error) {
            result.lastRefreshed = null
            result.refreshStatus = "Unknown"
        }        
    }
    return results
}

const buildColumns = () => {
    return [
        {
            "Header": "Actions",
            "disableSorting": true,
            "accessor": "actions",
            "maxWidth": 75,
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Cube Name",
            "accessor": "cubeName",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Refresh Condition",
            "accessor": "details",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Refreshed Cube(s)",
            "accessor": "cubeList",
            "Cell": ({ cell: { value } }) => {
                return value.map( v => <MDTypography key={v} variant="caption" color="dark">
                    [{v.replace("WithBudget","").replace("Details","").replace("WithForecasts","").replace("WithBusinessUnit","")}]&nbsp;
                </MDTypography>)
            }
        },
        {
            "Header": "Refresh Requested",
            "accessor": "refreshRequested",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Last Refreshed",
            "accessor": "lastRefreshed",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Refresh Status",
            "accessor": "refreshStatus",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                     {value === undefined ? "Fetching...." :  (value === 'Refreshing' ? 
                    <>Refreshing &nbsp;
                        <CircularProgress
                            size={12}
                            thickness={5}
                            sx={() => ({
                                color: "#ff7c17",
                                mt: "6px",
                                backgroundColor: "transparent",
                                position: "absolute",
                                zIndex: 1,
                            })}
                        />
                    </> : <>{value} <Icon sx={{ color: "#88ad02", pt: "2px", fontSize: "18px!important"}}>done</Icon></>)}
                </MDTypography>
            }
        }
    ]
}

const buildRows = (data, setRefresh, showSnackbar) => {

    const onRefresh = async (cubeKey) => {
        let cubeVal = cubeKey.charAt(0).toLowerCase() + cubeKey.slice(1);
        var [err, data] = await fetchRequest.post(`/api/dataflow/cubeRefreshKey/${cubeVal}?force=true`);
        if (err) {
            console.err(err)
        }
        else {
         setRefresh(Math.random());
         showSnackbar(data, "success")
         }
        }


    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, "__")] = r[k]
            });
            row["actions"] = (
                <MDBox display="flex"  alignItems="center" mt={{ xs: 2, sm: 0 }}>
                    <Tooltip title="Refresh" placement="top">
                        <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onRefresh(r["cube"])}>
                            <Icon fontSize="small" >refresh</Icon>
                        </IconButton>
                    </Tooltip>
                    {/* <MDTypography display="flex" alignItems="center" component="a" href="#" onClick={() => onRefresh(r["cube"])} variant="caption" color="text" fontWeight="medium">
                        <Icon fontSize="small" >refresh</Icon>&nbsp;Refresh
                    </MDTypography> */}
                </MDBox>
            )
            rows.push(row);
        });
    }
    rows.sort((a,b) =>  
        {
            if(a.cubeName < b.cubeName) { return -1; }
            if(a.cubeName > b.cubeName) { return 1; }
            return 0;
        }
    )
    return rows;
}

const CubeRefreshKey = () => {
    const { showSnackbar } = useYADialog();
    const [refresh, setRefresh] = useState(null);
    const handleError = useHandleError();
    const [rows, setRows] = useState([]);
    const [autoRefreshStatus, setAutoRefreshStatus] = useState(false)
    const columns = buildColumns();
    const { cubeApi } = useContext(CubeContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/dataflow/getCubeRefreshKey`);
            if (err) {
                handleError(err);
                setLoading(false)
            }
            else {
                data = data.filter((obj) => {
                    return obj.cube !== "all";
                });
                if (loading)
                    setRows(buildRows(data, setRefresh, showSnackbar));
                setLoading(false)
                data = await loadQueryResult(cubeApi, data)
                setRows(buildRows(data, setRefresh, showSnackbar));
            }
        }
        getList()
        const intervalId = setInterval(() => {
            getList();
        }, 30000) // in milliseconds
        return () => clearInterval(intervalId)
    }, [refresh]);

    useEffect(() => {
        async function getFiancialYearDetails() {
            var [, data] = await fetchRequest.get(`/api/settings/category/financialYearSetup`);
            if (data) {
                let refreshStatus = data.find(item => item.name === "autoCubeRefresh")?.value
                if (refreshStatus === "true") {
                    setAutoRefreshStatus(true)
                } 
                else 
                    setAutoRefreshStatus(false)
            }
        }
        getFiancialYearDetails()
    }, [])

    const onRefreshAll = async (cubeKey) => {
        let cubeVal = cubeKey.charAt(0).toLowerCase() + cubeKey.slice(1);
        var [err, data] = await fetchRequest.post(`/api/dataflow/cubeRefreshKey/${cubeVal}?force=true`);
        if (err) {
            console.log(err)
        }
        else {
             setRefresh(Math.random());
            showSnackbar(data, "success")
              }
    }

    const label = { inputProps: { 'aria-label': 'Color switch demo' } };
    const autoCubeVal = `Auto Cube Refresh (${autoRefreshStatus ? "ON" : "OFF"})`
    const handleChange = async (e)=> {
        setAutoRefreshStatus(e.target.checked)
        if(e.target.checked === true) onRefreshAll('all')
        var [err, data] = await fetchRequest.post(`/api/settings/category/autoCubeRefresh/${e.target.checked}`)
        if(err)
            console.log(err)
        else 
           showSnackbar(data, "success")  
    }

    if (loading) {
        return <YASkeleton variant="loading" />;
    }

    return (
        <>
            <MDBox color="text" pt={0} mt={0} display="flex" flexDirection="row" justifyContent="space-between">
                <PageHeader title="Cube Refresh" subtitle="Manually refresh cubes on an ad hoc basis, or in cases where an automatic cube refresh has not occurred." />
                <MDBox
                    mt={2.5}
                    mr={3}
                    pt={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <MDTypography variant="h5" color="dark">{autoCubeVal}</MDTypography>
                    <Switch {...label} checked={autoRefreshStatus} onChange = {handleChange} />
                    <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => onRefreshAll("all")}
                    >
                        Refresh All
                    </MDButton>
                </MDBox>

            </MDBox>
            <MDBox p={3} pt={1}>
                <Card sx={{ height: "100%" }} px={0}>
                    <DataTable
                        table={{ columns, rows }}
                        showTotalEntries={true}
                        isSorted={true}
                        newStyle1={true}
                        noEndBorder
                        entriesPerPage={true}
                        canSearch={true}
                    />
                </Card>
            </MDBox>

        </>
    );
};

export default AnimatedRoute(CubeRefreshKey);



