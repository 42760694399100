import { createContext, useContext, useState } from 'react';
import MDTypography from 'components/MDTypography';
import { Card, Icon, IconButton, Modal, Tooltip, Menu, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import MDBox from 'components/MDBox';
import YASkeleton from 'components/YASkeleton';
import moment from "moment";
import { useAppController } from 'context';
import { openContextHelp } from 'context';
import { getPageName } from 'utils';
import MDButton from "components/MDButton";
import colors from 'assets/theme/base/colors';
import fetchRequest from 'utils/fetchRequest';
import { useYADialog } from 'components/YADialog';
import { DashboardContext } from 'components/DashboardContext';
import { useNavigate } from 'react-router-dom';
import { DashboardCopilotExplainSidebar } from 'components/DashboardCopilotExplain';

export const DashboardItemContext = createContext();

export const useDashboardItem = () => {
  return useContext(DashboardItemContext);
};

const DashboardItem = ({ children, table, title, subtitle, chartRef, onCsvExport, loading, nodata, noOptions, download, isTable, primaryRender, onS3Export, chargeBackStatus, noLink, disableFullscreen, toggleType, setToggleType, hideToggle, parsedResultset, cubeOptions }) => {

  const [controller, dispatch] = useAppController();
  const { helpCenterUrl, helpCenterInternal, showinapphelp, helpCenterToken, graphExplanationCopilotEnabled } = controller;
  const { showDashboardCopilot, showAlert } = useYADialog();
  const dashboardContext = useContext(DashboardContext);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [toggleAskYarkenSidebar, setToggleAskYarkenSidebar] = useState(false);

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  };

  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };

  const handleOpenCopilot = () => {
    if (cubeOptions?.openAskYarkenInline)
      setToggleAskYarkenSidebar(true)
    else
      showDashboardCopilot({
        title,
        description: subtitle,
        data: parsedResultset,
        filters: dashboardContext?.[0]?.filters || []
      })
  };

  const handleToggle = (event, chartType) => {
    chartType && setToggleType(chartType);
  };

  // const convPageName2HTML = (page) => {
  //   let pg = []
  //   if (page) {
  //     let pge = page.split('-')
  //     let idx = ""
  //       // if (pge[0] === 'ct') {
  //       //   idx = '(Cost-Transparency)'
  //       // }
  //       // if (pge[0] === 'finance') {
  //       //   idx = '(Finance-Overview)'
  //       // }
  //       // if (pge[0] === 'vendor') {
  //       //   idx = '-'
  //       // }          
  //     if (idx !== "") {
  //       pge.shift()
  //       idx !== "-" ? pge.push(idx) : ""
  //     }
  //     pge.map(p => { pg.push(p.length <= 3 ? p.toUpperCase() : p.charAt(0).toUpperCase() + p.slice(1)) })
  //     pg = (pg + "").replaceAll(",","-")
  //   }
  //   else
  //     pg = "Home"
  //   return pg + '.html'
  // }

  let chartHelpRef = ""
  if (((getPageName().indexOf('rag-status') == -1) && (getPageName().indexOf('cost-center-owners') == -1) && (title.indexOf('General Ledger Transactions') == -1))) {
    if (helpCenterInternal)
      chartHelpRef = ('Home.html') + (showinapphelp ? showinapphelp === 'true' ? '' : '?t=' + helpCenterToken : '?t=' + helpCenterToken);
    // chartHelpRef = (convPageName2HTML(getPageName()) || 'Home.html') + (showinapphelp ? showinapphelp === 'true' ? '' : '?t=' + helpCenterToken : '?t=' + helpCenterToken) + '#' + title.replaceAll(' ', '-').toLowerCase();
    else
      chartHelpRef = (getPageName() || 'home') + (showinapphelp ? showinapphelp === 'true' ? '' : '?t=' + helpCenterToken : '?t=' + helpCenterToken) + '#' + title.replaceAll(' ', '-').toLowerCase();
  }

  const handleImageExport = async () => {
    (chartRef.current.chart.options.series[0].type != "sankey") ? chartRef.current.chart.options.chart.spacingTop = 8 : ""
    chartRef.current.chart.options.exporting.fallbackToExportServer = false;
    const subtitleSet = subtitle ? ` - ${subtitle}` : '';
    chartRef.current.chart.options.exporting.filename = title + subtitleSet + ' ' + moment(Date()).format("YYYYMMDDHHmmss");
    chartRef.current.chart.options.exporting.scale = 10;
    chartRef.current.chart.options.title.text = title;
    chartRef.current.chart.options.subtitle.text = subtitle ? subtitle : '';
    chartRef.current.chart.exportChartLocal({ type: "image/jpeg" });
    await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded chart ${title} from [${chartHelpRef.split('#')[0].replace(/-/g, " ")}]` })
    chartRef.current.chart.options.exporting.error = () => { showAlert("There is an Error While Exporting") };
  }

  const handleOpenInAnalytics = () => {
    if (cubeOptions && cubeOptions.cubeId) {
      let currentYear = sessionStorage.getItem("Year");
      const filters = cubeOptions?.vizData?.filters.map(filter => { if (filter.operator === 'currentYear') { return {... filter, operator: 'equals', values: [currentYear] } } return filter });
      let updatedFilters = filters; 
      if(cubeOptions?.ignoreFilters &&  cubeOptions?.ignoreFilters.length > 0)
         updatedFilters = filters.filter(filter => !cubeOptions.ignoreFilters.includes(filter.name))
      const updatedCubeOptions = { ...cubeOptions, vizData: { ...cubeOptions.vizData, filters: updatedFilters }}
      navigate(`/analytics/${cubeOptions.cubeId}`, { state: updatedCubeOptions })
    }
  }

  const render = () => {
    return <Card sx={{ height: "100%", overflow: "hidden", border: "none", position: "relative", borderRadius: "12px", boxShadow: "0px 2px 8px rgba(48, 53, 109, 0.1)", backgroundColor: colors.chartBackground, "&:hover": { "& .helpIcon": { visibility: 'visible' } } }} px={table && 0}>
      <MDBox pl={3} pt={2} display="flex" justifyContent="space-between" width="100%" alignItems="flex-start">
        <MDBox display="flex" flexDirection="row" width="100%" justifyContent="space-between">
          <MDBox display="flex" flexDirection="column" width="90%" justifyContent="space-between">
            <MDTypography variant="h6" component="span" color="text" display="flex" alignItems="center">
              {loading && <YASkeleton variant="title" />}
              {!loading &&
                <>
                  <MDBox data-testid={title?.toLowerCase().replaceAll(' ', '')}>
                    <Tooltip placement='bottom' display={{ lg: "none", md: "block", sm: "block", xs: "block" }} title={subtitle ? subtitle : title}>
                      <MDTypography variant="h6" component="h2" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                        {title}
                      </MDTypography>
                    </Tooltip>
                    <MDTypography display={{ lg: "block", md: "none", sm: "none", xs: "none" }} variant="h5" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                      {title}
                    </MDTypography>
                  </MDBox>
                  {
                    // (helpCenterUrl || "") !== "" && (chartHelpContextKey || "") !== "" &&
                    (helpCenterUrl || "") !== "" && (chartHelpRef || "") !== "" && !noLink &&
                    <>
                      <MDBox display={{ lg: "none", md: "flex", sm: "flex", xs: "flex" }} flexDirection="column" alignItems="right">
                        <Tooltip
                          placement="right"
                          title="Click to learn more"
                        >
                          <IconButton className="helpIcon"
                            sx={({ palette: { text } }) => ({
                              marginLeft: .15,
                              color: "#979191",
                              visibility: 'hidden',
                              "& .MuiIcon-root": {
                                fontSize: "16px!important"
                              },
                              "&:hover": {
                                color: text.main
                              }
                            })}
                            size="small"
                            onClick={() => {
                              // showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, chartHelpRef) :  window.open(helpCenterUrl+'/'+chartHelpRef,'yarkenhelp') :  window.open(helpCenterUrl+'/'+chartHelpRef,'yarkenhelp');
                              window.open(helpCenterUrl + '/' + chartHelpRef, 'yarkenhelp');
                            }}
                          >
                            <Icon>help</Icon>
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                      <MDBox display={{ lg: "flex", md: "none", sm: "none", xs: "none" }} flexDirection="column" alignItems="right">
                        <Tooltip
                          placement="right"
                          title="Click to learn more"
                        >
                          <IconButton className="helpIcon"
                            sx={({ palette: { text } }) => ({
                              marginLeft: .15,
                              color: "#979191",
                              visibility: 'hidden',
                              "& .MuiIcon-root": {
                                fontSize: "16px!important"
                              },
                              "&:hover": {
                                color: text.main
                              }
                            })}
                            size="small"
                            onClick={() => {
                              showinapphelp ? showinapphelp === 'true' ? openContextHelp(dispatch, chartHelpRef) : window.open(helpCenterUrl + '/' + chartHelpRef, 'yarkenhelp') : window.open(helpCenterUrl + '/' + chartHelpRef, 'yarkenhelp');
                            }}
                          >
                            <Icon>help</Icon>
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </>
                  }
                </>
              }
            </MDTypography>
            {
              (loading || subtitle) && (
                <MDBox data-testid={subtitle?.toLowerCase().replaceAll(' ', '')} display="flex" flexDirection="row">
                  <MDTypography variant="caption" color="text" display={{ lg: "block", md: "none", sm: "none", xs: "none" }} mt={0.3}>
                    {loading ? <YASkeleton variant="subtitle" /> : subtitle}
                  </MDTypography>
                </MDBox>
              )
            }
          </MDBox>
          {
            (!loading && primaryRender) && primaryRender
          }
          {
            (!loading && !nodata && !noOptions) && (
              <>
                <MDBox color="text" pt={0} mt={0} display="flex" flexDirection="row" gap={.75}>
                  {!hideToggle && !fullscreen &&
                    <>
                      {
                        graphExplanationCopilotEnabled && (
                          <Tooltip title="Ask YäRKEN" placement="top">
                            <MDButton
                              // size="medium"
                              disableRipple
                              color="dark"
                              variant="text"
                              onClick={handleOpenCopilot}
                              sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                              iconOnly
                            >
                              <Icon px={0} py={0}>auto_awesome</Icon>
                            </MDButton>
                          </Tooltip>
                        )
                      }
                     {
                     cubeOptions && cubeOptions.cubeId && <Tooltip title="Open In Analytics" placement="top">
                            <MDButton
                              // size="medium"
                              disableRipple
                              color="dark"
                              variant="text"
                              onClick={() => handleCloseMenuItem(handleOpenInAnalytics)}
                              sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                              iconOnly
                            >
                              <Icon px={0} py={0}>view_in_ar</Icon>
                            </MDButton>
                     </Tooltip>
                     }
                      <ToggleButtonGroup
                        color="primary"
                        value={toggleType}
                        exclusive
                        onChange={handleToggle}
                        sx={{ mb: 1.5 }}
                      >
                        <ToggleButton value="chart" aria-label= "barChart">
                          <Tooltip title="View Chart" placement="top">
                            <Icon>bar_chart</Icon>
                          </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="table"  aria-label= "table">
                          <Tooltip title="View Table" placement="top">
                            <Icon>table_chart</Icon>
                          </Tooltip>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </>
                  }
                  {
                     hideToggle && cubeOptions && <Tooltip title="Open In Analytics" placement="top">
                            <MDButton
                              // size="medium"
                              disableRipple
                              color="dark"
                              variant="text"
                              onClick={() => handleCloseMenuItem(handleOpenInAnalytics)}
                              sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                              iconOnly
                            >
                              <Icon px={0} py={0}>view_in_ar</Icon>
                            </MDButton>
                     </Tooltip>
                     }
                  <Menu
                    anchorEl={openMenu}
                    anchorReference={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={Boolean(openMenu)}
                    onClose={handleCloseMenu}
                  >
                    {download ? <MenuItem key={'download'} onClick={() => handleCloseMenuItem((onCsvExport && onCsvExport) || (chartRef && handleImageExport))}>{onCsvExport ? "Download CSV" : "Download Image"}</MenuItem> : isTable ? "" : <MenuItem key={'download'} onClick={() => handleCloseMenuItem((onCsvExport && onCsvExport) || (chartRef && handleImageExport))}>{onCsvExport ? "Download CSV" : "Download Image"}</MenuItem>}
                    {/* <MenuItem key={'download'} onClick={() => handleCloseMenuItem((onCsvExport && onCsvExport) || (chartRef && handleImageExport))}>{onCsvExport ? "Download CSV" : "Download Image"}</MenuItem> */}
                    {!disableFullscreen && !fullscreen && <MenuItem key={'fullscreen'} onClick={() => handleCloseMenuItem(setFullscreen(!fullscreen))}>{fullscreen ? "Exit Fullscreen" : "Show Fullscreen"}</MenuItem>}
                    {onS3Export && chargeBackStatus === 'APPROVED' && <MenuItem key={'exportToS3'} onClick={() => handleCloseMenuItem((onS3Export))}>{"Export to S3 Bucket"}</MenuItem>}

                  </Menu>

                  <MDBox>
                    {fullscreen && isTable && !download ? "" : <MDButton
                      // size="medium"
                      disableRipple
                      color="dark"
                      variant="text"
                      onClick={handleOpenMenu}
                      sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
                      iconOnly
                      aria-label="options"
                    >
                      <Icon px={0} py={0}>more_horiz</Icon>
                    </MDButton>}
                  </MDBox>
                  <MDBox pl={1.5} pt={0}>
                    {fullscreen && <Tooltip title={fullscreen ? "Exit fullscreen" : "Show in fullscreen"}>
                      <Icon sx={{ cursor: "pointer", fontWeight: "normal", marginRight: 3 }} fontSize="small" onClick={() => { setFullscreen(!fullscreen) }}>
                        {fullscreen ? "close_fullscreen" : "open_in_full"}
                      </Icon>
                    </Tooltip>}
                  </MDBox>
                </MDBox>
              </>
            )
          }
        </MDBox>
      </MDBox>
      <MDBox p={table ? 0 : 2} sx={{ height: '100%' }}>
        {nodata && (
          <MDBox width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Icon sx={{ color: "#d0cdcd", fontSize: "64px!important" }}>leaderboard</Icon>
            <MDTypography variant="subtitle2" component="h2" color="text">No Data</MDTypography>
          </MDBox>
        )
        }
        {loading && (
          <MDBox width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <YASkeleton variant="chart" />
          </MDBox>
        )
        }
        {!loading && !nodata && (
          <MDBox display="flex" sx={{ height: '100%' }}>
            <MDBox flex={1}>
              {children}
            </MDBox>
            {
              toggleAskYarkenSidebar && (
                <MDBox width="500px">
                  <DashboardCopilotExplainSidebar config={{
                    title,
                    description: subtitle,
                    data: parsedResultset,
                    filters: dashboardContext?.[0]?.filters || []
                  }}
                    onClose={() => setToggleAskYarkenSidebar(false)} />
                </MDBox>
              )
            }
          </MDBox>
        )}
      </MDBox>
    </Card>
  }
  return <DashboardItemContext.Provider value={{ fullscreen }}>
    {fullscreen ? (
      <Modal open={fullscreen} onClose={() => { setFullscreen(false) }}>
        <MDBox p={3} height="100%">
          {render()}
        </MDBox>
      </Modal>
    ) : render()}
  </DashboardItemContext.Provider>
};

DashboardItem.defaultProps = {
  table: false,
  nodata: false,
  loading: false,
};

export default DashboardItem;
