import { memo, useEffect, useMemo, useState } from "react";
import EmptyState from "components/EmptyState";
import MDBox from "components/MDBox";
import VisualizationRenderer from "components/VisualizationRenderer";
import { useImmer } from "use-immer";
import { DataFieldTypes } from "../../../../components/DataField";
import VisualizationEmptyState from "./components/VisualizationEmptyState";
import { Card, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import { toInt } from "utils";
import { useAnalyticsContext } from "context/AnalyticsContext";
import { validateQuery } from "utils/visualization";
import { getTimlineDateRangeByType } from "utils/dashboard";
const DEFAULT_QUERY_TEMPLATE = {
    measures: [], dimensions: [], filters: [], order: [], limit: 10000
};

const DEFAULT_PIVOT_CONFIG_TEMPLATE = {
    x: [], y: ["measures"], fillMissingDates: true, joinDateRange: false
};

const getPivotConfig = (visualizationData) => {
    let pivotConfig = {
        x: [], y: ["measures"], fillMissingDates: true, joinDateRange: false
    };

    if (visualizationData["rows"]) {
        pivotConfig.x = visualizationData.rows?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
    }
    if (visualizationData["columns"]) {
        pivotConfig.y = visualizationData.columns?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        pivotConfig.y.push("measures");
    }

    return pivotConfig;
};

const timelineOperators = ["isRelative"];
const valuelessOperators = ["set", "notSet", "currentQuarter", "previousQuarter", "currentMonth", "previousMonth", "currentYear", "previousYear"];

const getFilters = (visualizationData, standardVariables) => {
    if (visualizationData["filters"]) {
        const filters = visualizationData.filters?.filter(f => valuelessOperators.includes(f.operator) || (f.values && f.values.length > 0))?.map(
            f => {

                let operator = f.operator;
                let values = f.values;

                if (!["set", "notSet"].includes(f.operator) && valuelessOperators.includes(f.operator)) {
                    operator = "equals";
                    values = [standardVariables[f.operator]];
                }
                else if (!["set", "notSet"].includes(f.operator) && timelineOperators.includes(f.operator)) {
                    operator = "inDateRange";
                    values = getTimlineDateRangeByType(f.values?.[0], f.values?.[1], standardVariables?.firstMonth);
                }

                return ({
                    member: f.name,
                    operator,
                    values
                });
            }
        );

        return visualizationData?.filterConditionType === "or" ? [{ "or": filters }] : filters;
    }
    return [];
};

const getFilters1 = (visualizationData, standardVariables) => {
    if (visualizationData["filters1"]) {
        const filters = visualizationData.filters1?.filter(f => valuelessOperators.includes(f.operator) || (f.values && f.values.length > 0))?.map(
            f => {

                let operator = f.operator;
                let values = f.values;

                if (!["set", "notSet"].includes(f.operator) && valuelessOperators.includes(f.operator)) {
                    operator = "equals";
                    values = [standardVariables[f.operator]];
                }
                else if (!["set", "notSet"].includes(f.operator) && timelineOperators.includes(f.operator)) {
                    operator = "inDateRange";
                    values = getTimlineDateRangeByType(f.values?.[0], f.values?.[1], standardVariables?.firstMonth);
                }

                return ({
                    member: f.name,
                    operator,
                    values
                });
            }
        );

        return visualizationData?.filterConditionType1 === "or" ? [{ "or": filters }] : filters;
    }
    return [];
};

const getOrderDetails = (visualizationData, chartType) => {
    if (visualizationData["sort"]) {
        const orderDetails = [];
        visualizationData.sort?.filter(v => !["Years.srl", "Months.srl", "YearsDuck.srl", "MonthsDuck.srl"].includes(v.name))?.forEach(v => {
            if (v.name === "Months.month")
                orderDetails.push(["Months.srl", v.sortAsc === true ? "asc" : "desc"]);
            else if (v.name === "Years.year")
                orderDetails.push(["Years.srl", v.sortAsc === true ? "asc" : "desc"]);
            else if (v.name === "MonthsDuck.month")
                orderDetails.push(["MonthsDuck.srl", v.sortAsc === true ? "asc" : "desc"]);
            else if (v.name === "YearsDuck.year")
                orderDetails.push(["YearsDuck.srl", v.sortAsc === true ? "asc" : "desc"]);
            else
                orderDetails.push([v.sortName, v.sortAsc === true ? "asc" : "desc"]);
        });
        return orderDetails;
    }
    else {
        // add default sort for treemap chart
        if (chartType === "treemap-chart" && visualizationData.values?.length > 0) {
            return [[visualizationData.values[0].name, "desc"]]
        }
    }
    return [];
};

const getQueryDetails = (chartType, visualizationData, standardVariables) => {
    let dimensions = [], measures = [], measures1 = [], pivotConfig = null;
    if (chartType === "table") {
        dimensions = visualizationData.values?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
    }
    else if (chartType === "pivot-table") {
        dimensions = visualizationData.rows?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        const columnDimensions = visualizationData.columns?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        if (columnDimensions && columnDimensions.length > 0) {
            dimensions.push(...columnDimensions);
        }
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
        pivotConfig = getPivotConfig(visualizationData);
    }
    else if (chartType === "stats") {
        dimensions = visualizationData.axis?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        const legendDimensions = visualizationData.legend?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        if (legendDimensions && legendDimensions.length > 0) {
            dimensions.push(...legendDimensions);
        }
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
        const legendMeasures = visualizationData.legend?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name);
        if (legendMeasures && legendMeasures.length > 0) {
            measures.push(...legendMeasures);
        }
        const axisMeasures = visualizationData.axis?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name);
        if (axisMeasures && axisMeasures.length > 0) {
            measures.push(...axisMeasures);
        }
    }
    else if (chartType === "combination-chart") {
        dimensions = visualizationData.axis?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        const legendDimensions = visualizationData.legend?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        if (legendDimensions && legendDimensions.length > 0) {
            dimensions.push(...legendDimensions);
        }
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
        measures1 = visualizationData.values1?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
        // const values1Measures = visualizationData.values1?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name);
        // if (values1Measures && values1Measures.length > 0) {
        //     measures.push(...values1Measures);
        // }
    }
    else {
        dimensions = visualizationData.axis?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name) || [];
        const legendDimensions = visualizationData.legend?.filter(v => v.type === DataFieldTypes.DIMENSION)?.map(v => v.name);
        if (legendDimensions && legendDimensions.length > 0) {
            dimensions.push(...legendDimensions);
        }
        measures = visualizationData.values?.filter(v => v.type === DataFieldTypes.MEASURE)?.map(v => v.name) || [];
    }

    // add sort fields into dimensions & measures (if not already present)
    if (visualizationData.sort) {
        const sortMeasures = visualizationData.sort?.filter(v => v.type === DataFieldTypes.MEASURE && !measures.includes(v.name))?.map(v => v.name);
        if (sortMeasures && sortMeasures.length > 0) {
            measures.push(...sortMeasures);
        }
        const sortDimensions = visualizationData.sort?.filter(v => v.type === DataFieldTypes.DIMENSION && !dimensions.includes(v.name))?.map(v => v.name);
        if (sortDimensions && sortDimensions.length > 0) {
            dimensions.push(...sortDimensions);
        }
        // add month and year serial number if month or year dimensions are added
        if (dimensions.includes("Months.month") && !dimensions.includes("Months.srl"))
            dimensions.push("Months.srl");
        if (dimensions.includes("Years.year") && !dimensions.includes("Years.srl"))
            dimensions.push("Years.srl");
        if (dimensions.includes("MonthsDuck.month") && !dimensions.includes("MonthsDuck.srl"))
            dimensions.push("MonthsDuck.srl");
        if (dimensions.includes("YearsDuck.year") && !dimensions.includes("YearsDuck.srl"))
            dimensions.push("YearsDuck.srl");
    }

    const filters = getFilters(visualizationData, standardVariables);
    const filters1 = getFilters1(visualizationData, standardVariables);
    const order = getOrderDetails(visualizationData, chartType);

    let query = { dimensions, measures, filters, order };

    if (chartType === "combination-chart") {
        query = [
            { dimensions, measures, filters, order },
            { dimensions, measures: measures1, filters: filters1, order }
        ]
    }

    return { query, pivotConfig };
};

const getVizConfig = (chartType, query, pivotConfig, visualizationData, chartConfig) => {
    let newQuery = query;
    if (chartType === "combination-chart") {
        if (!Array.isArray(query))
            newQuery = [query];
        newQuery = newQuery?.map(q => ({ ...q, limit: toInt(chartConfig?.top_n || 2000) }));
    }
    else
        newQuery = { ...query, ...{ limit: toInt(chartConfig?.top_n || 2000) } };

    let vizState = {
        query: newQuery,
        pivotConfig,
        chartType
    };

    let vizOptions = {};

    if (chartType === "table") {
        vizOptions = {
            columns: visualizationData.values,
            config: chartConfig
        };
    }
    else if (chartType === "pivot-table") {
        vizOptions = {
            columns: [
                ...(visualizationData.rows || []),
                ...(visualizationData.columns || []),
                ...(visualizationData.values || []),
            ],
            config: chartConfig
        };
    }
    else {
        vizOptions = {
            axis: visualizationData.axis,
            legend: visualizationData.legend,
            values: visualizationData.values,
            values1: visualizationData.values1,
            config: chartConfig
        };
    }

    return { vizState, vizOptions };
}

const getMeasuresDimensions = (query) => {
    let measures = [], dimensions = [];
    if (Array.isArray(query)) {
        query.forEach(q => {
            if (q.measures) {
                measures.push(...q.measures);
            }
            if (q.dimensions) {
                dimensions.push(...q.dimensions);
            }
        });
    } else {
        if (query.measures) {
            measures = query.measures;
        }
        if (query.dimensions) {
            dimensions = query.dimensions;
        }
    }
    return { measures, dimensions };
};

const VisualizationPanel = memo(({ chartType, chartConfig, visualizationData, standardVariables, reRunCode, onQueryChange }) => {
    const [query, setQuery] = useImmer(chartType === "combination-chart" ? [DEFAULT_QUERY_TEMPLATE] : DEFAULT_QUERY_TEMPLATE);
    const [pivotConfig, setPivotConfig] = useImmer(DEFAULT_PIVOT_CONFIG_TEMPLATE);
    const [emptyState, setEmptyState] = useState(true);
    const [validationMessage, setValidationMessage] = useState(true);
    const { setEnableExport } = useAnalyticsContext();
    const [datacount, setDataCount] = useState(null);
    useEffect(() => {
        if (Object.keys(visualizationData).length > 0) {
            const { query, pivotConfig } = getQueryDetails(chartType, visualizationData, standardVariables);
            setQuery(query);
            setPivotConfig(pivotConfig || DEFAULT_PIVOT_CONFIG_TEMPLATE);

        } else {
            setQuery(chartType === "combination-chart" ? [DEFAULT_QUERY_TEMPLATE] : DEFAULT_QUERY_TEMPLATE);
            setPivotConfig(DEFAULT_PIVOT_CONFIG_TEMPLATE);
        }
    }, [chartType, visualizationData]);

    useEffect(() => {
        const queryObj = getMeasuresDimensions(query);
        if (queryObj?.dimensions?.length > 0 || queryObj?.measures?.length > 0) {
            const { result, message } = validateQuery(chartType, queryObj?.dimensions, queryObj?.measures);
            if (result) {
                setValidationMessage(null);
            } else {
                setValidationMessage(message);
            }
            setEnableExport(true);
            setEmptyState(false);
        }
        else {
            setEnableExport(false);
            setEmptyState(true);
        }
        if (onQueryChange)
            onQueryChange(getVizConfig(chartType, query, pivotConfig, visualizationData, chartConfig));
    }, [chartType, query, pivotConfig, visualizationData, chartConfig]);

    const { vizState, vizOptions } = useMemo(
        () => {
            if (emptyState)
                return {};
            return getVizConfig(chartType, query, pivotConfig, visualizationData, chartConfig);
        },
        [emptyState, chartType, query, pivotConfig, visualizationData, chartConfig]
    );

    if (emptyState) {
        return <EmptyState
            iconComponent={<VisualizationEmptyState />}
            size="medium"
            variant="secondary"
            iconName="query_stats"
            title={"Drag and drop fields"}
            description={"Select or drag fields from the \"Fields\" pane on to the drop zones to see the changes in visualization."}
        />
    }

    if (validationMessage) {
        return <MDBox
            flex={1}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 2,
                    m: 2,
                    minWidth: 300,
                    maxWidth: "80%",
                    alignItems: 'center',
                    boxShadow: '0 0 1px 1px rgb(35 217 108 / 30%), 0 0 15px 0 rgb(23 54 71 / 20%)'
                }}
            >
                <Icon
                    sx={{
                        height: 30,
                        width: 30,
                        borderRadius: '50%',
                        backgroundColor: '#facd35',
                        pt: .75,
                        mr: 1.75
                    }}
                >lightbulb</Icon>
                <MDTypography fontWeight="medium" variant="button" color="text">{validationMessage}</MDTypography>
            </Card>
        </MDBox>
    }

    return (
        <MDBox flex={1} position="relative" overflow="auto" display="flex" flexDirection="column" className="analytics-renderer">
            {
                datacount === vizState.query.limit && <MDBox px={1.75} py={.75} bgColor="#f1f1f1">
                    <MDTypography variant="caption" color="text" component="p" textTransform="uppercase">showing top {vizState.query.limit}</MDTypography>
                </MDBox>
            }
            <MDBox flex={1}>
                <VisualizationRenderer key={reRunCode} fluidLayout vizState={vizState} vizOptions={vizOptions} setDataCount={setDataCount} />
            </MDBox>
        </MDBox>
    )
});

export default VisualizationPanel;