import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate, useLocation } from "react-router-dom";
import { formatAmount, getDrilldownPath, formatNumber } from 'utils';
import colors from "assets/theme/base/colors";
import { useResizeDetector } from 'react-resize-detector';
import { useYADialog } from "components/YADialog";
import { convertRStoGraph, removeSessionFilter } from "utils"
import DashboardItem from 'components/DashboardItem';
import { creatToggleTableCubeOptions } from 'utils/charts';
import _ from 'lodash';
const LineChartRenderer = ({ loading, title, subtitle, chartHelpContextKey, resultSet, vizOptions, vizState, cubeOptions }) => {
    
    const chartRef = createRef();
    const { width, height, ref: rref } = useResizeDetector();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const [tableCubeOptions, setTableCubeOptions] = useState(null)
    const {showReport} = useYADialog();
    let navigate = useNavigate()
    let location = useLocation()
    let currentFilters
    let resultData = resultSet.tablePivot();
    let target
    if (vizOptions.target) {
        target = resultData[0][vizOptions.target];
    }
    if(loading)
       return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

    currentFilters = removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions)
    let metricYAxis = vizOptions.yAxisTitleText ? currentFilters[currentFilters.length-1]['values'][0]: null;
    let metricLegend = vizOptions.yAxisTitleText ? currentFilters[currentFilters.length-2]['values'][0]: null;
    let unitLegend = currentFilters[currentFilters.length-1]['values'][0]??null

    var graphData = convertRStoGraph(resultSet, vizOptions.series.length === 1 ? colors.singleDataColors : colors.graphColors, vizOptions.straightLineView ? "line" : "spline", vizOptions, metricLegend, unitLegend, target ? target : null)

    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            const foundMeasure = vizOptions.series.filter(({value}) => value === measures)[0]
            let col = {
                "name": measures,
                "displayName": foundMeasure ? `${foundMeasure.useNameString ? foundMeasure.name??'' : ''}${(metricLegend && foundMeasure.metricLegend) ? `\t[${metricLegend}]` : ''}${foundMeasure.concatWith ? `\t${foundMeasure.concatWith}` : ''}${(unitLegend && foundMeasure.unitLegend) ? `\t[${unitLegend}]` : ''}` : String(measures).match(/[^|]*$/g)[0],
                "found": foundMeasure ? true : false,
                "type": foundMeasure ? foundMeasure.type === 'column' ? 'string' : "currency" : "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            const foundDimension = vizOptions.series.filter(({value}) => value === dimensions)[0]
            let col = {
                "name": dimensions,
                "displayName": foundDimension ? `${foundDimension.useNameString ? foundDimension.name??'' : ''}${(metricLegend && foundDimension.metricLegend) ? `\t[${metricLegend}]` : ''}${foundDimension.concatWith ? `\t${foundDimension.concatWith}` : ''}${(unitLegend && foundDimension.unitLegend) ? `\t[${unitLegend}]` : ''}` : String(dimensions).match(/[^|]*$/g)[0],
                "found": foundDimension ? true : false,
                "type": "string"
            }
            return col
        })
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = 5.8;
        tableVizOptions["percentageFormat"] = vizOptions?.percentageFormat;
        if (cubeOptions) {
            let tableCubeOptions = creatToggleTableCubeOptions(cubeOptions)
            setTableCubeOptions(tableCubeOptions)
        }
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])


    var opts = {
        chart: { 
            width: width, 
            height: height,
            type: 'spline', 
        style: { fontFamily: 'inherit', fontSize: '14px', },
        spacingBottom: 0,
        // spacingTop: 0,
        spacingRight: 0,
        spacingLeft: 0,
     },
        title: { text: '' },
        exporting: { enabled: false },
        lang: { thousandsSep: ',' },        
        credits: { enabled: false },
        tooltip: {
            outside: false,
            formatter: function () {
                if (vizOptions.percentageFormat) {
                    return `<b>${this.point.category}</b><br/>${this.point.series.name}: ${this.point.series.userOptions?.metric ? `<b>${this.point.y}</b>` : `<b>${formatNumber(this.point.y).replace(/ /g, '').replace('.0', '')}%</b>`}`;
                }
                else if (vizOptions.disableAmountFormat) {
                    return `<b>${this.point.category}</b><br/>${this.point.series.name}: ${this.point.series.userOptions?.metric ? `<b>${this.point.y}</b>` : `<b>${Highcharts.numberFormat(this.point.y, 0, ".", ",")}</b>`}`;
                }
                else
                    return `<b>${this.point.category}</b><br/>${this.point.series.name}: ${this.point.series.userOptions?.metric ? `<b>${this.point.y}</b>` : `<b>$${Highcharts.numberFormat(this.point.y, 0, ".", ",")}</b>`}`;
            }
        },
        xAxis: {
            categories: Array.from(graphData.categories)
        },
        yAxis: [{
         
            opposite: vizOptions.target ? true : false,
            reversed: false,
            title: {
                text: vizOptions.yAxisTitleText ? vizOptions.yAxisTitleText : ''
            },
            labels: {
                formatter: function () {
                    if (vizOptions.percentageFormat) {
                        return formatNumber(Math.abs(this.value)).replace(/ /g, '').replace('.0', '');
                    }
                    else if (vizOptions.disableAmountFormat) {
                        return formatNumber(Math.abs(this.value)).replace(/ /g, '').replace('.0', '');
                    }
                    else
                        return formatAmount(Math.abs(this.value)).replace(/ /g, '').replace('.0', '');
                }
            },
            
        },
        {
            // plotLines: vizOptions.target ?
            // [{
            //     color: '#08070a',
            //     width: 3,
            //     value: target ? target : null,
            //     dashStyle: 'dot',
            //     zIndex: 3
            // }] :
            // [],
            reversed: false,
            title: {
                 text: metricYAxis ? `Planned (${metricYAxis})` : ''
            },
            labels: {
                formatter: function () {
                    return formatNumber(Math.abs(this.value)).replace(/ /g,'').replace('.0','');
                }
            },
        }
    ],
    plotOptions: {
            series: {
                // events : {
                //     legendItemClick: function () {
                //            return this.userOptions?.legendActive ? false : true
                //     },
                // },
                cursor: "pointer",
                groupPadding: 0.1,
                // pointPadding: 0,
                borderWidth: 0,
                marker: {
                    enabled: vizOptions.straightLineView ? true : false
                }, 
                states: {
                    inactive: {
                        opacity: 1
                    }
                },               
                point: {
                    events: {
                        click: function (event) {
                            var obj = Object.assign([], [...currentFilters]);
                            if (vizOptions.category)
                                // if (!obj.find((({name}) => name === vizOptions.category))) 
                                if (obj.find((({name}) => name === vizOptions.category))) 
                                {
                                   _.remove(obj, {name: vizOptions.category})
                                   obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                                else
                                {
                                   obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                                if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                                    vizOptions.excludeFilters.map((fil) => {
                                        if (obj.find((({name}) => name === fil)))
                                            _.remove(obj, {name: fil})                                
                                    })
                                }
                            vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath(location.pathname, vizOptions.drillTo), { state: obj})
                            vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && showReport(vizOptions["popupTo"], obj, null);
                        }
                    }
                }
            }
        },
        series: Object.values(graphData.range)

    }

    const nodata = graphData.categories?.size === 0;
    return ( toggleType === 'table' && tableVizOptions ?
    <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet} cubeOptions={tableCubeOptions} metricLegend={metricLegend} unitLegend={unitLegend} /> :
        <DashboardItem nodata={nodata} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={resultSet?.tablePivot()} cubeOptions={cubeOptions}>
            <div ref={rref} style={{position: 'relative', height: '100%'}}>
                <div style={{position: 'absolute', left: 0, top: 0, bottom: 0, right: 0}}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
                </div>
            </div>
        </DashboardItem>
    )
}

export default LineChartRenderer;