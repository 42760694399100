
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { Card, Icon } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import Checkbox from '@mui/material/Checkbox';
import CardHeader from '@mui/material/CardHeader';
import YAScrollbar from "components/YAScrollbar";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MDInput from "components/MDInput";
import Divider from '@mui/material/Divider';
import new_item_img from "assets/svg/add_new.svg";
import EmptyState from "components/EmptyState";
import MDButton from 'components/MDButton';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

const DataDictionary = () => {

    const [tableDetails, setTableDetails] = useState(null)
    const [search, setSearch] = useState("")
    const handleError = useHandleError();


    const buildColumns = () => [
        { Header: "Type", accessor: "type" },
        { Header: "Name", accessor: "displayName" },
        { Header: "Field Name", accessor: "fieldName" },
        { Header: "Mandatory", accessor: "required" },
        { Header: "Data Type", accessor: "datatype" },
        { Header: "Description", accessor: "description" },
        { Header: "Impact If Not Available", accessor: "impact" },
        { Header: "Severity of Impact", accessor: "severity" },
    ].map(column => ({ ...column, Cell: ({ cell: { value } }) => (<MDTypography variant="caption" color="dark">{value}</MDTypography>), }));

    const buildRows = (data) => {
        const rows = [];
        if (Array.isArray(data) && data.length > 0) {
            data.forEach((r) => {
                let row = {};
                Object.keys(r).forEach((k) => {
                    row[k.replace(/\./g, "__")] = r[k]
                });
                rows.push(row);
            });
        }
        return rows;
    }
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const columns = buildColumns();
    const [fieldDefinitions, setFieldDefinitions] = useState([])
    const [definitionCheck, setDefinitionCheck] = useState(false)
    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/templates/list`);
            var [err1, dataRes] = await fetchRequest.get(`/api/dataflow/assetTypes`);
            var [err3, dataDefSet] = await fetchRequest.get(`/api/dataflow/dataDefinitions/all`)
            if (err || err1 || err3) {
                handleError(err);

            }
            else {
                let data11 = Object.assign([], data)
                let data1 = data11.filter(o => o.hidden !== true)
                let fullDataDefinition = []
                let tableNames = []
                data1.map(data => {
                    tableNames.push({ name: data.name, displayName: data.displayName, checked: false })
                    let mappingFields =
                        dataDefSet.dataDefinitions[data.name]?.fields ||
                        dataDefSet.resourcesMap[data.name]?.fields ||
                        dataDefSet.dataloadTypeDefsMap[data.name]?.fields || [];

                    mappingFields.forEach(item => {
                        let fieldItem = {}
                        fieldItem["name"] = data.name
                        fieldItem["displayName"] = data.displayName
                        fieldItem["fieldName"] = item.displayName
                        fieldItem["required"] = item.required ? "Yes" : "No"
                        fieldItem["datatype"] =  (item.type === 'switch' || item.type === 'boolean') ? 'Boolean' : (item.type === 'datepicker' || item.type === 'date') ? 'Date'  : (item.type === 'number' || item.type === "integer" || item.type === 'currency' || item.type === 'float') ? 'Number' : 'Text'
                        fieldItem["description"] = item.toolTip || "N/A"
                        fieldItem["impact"] = item.impact ? item.impact : 'N/A'
                        fieldItem['severity'] = item.severity ? item.severity : 'N/A'
                        fieldItem['type'] = (
                            dataDefSet.fileTypes[data.name] ||
                            dataDefSet.resourcesMap[data.name]?.type ||
                            dataDefSet.dataloadTypeDefsMap[data.name]?.type || ""
                        ).replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
                        fullDataDefinition.push(fieldItem)
                    })
                })
                dataRes.map(data => {
                    tableNames.push({ name: data.name, displayName: data.displayName, checked: false })
                    let mappingFields = dataDefSet.dataDefinitions[data.name]?.fields || dataDefSet.resourcesMap[data.name]?.fields || dataDefSet.dataloadTypeDefsMap[data.name]?.fields
                    mappingFields.forEach(item => {
                        let fieldItem = {}
                        fieldItem["name"] = data.name
                        fieldItem["displayName"] = data.displayName
                        fieldItem["fieldName"] = item.displayName
                        fieldItem["required"] = item.required ? "Yes" : "No"
                        fieldItem["datatype"] = (item.type === 'switch' || item.type === 'boolean') ? 'Boolean' : (item.type === 'datepicker' || item.type === 'date') ? 'Date'  : (item.type === 'number' || item.type === "integer" || item.type === 'currency' || item.type === 'float') ? 'Number' : 'Text'
                        fieldItem["description"] = item.toolTip || "N/A"
                        fieldItem["impact"] = item.impact ? item.impact : 'N/A'
                        fieldItem['severity'] = item.severity ? item.severity : 'N/A'
                        fieldItem['type'] = (
                            dataDefSet.fileTypes[data.name] ||
                            dataDefSet.resourcesMap[data.name]?.type ||
                            dataDefSet.dataloadTypeDefsMap[data.name]?.type || ""
                        ).replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
                        fullDataDefinition.push(fieldItem)
                    })
                })
                setFieldDefinitions(fullDataDefinition)
                setTableDetails(tableNames)
                setRows(buildRows(fullDataDefinition));
                setLoading(false);
            }
        }
        getList();
    }, [])
    const numberOfChecked = (tableDetails) => {
        let checkdItems = tableDetails.filter(item => item.checked)
        return (checkdItems.length)
    };
    useEffect(() => {
        let filteredDefData = fieldDefinitions.filter(item => {
            const tableDetail = tableDetails.find(table => table.name === item.name);
            return tableDetail && tableDetail.checked;
        })
        setRows(buildRows(filteredDefData));
    }, [tableDetails]);

    const handleDownload = async () => {
      try {
        if (columns && rows) {
          const allData = [];
          const fileName = [
            'Data Dictionary', 'Account', 'Cost Center', 'Vendor', 'Invoice',
            'Purchase Order', 'Contract', 'Recommendation', 'Business Unit',
            'Companion Metrics', 'Budget Allocation', 'Budget Planning',
            'Business Unit', 'Solution Offerings', 'Upload Rules'
          ];
    
          const groupedData = rows.reduce((acc, item) => {
            const { displayName, fieldName, description } = item;
    
            if (!acc[displayName]) {
              acc[displayName] = [];
            }
    
            const rowObject = {};
            columns.forEach(e => {
              if (e.Header !== "Actions") {
                rowObject[e.Header] = item[e.accessor];
              }
            });
            allData.push(rowObject);
    
            const group = acc[displayName];

                  if (group.length === 0) {
                      group.push({ [fieldName]: definitionCheck ? description  : null});
                  } else {
                      group[0][fieldName] =  definitionCheck ? description  : null
                  }
    
            return acc;
          }, {});
    
          groupedData["Data Dictionary"] = allData;
          
          const zip = new JSZip();
    
          Object.entries(groupedData).forEach(([key, array]) => {
            const worksheet = XLSX.utils.json_to_sheet(array);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelFile = XLSX.write(workbook, { type: 'binary', bookType: key === "Data Dictionary" ? 'xlsx':'csv' });
            const fileNameWithDate = key === "Data Dictionary" ? `${key}.xlsx`: fileName.includes(key) ? `${key}.csv` : `${key}_YYYYMM.csv`;
            zip.file(fileNameWithDate, excelFile, { binary: true });
          });
    
          const content = await zip.generateAsync({ type: 'blob' });
          saveAs(content, 'Data Dictionary.zip');
    
          await fetchRequest.post(`/api/dataflow/createLogger`, {
            message: `Downloaded Data Dictionary list`
          });
        }
      } catch (error) {
        console.error("Error during download:", error);
      }
    };

    const renderPrimaryActions = () => {
        return <MDBox pb={2} pt={1} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography my={2} mr={2} color="text" variant="button" fontWeight="medium">
                <Tooltip title={"Choose this option to include column descriptions in the downloaded data templates."}>
                    <MDBox>
                        <Checkbox sx={{ p: 0, mr: 1, "& .MuiSvgIcon-root": { border: "1px solid #c5c9cc", borderRadius: "4px" } }} checked={definitionCheck} onClick={() => { setDefinitionCheck(!definitionCheck) }} />
                        <MDTypography component="span" variant="subtitle2" fontWeight={"medium"}>
                        Include Column Descriptions
                        </MDTypography>
                    </MDBox>
                </Tooltip>
            </MDTypography>
            <MDButton
                data-testid={"download"}
                variant="gradient"
                color="info"
                startIcon={<Icon>download</Icon>}
                onClick={handleDownload}
            >
                Download
            </MDButton>
        </MDBox>
    }


    let handleToggle = (value) => {
        let newValue = value
        newValue.checked = !value.checked
        let updatedTables = tableDetails.map(obj =>
            obj.name === value.name ? newValue : obj
        );
        setTableDetails(updatedTables)
    }

    const handleToggleAll = (event, tableDetails, search) => {
        let updatedTables = tableDetails.map(item => {
            if (search === "" || item.displayName.toLowerCase().includes(search.toLowerCase()) === true) {
                item.checked = event.target.checked ? true : false
            }
            return item
        })
        setTableDetails(updatedTables)
    }

    if (loading) {
        return <YASkeleton variant="dashboard-loading" />;
    }

    if (loading === false && !rows) { return (<div>no data </div>); }

    return (
        <>
            <PageHeader title="Data Dictionary" subtitle="Download your data definitions of the fields and data templates from here." primaryActionComponent={renderPrimaryActions} />
            <MDBox data-testid={"user"} p={2} sx={{ height: "100%" }}>
                <MDBox
                    borderRadius="6px"
                    border="1px solid #ddd"
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="row"
                    overflow="hidden"
                >
                    {/* Left Column with Fixed Width */}
                    <MDBox
                        width={"30%"} // Fixed width for the left column
                        p={3}
                        sx={{
                            minHeight: '550px',
                            height: '750px',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                        }}
                    >
                        <YAScrollbar>
                            <Card sx={{ height: "100%", width: "100%" }} px={0}>
                                <CardHeader
                                    sx={{ px: 0, py: 1 }}
                                    avatar={
                                        <Checkbox
                                            sx={{
                                                color: "primary",
                                                '&.Mui-checked': {
                                                    color: "red",
                                                    font: 'inherit',
                                                    fontSize: '12px'
                                                },
                                            }}
                                            onClick={(event) => { handleToggleAll(event, tableDetails, search) }}
                                            checked={numberOfChecked(tableDetails) === tableDetails.length && tableDetails.length !== 0}
                                            indeterminate={
                                                numberOfChecked(tableDetails) !== tableDetails.length && numberOfChecked(tableDetails) !== 0
                                            }
                                            disabled={tableDetails.length === 0}
                                            inputProps={{
                                                'aria-label': 'all items selected',
                                            }}
                                        />
                                    }
                                    title={`Select All`}
                                />
                                <Divider />
                                <MDInput
                                    placeholder="Search..."
                                    inputProps={{ type: "search" }}
                                    value={search}
                                    size="small"
                                    sx={{ width: "100%", minWidth: "10rem", maxWidth: "22rem", margin: 0.5, pl: 0.5 }}
                                    onChange={({ currentTarget }) => setSearch(currentTarget.value)}
                                />
                                <Divider />
                                <List
                                    sx={{ width: '100%', maxHeight: 'calc(100% - 150px)', overflowY: 'auto', bgcolor: "background.paper" }}
                                    dense
                                    role="list"
                                >
                                    {tableDetails.filter(item => item.displayName.toLowerCase().includes(search.toLowerCase())).map(item => (
                                        <ListItem
                                            key={item.name}
                                            role="listitem"
                                            button
                                            onClick={() => handleToggle(item)}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={item.checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-label': 'Select in list',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={"1"} primary={item.displayName} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Card>
                        </YAScrollbar>
                    </MDBox>

                    {/* Right Column with Fixed Width */}
                    <MDBox
                        width={"70%"} // Fixed width for the right column
                        p={3}
                        sx={{
                            height: '750px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Card sx={{ height: "100%", width: "100%" }} px={0}>
                            {rows.length === 0 ?
                                <MDBox
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%"
                                    }}
                                >
                                    <EmptyState
                                        size="large"
                                        image={new_item_img}
                                        title={"Choose items from the list."}
                                        description={""}
                                    />
                                </MDBox>
                                :
                                <DataTable
                                    table={{ columns, rows }}
                                    showTotalEntries={true}
                                    isSorted={true}
                                    newStyle1={true}
                                    noEndBorder
                                    entriesPerPage={true}
                                    canSearch={true}
                                    canDownload={true}
                                />
                            }
                        </Card>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    );

};

export default AnimatedRoute(DataDictionary);